@charset "UTF-8";
@-webkit-keyframes spinner-bubble {
  from {
    width: 5px;
    height: 5px;
    opacity: .8; }
  to {
    width: 30px;
    height: 30px;
    opacity: 0; } }

@-ms-keyframes spinner-bubble {
  from {
    width: 5px;
    height: 5px;
    opacity: .8; }
  to {
    width: 30px;
    height: 30px;
    opacity: 0; } }

@keyframes spinner-bubble {
  from {
    width: 5px;
    height: 5px;
    opacity: .8; }
  to {
    width: 30px;
    height: 30px;
    opacity: 0; } }

@-webkit-keyframes spinner-bubble-xl {
  from {
    width: 5px;
    height: 5px;
    opacity: .8; }
  to {
    width: 50px;
    height: 50px;
    opacity: 0; } }

@-ms-keyframes spinner-bubble-xl {
  from {
    width: 5px;
    height: 5px;
    opacity: .8; }
  to {
    width: 50px;
    height: 50px;
    opacity: 0; } }

@keyframes spinner-bubble-xl {
  from {
    width: 5px;
    height: 5px;
    opacity: .8; }
  to {
    width: 50px;
    height: 50px;
    opacity: 0; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(10%); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes border-effect {
  from {
    width: 0; }
  to {
    width: 4rem; } }

@keyframes pulse {
  from {
    opacity: 1;
    transform: translateY(50%) translateX(50%) scale(0.5); }
  to {
    opacity: 0;
    transform: translateY(50%) translateX(50%) scale(1.5); } }

@keyframes pulse-alt {
  from {
    opacity: 1;
    transform: translateY(50%) translateX(-50%) scale(0.5); }
  to {
    opacity: 0;
    transform: translateY(50%) translateX(-50%) scale(1.5); } }

@font-face {
  font-family: "darwinex";
  src: url("../fonts/darwinex/darwinex.woff2") format("woff2"), url("../fonts/darwinex/darwinex.ttf") format("truetype"), url("../fonts/darwinex/darwinex.woff") format("woff"), url("../fonts/darwinex/darwinex.svg#darwinex") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "darwinex" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  [class^="icon-"].font-weight-bold, [class*=" icon-"].font-weight-bold {
    font-weight: bold; }

.icon-documents-circle:before {
  content: "\e9cf"; }

.icon-arrow-right-circle:before {
  content: "\e9d0"; }

.icon-upload:before {
  content: "\e9c0"; }

.icon-status-ok:before {
  content: "\e9a0"; }

.icon-status-warning:before {
  content: "\e9a1"; }

.icon-screen-candles:before {
  content: "\e973"; }

.icon-calendar-clock:before {
  content: "\e9cd"; }

.icon-globe-framework:before {
  content: "\e9cc"; }

.icon-youku:before {
  content: "\e98f"; }

.icon-weibo:before {
  content: "\e990"; }

.icon-icon-candles:before {
  content: "\e98d"; }

.icon-icon-chart-2:before {
  content: "\e98e"; }

.icon-arrow-left-thin:before {
  content: "\e98b"; }

.icon-icon-people:before {
  content: "\e986"; }

.icon-icon-suitcase-2:before {
  content: "\e987"; }

.icon-icon-switches:before {
  content: "\e988"; }

.icon-icon-download-tocart:before {
  content: "\e989"; }

.icon-icon-add-filter:before {
  content: "\e98a"; }

.icon-link-circle:before {
  content: "\e985"; }

.icon-arrow-orders:before {
  content: "\e984"; }

.icon-arrow-orders-down:before {
  content: "\e98c"; }

.icon-widget:before {
  content: "\e983"; }

.icon-info-circle-alt:before {
  content: "\e982"; }

.icon-heart-hexagon:before {
  content: "\e981"; }

.icon-user-circle-alt:before {
  content: "\e980"; }

.icon-aaa:before {
  content: "\e97e"; }

.icon-bbb:before {
  content: "\e97f"; }

.icon-search-window:before {
  content: "\e979"; }

.icon-ambulance:before {
  content: "\e973"; }

.icon-map-marker:before {
  content: "\e974"; }

.icon-phone:before {
  content: "\e975"; }

.icon-sheriff-star:before {
  content: "\e96b"; }

.icon-partnership:before {
  content: "\e96c"; }

.icon-user:before {
  content: "\e924"; }

.icon-user-alt:before {
  content: "\e96d"; }

.icon-user-manager:before {
  content: "\e96e"; }

.icon-info-circle:before {
  content: "\e969"; }

.icon-tv:before {
  content: "\e968"; }

.icon-calendar:before {
  content: "\e967"; }

.icon-trash:before {
  content: "\e965"; }

.icon-paw-circle:before {
  content: "\e964"; }

.icon-number-200:before {
  content: "\e962"; }

.icon-flame:before {
  content: "\e961"; }

.icon-egg-broken:before {
  content: "\e960"; }

.icon-filter:before {
  content: "\e95e"; }

.icon-folder-open-fly:before {
  content: "\e95d"; }

.icon-box-o:before {
  content: "\e95a"; }

.icon-external-link:before {
  content: "\e959"; }

.icon-ticket-circle:before {
  content: "\e958"; }

.icon-search:before {
  content: "\e91c"; }

.icon-zoom-out:before {
  content: "\e957"; }

.icon-zoom-in:before {
  content: "\e956"; }

.icon-ticket:before {
  content: "\e953"; }

.icon-owl-circle:before {
  content: "\e97a"; }

.icon-owl:before {
  content: "\e91e"; }

.icon-owl-alt:before {
  content: "\e952"; }

.icon-download-alt:before {
  content: "\e951"; }

.icon-download:before {
  content: "\e94f"; }

.icon-time:before {
  content: "\e94e"; }

.icon-clock-o:before {
  content: "\e966"; }

.icon-dollar-back-circle:before {
  content: "\e95f"; }

.icon-refresh:before {
  content: "\e95c"; }

.icon-power-off:before {
  content: "\e95b"; }

.icon-ellipsis-h:before {
  content: "\e913"; }

.icon-ellipsis-v:before {
  content: "\e99b"; }

.icon-drag:before {
  content: "\e93c"; }

.icon-grid:before {
  content: "\e94c"; }

.icon-th-list:before {
  content: "\e94b"; }

.icon-list:before {
  content: "\e914"; }

.icon-bars:before {
  content: "\e915"; }

.icon-bars-alt:before {
  content: "\e97b"; }

.icon-flask:before {
  content: "\e948"; }

.icon-bell:before {
  content: "\e945"; }

.icon-folder-open-circle:before {
  content: "\e944"; }

.icon-graduation-cap:before {
  content: "\e943"; }

.icon-folder-open:before {
  content: "\e942"; }

.icon-star:before {
  content: "\e941"; }

.icon-dashboard:before {
  content: "\e940"; }

.icon-users-arrow-right:before {
  content: "\e93f"; }

.icon-piggy-bank:before {
  content: "\e93e"; }

.icon-exchange:before {
  content: "\e96f"; }

.icon-money:before {
  content: "\e93d"; }

.icon-coins:before {
  content: "\e93b"; }

.icon-money-bag:before {
  content: "\e963"; }

.icon-users-plus:before {
  content: "\e93a"; }

.icon-desktop:before {
  content: "\e939"; }

.icon-compass-circle:before {
  content: "\e938"; }

.icon-television-user:before {
  content: "\e94a"; }

.icon-television-chart:before {
  content: "\e949"; }

.icon-chart-bars:before {
  content: "\e971"; }

.icon-chart:before {
  content: "\e91d"; }

.icon-line-chart:before {
  content: "\e936"; }

.icon-share:before {
  content: "\e926"; }

.icon-pencil:before {
  content: "\e927"; }

.icon-eye:before {
  content: "\e935"; }

.icon-file-text-clip:before {
  content: "\e96a"; }

.icon-file-excel-o:before {
  content: "\e934"; }

.icon-file-pdf-o:before {
  content: "\e950"; }

.icon-medal:before {
  content: "\e931"; }

.icon-info:before {
  content: "\e930"; }

.icon-gear:before {
  content: "\e925"; }

.icon-gears:before {
  content: "\e955"; }

.icon-gears-circle:before {
  content: "\e92f"; }

.icon-check:before {
  content: "\e92e"; }

.icon-loving:before {
  content: "\e972"; }

.icon-heart-o:before {
  content: "\e92d"; }

.icon-twitter-circle:before {
  content: "\e92c"; }

.icon-linkedin-circle:before {
  content: "\e92b"; }

.icon-facebook-circle:before {
  content: "\e92a"; }

.icon-youtube-circle:before {
  content: "\e97c"; }

.icon-play-circle:before {
  content: "\e970"; }

.icon-blog-circle:before {
  content: "\e97d"; }

.icon-blog-circle-2:before {
  content: "\e9d5"; }

.icon-arrow-right-thin:before {
  content: "\e954"; }

.icon-arrow-left:before {
  content: "\e977"; }

.icon-arrow-right:before {
  content: "\e978"; }

.icon-arrow-up-thick:before {
  content: "\e946"; }

.icon-arrow-down-thick:before {
  content: "\e947"; }

.icon-arrow-up:before {
  content: "\e929"; }

.icon-arrow-down:before {
  content: "\e928"; }

.icon-caret-up:before {
  content: "\e933"; }

.icon-caret-down:before {
  content: "\e932"; }

.icon-chevron-down:before {
  content: "\e922"; }

.icon-chevron-up:before {
  content: "\e921"; }

.icon-chevron-left:before {
  content: "\e923"; }

.icon-chevron-right:before {
  content: "\e920"; }

.icon-chevron-left-thin:before {
  content: "\e9c9"; }

.icon-chevron-right-thin:before {
  content: "\e9c8"; }

.icon-chevron-up-thin:before {
  content: "\e9ca"; }

.icon-chevron-down-thin:before {
  content: "\e9cb"; }

.icon-chevron-double-up:before {
  content: "\e976"; }

.icon-chevron-double-left:before {
  content: "\e9dd"; }

.icon-chevron-double-right:before {
  content: "\e9de"; }

.icon-close-thin:before {
  content: "\e91f"; }

.icon-close:before {
  content: "\e90f"; }

.icon-plus:before {
  content: "\e917"; }

.icon-plus-circle:before {
  content: "\e937"; }

.icon-question:before {
  content: "\e91b"; }

.icon-trophy:before {
  content: "\e91a"; }

.icon-suitcase:before {
  content: "\e919"; }

.icon-wallet:before {
  content: "\e918"; }

.icon-wallet-open-o:before {
  content: "\e94d"; }

.icon-paw:before {
  content: "\e916"; }

.icon-dollar:before {
  content: "\e912"; }

.icon-comments:before {
  content: "\e911"; }

.icon-comments-circle:before {
  content: "\e9d6"; }

.icon-comment:before {
  content: "\e910"; }

.icon-user-circle:before {
  content: "\e90e"; }

.icon-close-circle-o:before {
  content: "\e9c5"; }

.icon-dollar-circle:before {
  content: "\e90c"; }

.icon-gear-circle:before {
  content: "\e90b"; }

.icon-arrow-circle-right:before {
  content: "\e90a"; }

.icon-dna-circle:before {
  content: "\e909"; }

.icon-amoeba-circle:before {
  content: "\e908"; }

.icon-circle:before {
  content: "\e907"; }

.icon-circle-o:before {
  content: "\e906"; }

.icon-square:before {
  content: "\e905"; }

.icon-square-o:before {
  content: "\e904"; }

.icon-hexagon-o:before {
  content: "\e903"; }

.icon-hexagon:before {
  content: "\e902"; }

.icon-heart:before {
  content: "\e901"; }

.icon-play:before {
  content: "\e900"; }

.icon-shield-tick:before {
  content: "\ea01"; }

.icon-check-thin:before {
  content: "\ea02"; }

/*!
 * Bootstrap v4.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #026DCE;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #FF4141;
  --orange: #F2A007;
  --yellow: #ffce00;
  --green: #79AA00;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #FFFFFF;
  --gray: #9B9B9B;
  --gray-dark: #343a40;
  --blue-alt: #089CFB;
  --blue-light: #009BFF;
  --blue-dark: #011B34;
  --blue-dark-alt: #03071A;
  --blue-darker: #052847;
  --blue-gray: #324454;
  --dark-grayish-cian: #81A7AA;
  --magenta: #C48ED1;
  --orange-soft: #f4b350;
  --orange-dark: #BA7209;
  --green-dark: #79AA00;
  --red-dark: #BA3232;
  --red-bright: #E35527;
  --primary: #026DCE;
  --secondary: #FFFFFF;
  --success: #79AA00;
  --info: #009BFF;
  --warning: #F2A007;
  --danger: #BA3232;
  --light: #E0E0E0;
  --dark: #011B34;
  --primary-light: #009BFF;
  --danger-dark: #BA3232;
  --gray: #E0E0E0;
  --breakpoint-xs: 0;
  --breakpoint-s: 350px;
  --breakpoint-smc: 450px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440px;
  --font-family-sans-serif: "sofia-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "sofia-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #4a4a4a;
  text-align: left;
  background-color: #FFFFFF; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #026DCE;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #014582;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  color: #979797;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 3rem; }

h2, .h2 {
  font-size: 2.5rem; }

h3, .h3 {
  font-size: 2rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 300; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled, .list-divided, .list-logos, .list-icons, .grid-checkboxes, ul.card-deck, ol.card-deck, .list-timeline, .card-listitems__list {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #272727; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFFFFF;
  border: 1px solid #4a4a4a;
  border-radius: 20px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #272727; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-s, .container-smc, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-s, .container-smc, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-s, .container-smc, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-s, .container-smc, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-s-1, .col-s-2, .col-s-3, .col-s-4, .col-s-5, .col-s-6, .col-s-7, .col-s-8, .col-s-9, .col-s-10, .col-s-11, .col-s-12, .col-s,
.col-s-auto, .col-smc-1, .col-smc-2, .col-smc-3, .col-smc-4, .col-smc-5, .col-smc-6, .col-smc-7, .col-smc-8, .col-smc-9, .col-smc-10, .col-smc-11, .col-smc-12, .col-smc,
.col-smc-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%; }

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%; }

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%; }

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%; }

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333333%; }

.offset-2 {
  margin-left: 16.66666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333333%; }

.offset-5 {
  margin-left: 41.66666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333333%; }

.offset-8 {
  margin-left: 66.66666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333333%; }

.offset-11 {
  margin-left: 91.66666667%; }

@media (min-width: 350px) {
  .col-s {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-s-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-s-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-s-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-s-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-s-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-s-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-s-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-s-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-s-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-s-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-s-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-s-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-s-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-s-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-s-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-s-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-s-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-s-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-s-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-s-first {
    order: -1; }
  .order-s-last {
    order: 13; }
  .order-s-0 {
    order: 0; }
  .order-s-1 {
    order: 1; }
  .order-s-2 {
    order: 2; }
  .order-s-3 {
    order: 3; }
  .order-s-4 {
    order: 4; }
  .order-s-5 {
    order: 5; }
  .order-s-6 {
    order: 6; }
  .order-s-7 {
    order: 7; }
  .order-s-8 {
    order: 8; }
  .order-s-9 {
    order: 9; }
  .order-s-10 {
    order: 10; }
  .order-s-11 {
    order: 11; }
  .order-s-12 {
    order: 12; }
  .offset-s-0 {
    margin-left: 0; }
  .offset-s-1 {
    margin-left: 8.33333333%; }
  .offset-s-2 {
    margin-left: 16.66666667%; }
  .offset-s-3 {
    margin-left: 25%; }
  .offset-s-4 {
    margin-left: 33.33333333%; }
  .offset-s-5 {
    margin-left: 41.66666667%; }
  .offset-s-6 {
    margin-left: 50%; }
  .offset-s-7 {
    margin-left: 58.33333333%; }
  .offset-s-8 {
    margin-left: 66.66666667%; }
  .offset-s-9 {
    margin-left: 75%; }
  .offset-s-10 {
    margin-left: 83.33333333%; }
  .offset-s-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 450px) {
  .col-smc {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-smc-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-smc-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-smc-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-smc-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-smc-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-smc-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-smc-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-smc-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-smc-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-smc-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-smc-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-smc-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-smc-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-smc-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-smc-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-smc-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-smc-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-smc-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-smc-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-smc-first {
    order: -1; }
  .order-smc-last {
    order: 13; }
  .order-smc-0 {
    order: 0; }
  .order-smc-1 {
    order: 1; }
  .order-smc-2 {
    order: 2; }
  .order-smc-3 {
    order: 3; }
  .order-smc-4 {
    order: 4; }
  .order-smc-5 {
    order: 5; }
  .order-smc-6 {
    order: 6; }
  .order-smc-7 {
    order: 7; }
  .order-smc-8 {
    order: 8; }
  .order-smc-9 {
    order: 9; }
  .order-smc-10 {
    order: 10; }
  .order-smc-11 {
    order: 11; }
  .order-smc-12 {
    order: 12; }
  .offset-smc-0 {
    margin-left: 0; }
  .offset-smc-1 {
    margin-left: 8.33333333%; }
  .offset-smc-2 {
    margin-left: 16.66666667%; }
  .offset-smc-3 {
    margin-left: 25%; }
  .offset-smc-4 {
    margin-left: 33.33333333%; }
  .offset-smc-5 {
    margin-left: 41.66666667%; }
  .offset-smc-6 {
    margin-left: 50%; }
  .offset-smc-7 {
    margin-left: 58.33333333%; }
  .offset-smc-8 {
    margin-left: 66.66666667%; }
  .offset-smc-9 {
    margin-left: 75%; }
  .offset-smc-10 {
    margin-left: 83.33333333%; }
  .offset-smc-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333333%; }
  .offset-sm-2 {
    margin-left: 16.66666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333333%; }
  .offset-sm-5 {
    margin-left: 41.66666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333333%; }
  .offset-sm-8 {
    margin-left: 66.66666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333333%; }
  .offset-sm-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333333%; }
  .offset-md-2 {
    margin-left: 16.66666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333333%; }
  .offset-md-5 {
    margin-left: 41.66666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333333%; }
  .offset-md-8 {
    margin-left: 66.66666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333333%; }
  .offset-md-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333333%; }
  .offset-lg-2 {
    margin-left: 16.66666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333333%; }
  .offset-lg-5 {
    margin-left: 41.66666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333333%; }
  .offset-lg-8 {
    margin-left: 66.66666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333333%; }
  .offset-lg-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333333%; }
  .offset-xl-2 {
    margin-left: 16.66666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333333%; }
  .offset-xl-5 {
    margin-left: 41.66666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333333%; }
  .offset-xl-8 {
    margin-left: 66.66666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333333%; }
  .offset-xl-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 1440px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-xxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-xxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-xxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-xxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333333%; }
  .offset-xxl-2 {
    margin-left: 16.66666667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333333%; }
  .offset-xxl-5 {
    margin-left: 41.66666667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333333%; }
  .offset-xxl-8 {
    margin-left: 66.66666667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333333%; }
  .offset-xxl-11 {
    margin-left: 91.66666667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #4a4a4a; }
  .table th,
  .table td {
    padding: 0.8rem;
    vertical-align: top;
    border-top: 1px solid #E0E0E0; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #E0E0E0; }
  .table tbody + tbody {
    border-top: 2px solid #E0E0E0; }

.table-sm th, .darwin-stats--sm th,
.table-sm td,
.darwin-stats--sm td {
  padding: 0.5rem; }

.table-bordered {
  border: 1px solid #E0E0E0; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #E0E0E0; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #4a4a4a;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d6f1; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7bb3e6; }

.table-hover .table-primary:hover {
  background-color: #a3caed; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a3caed; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: white; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: white; }

.table-hover .table-secondary:hover {
  background-color: #f2f2f2; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f2f2f2; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d9e7b8; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #b9d37a; }

.table-hover .table-success:hover {
  background-color: #cfe1a5; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #cfe1a5; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8e3ff; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7acbff; }

.table-hover .table-info:hover {
  background-color: #9fd9ff; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #9fd9ff; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbe4ba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f8ce7e; }

.table-hover .table-warning:hover {
  background-color: #fadba2; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fadba2; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ecc6c6; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #db9494; }

.table-hover .table-danger:hover {
  background-color: #e6b3b3; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e6b3b3; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f6f6f6; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #efefef; }

.table-hover .table-light:hover {
  background-color: #e9e9e9; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e9e9e9; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #b8bfc6; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #7b8895; }

.table-hover .table-dark:hover {
  background-color: #aab2bb; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #aab2bb; }

.table-primary-light,
.table-primary-light > th,
.table-primary-light > td {
  background-color: #b8e3ff; }

.table-primary-light th,
.table-primary-light td,
.table-primary-light thead th,
.table-primary-light tbody + tbody {
  border-color: #7acbff; }

.table-hover .table-primary-light:hover {
  background-color: #9fd9ff; }
  .table-hover .table-primary-light:hover > td,
  .table-hover .table-primary-light:hover > th {
    background-color: #9fd9ff; }

.table-danger-dark,
.table-danger-dark > th,
.table-danger-dark > td {
  background-color: #ecc6c6; }

.table-danger-dark th,
.table-danger-dark td,
.table-danger-dark thead th,
.table-danger-dark tbody + tbody {
  border-color: #db9494; }

.table-hover .table-danger-dark:hover {
  background-color: #e6b3b3; }
  .table-hover .table-danger-dark:hover > td,
  .table-hover .table-danger-dark:hover > th {
    background-color: #e6b3b3; }

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #f6f6f6; }

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
  border-color: #efefef; }

.table-hover .table-gray:hover {
  background-color: #e9e9e9; }
  .table-hover .table-gray:hover > td,
  .table-hover .table-gray:hover > th {
    background-color: #e9e9e9; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #FFFFFF;
  background-color: #011B34;
  border-color: #022e5a; }

.table .thead-light th {
  color: #202020;
  background-color: #979797;
  border-color: #E0E0E0; }

.table-dark {
  color: #FFFFFF;
  background-color: #011B34; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #022e5a; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #FFFFFF;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 349.98px) {
  .table-responsive-s {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-s > .table-bordered {
      border: 0; } }

@media (max-width: 449.98px) {
  .table-responsive-smc {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-smc > .table-bordered {
      border: 0; } }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #011B34;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #011B34;
    background-color: transparent;
    border-color: #52acfd;
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(2, 109, 206, 0.25); }
  .form-control::placeholder {
    color: rgba(1, 27, 52, 0.7);
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #979797;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #011B34; }

select.form-control:focus::-ms-value {
  color: #011B34;
  background-color: transparent; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.14rem + 1px);
  padding-bottom: calc(0.14rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #4a4a4a;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.28rem + 2px);
  padding: 0.14rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 20px; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #979797; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #79AA00; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: rgba(121, 170, 0, 0.9);
  border-radius: 20px; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #79AA00;
  padding-right: calc(1.5em + 1rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2379AA00' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #79AA00;
    box-shadow: 0 0 0 0.15rem rgba(121, 170, 0, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 4rem !important;
  background-position: right 2rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1rem);
  background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #79AA00;
  padding-right: calc(0.75em + 2.75rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2379AA00' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 2rem/calc(0.75em + 0.5rem) calc(0.75em + 0.5rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #79AA00;
    box-shadow: 0 0 0 0.15rem rgba(121, 170, 0, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #79AA00; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #79AA00; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #79AA00; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #9ddd00;
  background-color: #9ddd00; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.15rem rgba(121, 170, 0, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #79AA00; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #79AA00; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #79AA00;
  box-shadow: 0 0 0 0.15rem rgba(121, 170, 0, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #BA3232; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: rgba(186, 50, 50, 0.9);
  border-radius: 20px; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #BA3232;
  padding-right: calc(1.5em + 1rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23BA3232' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23BA3232' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #BA3232;
    box-shadow: 0 0 0 0.15rem rgba(186, 50, 50, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 4rem !important;
  background-position: right 2rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1rem);
  background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #BA3232;
  padding-right: calc(0.75em + 2.75rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23BA3232' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23BA3232' stroke='none'/%3e%3c/svg%3e") center right 2rem/calc(0.75em + 0.5rem) calc(0.75em + 0.5rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #BA3232;
    box-shadow: 0 0 0 0.15rem rgba(186, 50, 50, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #BA3232; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #BA3232; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #BA3232; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #d04f4f;
  background-color: #d04f4f; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.15rem rgba(186, 50, 50, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #BA3232; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #BA3232; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #BA3232;
  box-shadow: 0 0 0 0.15rem rgba(186, 50, 50, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 500;
  color: #4a4a4a;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #4a4a4a;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(2, 109, 206, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #FFFFFF;
  background-color: #026DCE;
  border-color: #026DCE; }
  .btn-primary:hover {
    color: #FFFFFF;
    background-color: #0259a8;
    border-color: #02529b; }
  .btn-primary:focus, .btn-primary.focus {
    color: #FFFFFF;
    background-color: #0259a8;
    border-color: #02529b;
    box-shadow: 0 0 0 0.15rem rgba(40, 131, 213, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #FFFFFF;
    background-color: #026DCE;
    border-color: #026DCE; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #02529b;
    border-color: #014c8f; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(40, 131, 213, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFFFFF; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e5e5; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e5e5;
    box-shadow: 0 0 0 0.15rem rgba(222, 222, 223, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #e6e5e5;
    border-color: #dfdfdf; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(222, 222, 223, 0.5); }

.btn-success {
  color: #FFFFFF;
  background-color: #79AA00;
  border-color: #79AA00; }
  .btn-success:hover {
    color: #FFFFFF;
    background-color: #5e8400;
    border-color: #557700; }
  .btn-success:focus, .btn-success.focus {
    color: #FFFFFF;
    background-color: #5e8400;
    border-color: #557700;
    box-shadow: 0 0 0 0.15rem rgba(141, 183, 38, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #FFFFFF;
    background-color: #79AA00;
    border-color: #79AA00; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #557700;
    border-color: #4c6a00; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(141, 183, 38, 0.5); }

.btn-info {
  color: #FFFFFF;
  background-color: #009BFF;
  border-color: #009BFF; }
  .btn-info:hover {
    color: #FFFFFF;
    background-color: #0084d9;
    border-color: #007ccc; }
  .btn-info:focus, .btn-info.focus {
    color: #FFFFFF;
    background-color: #0084d9;
    border-color: #007ccc;
    box-shadow: 0 0 0 0.15rem rgba(38, 170, 255, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #FFFFFF;
    background-color: #009BFF;
    border-color: #009BFF; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #007ccc;
    border-color: #0074bf; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(38, 170, 255, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #F2A007;
  border-color: #F2A007; }
  .btn-warning:hover {
    color: #FFFFFF;
    background-color: #cd8706;
    border-color: #c07f06; }
  .btn-warning:focus, .btn-warning.focus {
    color: #FFFFFF;
    background-color: #cd8706;
    border-color: #c07f06;
    box-shadow: 0 0 0 0.15rem rgba(211, 142, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #F2A007;
    border-color: #F2A007; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #FFFFFF;
    background-color: #c07f06;
    border-color: #b47705; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(211, 142, 12, 0.5); }

.btn-danger {
  color: #FFFFFF;
  background-color: #BA3232;
  border-color: #BA3232; }
  .btn-danger:hover {
    color: #FFFFFF;
    background-color: #9c2a2a;
    border-color: #922727; }
  .btn-danger:focus, .btn-danger.focus {
    color: #FFFFFF;
    background-color: #9c2a2a;
    border-color: #922727;
    box-shadow: 0 0 0 0.15rem rgba(196, 81, 81, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFFFFF;
    background-color: #BA3232;
    border-color: #BA3232; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #922727;
    border-color: #882424; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(196, 81, 81, 0.5); }

.btn-light {
  color: #212529;
  background-color: #E0E0E0;
  border-color: #E0E0E0; }
  .btn-light:hover {
    color: #212529;
    background-color: #cdcdcd;
    border-color: #c7c6c6; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #cdcdcd;
    border-color: #c7c6c6;
    box-shadow: 0 0 0 0.15rem rgba(195, 196, 197, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #E0E0E0;
    border-color: #E0E0E0; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c7c6c6;
    border-color: silver; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(195, 196, 197, 0.5); }

.btn-dark {
  color: #FFFFFF;
  background-color: #011B34;
  border-color: #011B34; }
  .btn-dark:hover {
    color: #FFFFFF;
    background-color: #00080e;
    border-color: #000102; }
  .btn-dark:focus, .btn-dark.focus {
    color: #FFFFFF;
    background-color: #00080e;
    border-color: #000102;
    box-shadow: 0 0 0 0.15rem rgba(39, 61, 82, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #FFFFFF;
    background-color: #011B34;
    border-color: #011B34; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #000102;
    border-color: black; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(39, 61, 82, 0.5); }

.btn-primary-light {
  color: #FFFFFF;
  background-color: #009BFF;
  border-color: #009BFF; }
  .btn-primary-light:hover {
    color: #FFFFFF;
    background-color: #0084d9;
    border-color: #007ccc; }
  .btn-primary-light:focus, .btn-primary-light.focus {
    color: #FFFFFF;
    background-color: #0084d9;
    border-color: #007ccc;
    box-shadow: 0 0 0 0.15rem rgba(38, 170, 255, 0.5); }
  .btn-primary-light.disabled, .btn-primary-light:disabled {
    color: #FFFFFF;
    background-color: #009BFF;
    border-color: #009BFF; }
  .btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-primary-light.dropdown-toggle {
    color: #FFFFFF;
    background-color: #007ccc;
    border-color: #0074bf; }
    .btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(38, 170, 255, 0.5); }

.btn-danger-dark {
  color: #FFFFFF;
  background-color: #BA3232;
  border-color: #BA3232; }
  .btn-danger-dark:hover {
    color: #FFFFFF;
    background-color: #9c2a2a;
    border-color: #922727; }
  .btn-danger-dark:focus, .btn-danger-dark.focus {
    color: #FFFFFF;
    background-color: #9c2a2a;
    border-color: #922727;
    box-shadow: 0 0 0 0.15rem rgba(196, 81, 81, 0.5); }
  .btn-danger-dark.disabled, .btn-danger-dark:disabled {
    color: #FFFFFF;
    background-color: #BA3232;
    border-color: #BA3232; }
  .btn-danger-dark:not(:disabled):not(.disabled):active, .btn-danger-dark:not(:disabled):not(.disabled).active,
  .show > .btn-danger-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #922727;
    border-color: #882424; }
    .btn-danger-dark:not(:disabled):not(.disabled):active:focus, .btn-danger-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(196, 81, 81, 0.5); }

.btn-gray {
  color: #212529;
  background-color: #E0E0E0;
  border-color: #E0E0E0; }
  .btn-gray:hover {
    color: #212529;
    background-color: #cdcdcd;
    border-color: #c7c6c6; }
  .btn-gray:focus, .btn-gray.focus {
    color: #212529;
    background-color: #cdcdcd;
    border-color: #c7c6c6;
    box-shadow: 0 0 0 0.15rem rgba(195, 196, 197, 0.5); }
  .btn-gray.disabled, .btn-gray:disabled {
    color: #212529;
    background-color: #E0E0E0;
    border-color: #E0E0E0; }
  .btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: #212529;
    background-color: #c7c6c6;
    border-color: silver; }
    .btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(195, 196, 197, 0.5); }

.btn-outline-primary {
  color: #026DCE;
  border-color: #026DCE; }
  .btn-outline-primary:hover {
    color: #FFFFFF;
    background-color: #026DCE;
    border-color: #026DCE; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.15rem rgba(2, 109, 206, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #026DCE;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #026DCE;
    border-color: #026DCE; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(2, 109, 206, 0.5); }

.btn-outline-secondary {
  color: #FFFFFF;
  border-color: #FFFFFF; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #FFFFFF;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, 0.5); }

.btn-outline-success {
  color: #79AA00;
  border-color: #79AA00; }
  .btn-outline-success:hover {
    color: #FFFFFF;
    background-color: #79AA00;
    border-color: #79AA00; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.15rem rgba(121, 170, 0, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #79AA00;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #79AA00;
    border-color: #79AA00; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(121, 170, 0, 0.5); }

.btn-outline-info {
  color: #009BFF;
  border-color: #009BFF; }
  .btn-outline-info:hover {
    color: #FFFFFF;
    background-color: #009BFF;
    border-color: #009BFF; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.15rem rgba(0, 155, 255, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #009BFF;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #009BFF;
    border-color: #009BFF; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(0, 155, 255, 0.5); }

.btn-outline-warning {
  color: #F2A007;
  border-color: #F2A007; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #F2A007;
    border-color: #F2A007; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.15rem rgba(242, 160, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #F2A007;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #F2A007;
    border-color: #F2A007; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(242, 160, 7, 0.5); }

.btn-outline-danger {
  color: #BA3232;
  border-color: #BA3232; }
  .btn-outline-danger:hover {
    color: #FFFFFF;
    background-color: #BA3232;
    border-color: #BA3232; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.15rem rgba(186, 50, 50, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #BA3232;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #BA3232;
    border-color: #BA3232; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(186, 50, 50, 0.5); }

.btn-outline-light {
  color: #E0E0E0;
  border-color: #E0E0E0; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #E0E0E0;
    border-color: #E0E0E0; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.15rem rgba(224, 224, 224, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #E0E0E0;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #E0E0E0;
    border-color: #E0E0E0; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(224, 224, 224, 0.5); }

.btn-outline-dark {
  color: #011B34;
  border-color: #011B34; }
  .btn-outline-dark:hover {
    color: #FFFFFF;
    background-color: #011B34;
    border-color: #011B34; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.15rem rgba(1, 27, 52, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #011B34;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #011B34;
    border-color: #011B34; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(1, 27, 52, 0.5); }

.btn-outline-primary-light {
  color: #009BFF;
  border-color: #009BFF; }
  .btn-outline-primary-light:hover {
    color: #FFFFFF;
    background-color: #009BFF;
    border-color: #009BFF; }
  .btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
    box-shadow: 0 0 0 0.15rem rgba(0, 155, 255, 0.5); }
  .btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
    color: #009BFF;
    background-color: transparent; }
  .btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-light.dropdown-toggle {
    color: #FFFFFF;
    background-color: #009BFF;
    border-color: #009BFF; }
    .btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(0, 155, 255, 0.5); }

.btn-outline-danger-dark {
  color: #BA3232;
  border-color: #BA3232; }
  .btn-outline-danger-dark:hover {
    color: #FFFFFF;
    background-color: #BA3232;
    border-color: #BA3232; }
  .btn-outline-danger-dark:focus, .btn-outline-danger-dark.focus {
    box-shadow: 0 0 0 0.15rem rgba(186, 50, 50, 0.5); }
  .btn-outline-danger-dark.disabled, .btn-outline-danger-dark:disabled {
    color: #BA3232;
    background-color: transparent; }
  .btn-outline-danger-dark:not(:disabled):not(.disabled):active, .btn-outline-danger-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #BA3232;
    border-color: #BA3232; }
    .btn-outline-danger-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-danger-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(186, 50, 50, 0.5); }

.btn-outline-gray {
  color: #E0E0E0;
  border-color: #E0E0E0; }
  .btn-outline-gray:hover {
    color: #212529;
    background-color: #E0E0E0;
    border-color: #E0E0E0; }
  .btn-outline-gray:focus, .btn-outline-gray.focus {
    box-shadow: 0 0 0 0.15rem rgba(224, 224, 224, 0.5); }
  .btn-outline-gray.disabled, .btn-outline-gray:disabled {
    color: #E0E0E0;
    background-color: transparent; }
  .btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: #212529;
    background-color: #E0E0E0;
    border-color: #E0E0E0; }
    .btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(224, 224, 224, 0.5); }

.btn-link {
  font-weight: 300;
  color: #026DCE;
  text-decoration: none; }
  .btn-link:hover {
    color: #014582;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #272727;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 20px; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.14rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 20px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #4a4a4a;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 350px) {
  .dropdown-menu-s-left {
    right: auto;
    left: 0; }
  .dropdown-menu-s-right {
    right: 0;
    left: auto; } }

@media (min-width: 450px) {
  .dropdown-menu-smc-left {
    right: auto;
    left: 0; }
  .dropdown-menu-smc-right {
    right: 0;
    left: auto; } }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1440px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #979797; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 300;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #979797; }
  .dropdown-item.active, .dropdown-item:active {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #026DCE; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #373737;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #272727;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #272727;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #4a4a4a; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #979797 #979797 #4a4a4a; }
    .nav-tabs .nav-link.disabled {
      color: #272727;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #202020;
    background-color: #FFFFFF;
    border-color: #4a4a4a #4a4a4a #FFFFFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #026DCE;
  background-color: #FBFBFB; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.card, .card-listitems, .r-calculator__result {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: tranparent;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem; }
  .card > hr, .card-listitems > hr, .r-calculator__result > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group, .card-listitems > .list-group, .r-calculator__result > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child, .card-listitems > .list-group:first-child, .r-calculator__result > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem; }
    .card > .list-group:last-child, .card-listitems > .list-group:last-child, .r-calculator__result > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem; }
  .card > .card-header + .list-group, .card-listitems > .card-header + .list-group, .r-calculator__result > .card-header + .list-group,
  .card > .list-group + .card-footer,
  .card-listitems > .list-group + .card-footer,
  .r-calculator__result > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0 solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 0.5rem 0.5rem 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 0.5rem 0.5rem; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0.5rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem; }

.card-deck .card, .card-deck .card-listitems, .card-deck .r-calculator__result {
  margin-bottom: 1rem; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -1rem;
    margin-left: -1rem; }
    .card-deck .card, .card-deck .card-listitems, .card-deck .r-calculator__result {
      flex: 1 0 0%;
      margin-right: 1rem;
      margin-bottom: 0;
      margin-left: 1rem; } }

.card-group > .card, .card-group > .card-listitems, .card-group > .r-calculator__result {
  margin-bottom: 0.5rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card, .card-group > .card-listitems, .card-group > .r-calculator__result {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card, .card-group > .card-listitems + .card, .card-group > .r-calculator__result + .card, .card-group > .card + .card-listitems, .card-group > .card-listitems + .card-listitems, .card-group > .r-calculator__result + .card-listitems, .card-group > .card + .r-calculator__result, .card-group > .card-listitems + .r-calculator__result, .card-group > .r-calculator__result + .r-calculator__result {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child), .card-group > .card-listitems:not(:last-child), .card-group > .r-calculator__result:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top, .card-group > .card-listitems:not(:last-child) .card-img-top, .card-group > .r-calculator__result:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header,
        .card-group > .card-listitems:not(:last-child) .card-header,
        .card-group > .r-calculator__result:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card-listitems:not(:last-child) .card-img-bottom, .card-group > .r-calculator__result:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer,
        .card-group > .card-listitems:not(:last-child) .card-footer,
        .card-group > .r-calculator__result:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child), .card-group > .card-listitems:not(:first-child), .card-group > .r-calculator__result:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top, .card-group > .card-listitems:not(:first-child) .card-img-top, .card-group > .r-calculator__result:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header,
        .card-group > .card-listitems:not(:first-child) .card-header,
        .card-group > .r-calculator__result:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card-listitems:not(:first-child) .card-img-bottom, .card-group > .r-calculator__result:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer,
        .card-group > .card-listitems:not(:first-child) .card-footer,
        .card-group > .r-calculator__result:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card, .card-columns .card-listitems, .card-columns .r-calculator__result {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card, .card-columns .card-listitems, .card-columns .r-calculator__result {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card, .accordion > .card-listitems, .accordion > .r-calculator__result {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type), .accordion > .card-listitems:not(:last-of-type), .accordion > .r-calculator__result:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type), .accordion > .card-listitems:not(:first-of-type), .accordion > .r-calculator__result:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header, .accordion > .card-listitems > .card-header, .accordion > .r-calculator__result > .card-header {
      border-radius: 0;
      margin-bottom: 0; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0; }

.list-group-item-action {
  width: 100%;
  color: #011B34;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #011B34;
    text-decoration: none;
    background-color: #FBFBFB; }
  .list-group-item-action:active {
    color: #026DCE;
    background-color: #E0E0E0; }

.list-group-item {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0; }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #272727;
    pointer-events: none;
    background-color: #FFFFFF; }
  .list-group-item.active {
    z-index: 2;
    color: #026DCE;
    background-color: #FBFBFB;
    border-color: #E0E0E0; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 350px) {
  .list-group-horizontal-s {
    flex-direction: row; }
    .list-group-horizontal-s > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-s > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-s > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-s > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-s > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 450px) {
  .list-group-horizontal-smc {
    flex-direction: row; }
    .list-group-horizontal-smc > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-smc > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-smc > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-smc > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-smc > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #01396b;
  background-color: #b8d6f1; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #01396b;
    background-color: #a3caed; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #01396b;
    border-color: #01396b; }

.list-group-item-secondary {
  color: #858585;
  background-color: white; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-success {
  color: #3f5800;
  background-color: #d9e7b8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #3f5800;
    background-color: #cfe1a5; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #3f5800;
    border-color: #3f5800; }

.list-group-item-info {
  color: #005185;
  background-color: #b8e3ff; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #005185;
    background-color: #9fd9ff; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #005185;
    border-color: #005185; }

.list-group-item-warning {
  color: #7e5304;
  background-color: #fbe4ba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7e5304;
    background-color: #fadba2; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #7e5304;
    border-color: #7e5304; }

.list-group-item-danger {
  color: #611a1a;
  background-color: #ecc6c6; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #611a1a;
    background-color: #e6b3b3; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #611a1a;
    border-color: #611a1a; }

.list-group-item-light {
  color: #747474;
  background-color: #f6f6f6; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #747474;
    background-color: #e9e9e9; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #747474;
    border-color: #747474; }

.list-group-item-dark {
  color: #010e1b;
  background-color: #b8bfc6; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #010e1b;
    background-color: #aab2bb; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #010e1b;
    border-color: #010e1b; }

.list-group-item-primary-light {
  color: #005185;
  background-color: #b8e3ff; }
  .list-group-item-primary-light.list-group-item-action:hover, .list-group-item-primary-light.list-group-item-action:focus {
    color: #005185;
    background-color: #9fd9ff; }
  .list-group-item-primary-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #005185;
    border-color: #005185; }

.list-group-item-danger-dark {
  color: #611a1a;
  background-color: #ecc6c6; }
  .list-group-item-danger-dark.list-group-item-action:hover, .list-group-item-danger-dark.list-group-item-action:focus {
    color: #611a1a;
    background-color: #e6b3b3; }
  .list-group-item-danger-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #611a1a;
    border-color: #611a1a; }

.list-group-item-gray {
  color: #747474;
  background-color: #f6f6f6; }
  .list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
    color: #747474;
    background-color: #e9e9e9; }
  .list-group-item-gray.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #747474;
    border-color: #747474; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  color: #011B34;
  text-shadow: 0;
  opacity: .5; }
  .close:hover {
    color: #011B34;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #E0E0E0;
  border-radius: 0.25rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #979797;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #E0E0E0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 768px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 992px; } }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1020;
  display: block;
  max-width: 276px;
  font-family: "sofia-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #FBFBFB;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 2px; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #FBFBFB; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 2px 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #FBFBFB; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #FBFBFB; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f3f3f3; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 2px 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #FBFBFB; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f3f3f3;
  border-bottom: 1px solid #e7e7e7;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #4a4a4a; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #026DCE !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #02529b !important; }

.bg-secondary {
  background-color: #FFFFFF !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e6e5e5 !important; }

.bg-success {
  background-color: #79AA00 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #557700 !important; }

.bg-info {
  background-color: #009BFF !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #007ccc !important; }

.bg-warning {
  background-color: #F2A007 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c07f06 !important; }

.bg-danger {
  background-color: #BA3232 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #922727 !important; }

.bg-light {
  background-color: #E0E0E0 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c7c6c6 !important; }

.bg-dark {
  background-color: #011B34 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #000102 !important; }

.bg-primary-light {
  background-color: #009BFF !important; }

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #007ccc !important; }

.bg-danger-dark {
  background-color: #BA3232 !important; }

a.bg-danger-dark:hover, a.bg-danger-dark:focus,
button.bg-danger-dark:hover,
button.bg-danger-dark:focus {
  background-color: #922727 !important; }

.bg-gray {
  background-color: #E0E0E0 !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #c7c6c6 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #4a4a4a !important; }

.border-top {
  border-top: 1px solid #4a4a4a !important; }

.border-right {
  border-right: 1px solid #4a4a4a !important; }

.border-bottom {
  border-bottom: 1px solid #4a4a4a !important; }

.border-left {
  border-left: 1px solid #4a4a4a !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #026DCE !important; }

.border-secondary {
  border-color: #FFFFFF !important; }

.border-success {
  border-color: #79AA00 !important; }

.border-info {
  border-color: #009BFF !important; }

.border-warning {
  border-color: #F2A007 !important; }

.border-danger {
  border-color: #BA3232 !important; }

.border-light {
  border-color: #E0E0E0 !important; }

.border-dark {
  border-color: #011B34 !important; }

.border-primary-light {
  border-color: #009BFF !important; }

.border-danger-dark {
  border-color: #BA3232 !important; }

.border-gray {
  border-color: #E0E0E0 !important; }

.border-white {
  border-color: #FFFFFF !important; }

.rounded-sm {
  border-radius: 2px !important; }

.rounded {
  border-radius: 20px !important; }

.rounded-top {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important; }

.rounded-right {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important; }

.rounded-bottom {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important; }

.rounded-left {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important; }

.rounded-lg {
  border-radius: 20px !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 350px) {
  .d-s-none {
    display: none !important; }
  .d-s-inline {
    display: inline !important; }
  .d-s-inline-block {
    display: inline-block !important; }
  .d-s-block {
    display: block !important; }
  .d-s-table {
    display: table !important; }
  .d-s-table-row {
    display: table-row !important; }
  .d-s-table-cell {
    display: table-cell !important; }
  .d-s-flex {
    display: flex !important; }
  .d-s-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 450px) {
  .d-smc-none {
    display: none !important; }
  .d-smc-inline {
    display: inline !important; }
  .d-smc-inline-block {
    display: inline-block !important; }
  .d-smc-block {
    display: block !important; }
  .d-smc-table {
    display: table !important; }
  .d-smc-table-row {
    display: table-row !important; }
  .d-smc-table-cell {
    display: table-cell !important; }
  .d-smc-flex {
    display: flex !important; }
  .d-smc-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714286%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 350px) {
  .flex-s-row {
    flex-direction: row !important; }
  .flex-s-column {
    flex-direction: column !important; }
  .flex-s-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-s-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-s-wrap {
    flex-wrap: wrap !important; }
  .flex-s-nowrap {
    flex-wrap: nowrap !important; }
  .flex-s-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-s-fill {
    flex: 1 1 auto !important; }
  .flex-s-grow-0 {
    flex-grow: 0 !important; }
  .flex-s-grow-1 {
    flex-grow: 1 !important; }
  .flex-s-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-s-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-s-start {
    justify-content: flex-start !important; }
  .justify-content-s-end {
    justify-content: flex-end !important; }
  .justify-content-s-center {
    justify-content: center !important; }
  .justify-content-s-between {
    justify-content: space-between !important; }
  .justify-content-s-around {
    justify-content: space-around !important; }
  .align-items-s-start {
    align-items: flex-start !important; }
  .align-items-s-end {
    align-items: flex-end !important; }
  .align-items-s-center {
    align-items: center !important; }
  .align-items-s-baseline {
    align-items: baseline !important; }
  .align-items-s-stretch {
    align-items: stretch !important; }
  .align-content-s-start {
    align-content: flex-start !important; }
  .align-content-s-end {
    align-content: flex-end !important; }
  .align-content-s-center {
    align-content: center !important; }
  .align-content-s-between {
    align-content: space-between !important; }
  .align-content-s-around {
    align-content: space-around !important; }
  .align-content-s-stretch {
    align-content: stretch !important; }
  .align-self-s-auto {
    align-self: auto !important; }
  .align-self-s-start {
    align-self: flex-start !important; }
  .align-self-s-end {
    align-self: flex-end !important; }
  .align-self-s-center {
    align-self: center !important; }
  .align-self-s-baseline {
    align-self: baseline !important; }
  .align-self-s-stretch {
    align-self: stretch !important; } }

@media (min-width: 450px) {
  .flex-smc-row {
    flex-direction: row !important; }
  .flex-smc-column {
    flex-direction: column !important; }
  .flex-smc-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-smc-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-smc-wrap {
    flex-wrap: wrap !important; }
  .flex-smc-nowrap {
    flex-wrap: nowrap !important; }
  .flex-smc-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-smc-fill {
    flex: 1 1 auto !important; }
  .flex-smc-grow-0 {
    flex-grow: 0 !important; }
  .flex-smc-grow-1 {
    flex-grow: 1 !important; }
  .flex-smc-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-smc-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-smc-start {
    justify-content: flex-start !important; }
  .justify-content-smc-end {
    justify-content: flex-end !important; }
  .justify-content-smc-center {
    justify-content: center !important; }
  .justify-content-smc-between {
    justify-content: space-between !important; }
  .justify-content-smc-around {
    justify-content: space-around !important; }
  .align-items-smc-start {
    align-items: flex-start !important; }
  .align-items-smc-end {
    align-items: flex-end !important; }
  .align-items-smc-center {
    align-items: center !important; }
  .align-items-smc-baseline {
    align-items: baseline !important; }
  .align-items-smc-stretch {
    align-items: stretch !important; }
  .align-content-smc-start {
    align-content: flex-start !important; }
  .align-content-smc-end {
    align-content: flex-end !important; }
  .align-content-smc-center {
    align-content: center !important; }
  .align-content-smc-between {
    align-content: space-between !important; }
  .align-content-smc-around {
    align-content: space-around !important; }
  .align-content-smc-stretch {
    align-content: stretch !important; }
  .align-self-smc-auto {
    align-self: auto !important; }
  .align-self-smc-start {
    align-self: flex-start !important; }
  .align-self-smc-end {
    align-self: flex-end !important; }
  .align-self-smc-center {
    align-self: center !important; }
  .align-self-smc-baseline {
    align-self: baseline !important; }
  .align-self-smc-stretch {
    align-self: stretch !important; } }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 350px) {
  .float-s-left {
    float: left !important; }
  .float-s-right {
    float: right !important; }
  .float-s-none {
    float: none !important; } }

@media (min-width: 450px) {
  .float-smc-left {
    float: left !important; }
  .float-smc-right {
    float: right !important; }
  .float-smc-none {
    float: none !important; } }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom, .sticky-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 350px) {
  .m-s-0 {
    margin: 0 !important; }
  .mt-s-0,
  .my-s-0 {
    margin-top: 0 !important; }
  .mr-s-0,
  .mx-s-0 {
    margin-right: 0 !important; }
  .mb-s-0,
  .my-s-0 {
    margin-bottom: 0 !important; }
  .ml-s-0,
  .mx-s-0 {
    margin-left: 0 !important; }
  .m-s-1 {
    margin: 0.25rem !important; }
  .mt-s-1,
  .my-s-1 {
    margin-top: 0.25rem !important; }
  .mr-s-1,
  .mx-s-1 {
    margin-right: 0.25rem !important; }
  .mb-s-1,
  .my-s-1 {
    margin-bottom: 0.25rem !important; }
  .ml-s-1,
  .mx-s-1 {
    margin-left: 0.25rem !important; }
  .m-s-2 {
    margin: 0.5rem !important; }
  .mt-s-2,
  .my-s-2 {
    margin-top: 0.5rem !important; }
  .mr-s-2,
  .mx-s-2 {
    margin-right: 0.5rem !important; }
  .mb-s-2,
  .my-s-2 {
    margin-bottom: 0.5rem !important; }
  .ml-s-2,
  .mx-s-2 {
    margin-left: 0.5rem !important; }
  .m-s-3 {
    margin: 1rem !important; }
  .mt-s-3,
  .my-s-3 {
    margin-top: 1rem !important; }
  .mr-s-3,
  .mx-s-3 {
    margin-right: 1rem !important; }
  .mb-s-3,
  .my-s-3 {
    margin-bottom: 1rem !important; }
  .ml-s-3,
  .mx-s-3 {
    margin-left: 1rem !important; }
  .m-s-4 {
    margin: 1.5rem !important; }
  .mt-s-4,
  .my-s-4 {
    margin-top: 1.5rem !important; }
  .mr-s-4,
  .mx-s-4 {
    margin-right: 1.5rem !important; }
  .mb-s-4,
  .my-s-4 {
    margin-bottom: 1.5rem !important; }
  .ml-s-4,
  .mx-s-4 {
    margin-left: 1.5rem !important; }
  .m-s-5 {
    margin: 3rem !important; }
  .mt-s-5,
  .my-s-5 {
    margin-top: 3rem !important; }
  .mr-s-5,
  .mx-s-5 {
    margin-right: 3rem !important; }
  .mb-s-5,
  .my-s-5 {
    margin-bottom: 3rem !important; }
  .ml-s-5,
  .mx-s-5 {
    margin-left: 3rem !important; }
  .p-s-0 {
    padding: 0 !important; }
  .pt-s-0,
  .py-s-0 {
    padding-top: 0 !important; }
  .pr-s-0,
  .px-s-0 {
    padding-right: 0 !important; }
  .pb-s-0,
  .py-s-0 {
    padding-bottom: 0 !important; }
  .pl-s-0,
  .px-s-0 {
    padding-left: 0 !important; }
  .p-s-1 {
    padding: 0.25rem !important; }
  .pt-s-1,
  .py-s-1 {
    padding-top: 0.25rem !important; }
  .pr-s-1,
  .px-s-1 {
    padding-right: 0.25rem !important; }
  .pb-s-1,
  .py-s-1 {
    padding-bottom: 0.25rem !important; }
  .pl-s-1,
  .px-s-1 {
    padding-left: 0.25rem !important; }
  .p-s-2 {
    padding: 0.5rem !important; }
  .pt-s-2,
  .py-s-2 {
    padding-top: 0.5rem !important; }
  .pr-s-2,
  .px-s-2 {
    padding-right: 0.5rem !important; }
  .pb-s-2,
  .py-s-2 {
    padding-bottom: 0.5rem !important; }
  .pl-s-2,
  .px-s-2 {
    padding-left: 0.5rem !important; }
  .p-s-3 {
    padding: 1rem !important; }
  .pt-s-3,
  .py-s-3 {
    padding-top: 1rem !important; }
  .pr-s-3,
  .px-s-3 {
    padding-right: 1rem !important; }
  .pb-s-3,
  .py-s-3 {
    padding-bottom: 1rem !important; }
  .pl-s-3,
  .px-s-3 {
    padding-left: 1rem !important; }
  .p-s-4 {
    padding: 1.5rem !important; }
  .pt-s-4,
  .py-s-4 {
    padding-top: 1.5rem !important; }
  .pr-s-4,
  .px-s-4 {
    padding-right: 1.5rem !important; }
  .pb-s-4,
  .py-s-4 {
    padding-bottom: 1.5rem !important; }
  .pl-s-4,
  .px-s-4 {
    padding-left: 1.5rem !important; }
  .p-s-5 {
    padding: 3rem !important; }
  .pt-s-5,
  .py-s-5 {
    padding-top: 3rem !important; }
  .pr-s-5,
  .px-s-5 {
    padding-right: 3rem !important; }
  .pb-s-5,
  .py-s-5 {
    padding-bottom: 3rem !important; }
  .pl-s-5,
  .px-s-5 {
    padding-left: 3rem !important; }
  .m-s-n1 {
    margin: -0.25rem !important; }
  .mt-s-n1,
  .my-s-n1 {
    margin-top: -0.25rem !important; }
  .mr-s-n1,
  .mx-s-n1 {
    margin-right: -0.25rem !important; }
  .mb-s-n1,
  .my-s-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-s-n1,
  .mx-s-n1 {
    margin-left: -0.25rem !important; }
  .m-s-n2 {
    margin: -0.5rem !important; }
  .mt-s-n2,
  .my-s-n2 {
    margin-top: -0.5rem !important; }
  .mr-s-n2,
  .mx-s-n2 {
    margin-right: -0.5rem !important; }
  .mb-s-n2,
  .my-s-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-s-n2,
  .mx-s-n2 {
    margin-left: -0.5rem !important; }
  .m-s-n3 {
    margin: -1rem !important; }
  .mt-s-n3,
  .my-s-n3 {
    margin-top: -1rem !important; }
  .mr-s-n3,
  .mx-s-n3 {
    margin-right: -1rem !important; }
  .mb-s-n3,
  .my-s-n3 {
    margin-bottom: -1rem !important; }
  .ml-s-n3,
  .mx-s-n3 {
    margin-left: -1rem !important; }
  .m-s-n4 {
    margin: -1.5rem !important; }
  .mt-s-n4,
  .my-s-n4 {
    margin-top: -1.5rem !important; }
  .mr-s-n4,
  .mx-s-n4 {
    margin-right: -1.5rem !important; }
  .mb-s-n4,
  .my-s-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-s-n4,
  .mx-s-n4 {
    margin-left: -1.5rem !important; }
  .m-s-n5 {
    margin: -3rem !important; }
  .mt-s-n5,
  .my-s-n5 {
    margin-top: -3rem !important; }
  .mr-s-n5,
  .mx-s-n5 {
    margin-right: -3rem !important; }
  .mb-s-n5,
  .my-s-n5 {
    margin-bottom: -3rem !important; }
  .ml-s-n5,
  .mx-s-n5 {
    margin-left: -3rem !important; }
  .m-s-auto {
    margin: auto !important; }
  .mt-s-auto,
  .my-s-auto {
    margin-top: auto !important; }
  .mr-s-auto,
  .mx-s-auto {
    margin-right: auto !important; }
  .mb-s-auto,
  .my-s-auto {
    margin-bottom: auto !important; }
  .ml-s-auto,
  .mx-s-auto {
    margin-left: auto !important; } }

@media (min-width: 450px) {
  .m-smc-0 {
    margin: 0 !important; }
  .mt-smc-0,
  .my-smc-0 {
    margin-top: 0 !important; }
  .mr-smc-0,
  .mx-smc-0 {
    margin-right: 0 !important; }
  .mb-smc-0,
  .my-smc-0 {
    margin-bottom: 0 !important; }
  .ml-smc-0,
  .mx-smc-0 {
    margin-left: 0 !important; }
  .m-smc-1 {
    margin: 0.25rem !important; }
  .mt-smc-1,
  .my-smc-1 {
    margin-top: 0.25rem !important; }
  .mr-smc-1,
  .mx-smc-1 {
    margin-right: 0.25rem !important; }
  .mb-smc-1,
  .my-smc-1 {
    margin-bottom: 0.25rem !important; }
  .ml-smc-1,
  .mx-smc-1 {
    margin-left: 0.25rem !important; }
  .m-smc-2 {
    margin: 0.5rem !important; }
  .mt-smc-2,
  .my-smc-2 {
    margin-top: 0.5rem !important; }
  .mr-smc-2,
  .mx-smc-2 {
    margin-right: 0.5rem !important; }
  .mb-smc-2,
  .my-smc-2 {
    margin-bottom: 0.5rem !important; }
  .ml-smc-2,
  .mx-smc-2 {
    margin-left: 0.5rem !important; }
  .m-smc-3 {
    margin: 1rem !important; }
  .mt-smc-3,
  .my-smc-3 {
    margin-top: 1rem !important; }
  .mr-smc-3,
  .mx-smc-3 {
    margin-right: 1rem !important; }
  .mb-smc-3,
  .my-smc-3 {
    margin-bottom: 1rem !important; }
  .ml-smc-3,
  .mx-smc-3 {
    margin-left: 1rem !important; }
  .m-smc-4 {
    margin: 1.5rem !important; }
  .mt-smc-4,
  .my-smc-4 {
    margin-top: 1.5rem !important; }
  .mr-smc-4,
  .mx-smc-4 {
    margin-right: 1.5rem !important; }
  .mb-smc-4,
  .my-smc-4 {
    margin-bottom: 1.5rem !important; }
  .ml-smc-4,
  .mx-smc-4 {
    margin-left: 1.5rem !important; }
  .m-smc-5 {
    margin: 3rem !important; }
  .mt-smc-5,
  .my-smc-5 {
    margin-top: 3rem !important; }
  .mr-smc-5,
  .mx-smc-5 {
    margin-right: 3rem !important; }
  .mb-smc-5,
  .my-smc-5 {
    margin-bottom: 3rem !important; }
  .ml-smc-5,
  .mx-smc-5 {
    margin-left: 3rem !important; }
  .p-smc-0 {
    padding: 0 !important; }
  .pt-smc-0,
  .py-smc-0 {
    padding-top: 0 !important; }
  .pr-smc-0,
  .px-smc-0 {
    padding-right: 0 !important; }
  .pb-smc-0,
  .py-smc-0 {
    padding-bottom: 0 !important; }
  .pl-smc-0,
  .px-smc-0 {
    padding-left: 0 !important; }
  .p-smc-1 {
    padding: 0.25rem !important; }
  .pt-smc-1,
  .py-smc-1 {
    padding-top: 0.25rem !important; }
  .pr-smc-1,
  .px-smc-1 {
    padding-right: 0.25rem !important; }
  .pb-smc-1,
  .py-smc-1 {
    padding-bottom: 0.25rem !important; }
  .pl-smc-1,
  .px-smc-1 {
    padding-left: 0.25rem !important; }
  .p-smc-2 {
    padding: 0.5rem !important; }
  .pt-smc-2,
  .py-smc-2 {
    padding-top: 0.5rem !important; }
  .pr-smc-2,
  .px-smc-2 {
    padding-right: 0.5rem !important; }
  .pb-smc-2,
  .py-smc-2 {
    padding-bottom: 0.5rem !important; }
  .pl-smc-2,
  .px-smc-2 {
    padding-left: 0.5rem !important; }
  .p-smc-3 {
    padding: 1rem !important; }
  .pt-smc-3,
  .py-smc-3 {
    padding-top: 1rem !important; }
  .pr-smc-3,
  .px-smc-3 {
    padding-right: 1rem !important; }
  .pb-smc-3,
  .py-smc-3 {
    padding-bottom: 1rem !important; }
  .pl-smc-3,
  .px-smc-3 {
    padding-left: 1rem !important; }
  .p-smc-4 {
    padding: 1.5rem !important; }
  .pt-smc-4,
  .py-smc-4 {
    padding-top: 1.5rem !important; }
  .pr-smc-4,
  .px-smc-4 {
    padding-right: 1.5rem !important; }
  .pb-smc-4,
  .py-smc-4 {
    padding-bottom: 1.5rem !important; }
  .pl-smc-4,
  .px-smc-4 {
    padding-left: 1.5rem !important; }
  .p-smc-5 {
    padding: 3rem !important; }
  .pt-smc-5,
  .py-smc-5 {
    padding-top: 3rem !important; }
  .pr-smc-5,
  .px-smc-5 {
    padding-right: 3rem !important; }
  .pb-smc-5,
  .py-smc-5 {
    padding-bottom: 3rem !important; }
  .pl-smc-5,
  .px-smc-5 {
    padding-left: 3rem !important; }
  .m-smc-n1 {
    margin: -0.25rem !important; }
  .mt-smc-n1,
  .my-smc-n1 {
    margin-top: -0.25rem !important; }
  .mr-smc-n1,
  .mx-smc-n1 {
    margin-right: -0.25rem !important; }
  .mb-smc-n1,
  .my-smc-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-smc-n1,
  .mx-smc-n1 {
    margin-left: -0.25rem !important; }
  .m-smc-n2 {
    margin: -0.5rem !important; }
  .mt-smc-n2,
  .my-smc-n2 {
    margin-top: -0.5rem !important; }
  .mr-smc-n2,
  .mx-smc-n2 {
    margin-right: -0.5rem !important; }
  .mb-smc-n2,
  .my-smc-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-smc-n2,
  .mx-smc-n2 {
    margin-left: -0.5rem !important; }
  .m-smc-n3 {
    margin: -1rem !important; }
  .mt-smc-n3,
  .my-smc-n3 {
    margin-top: -1rem !important; }
  .mr-smc-n3,
  .mx-smc-n3 {
    margin-right: -1rem !important; }
  .mb-smc-n3,
  .my-smc-n3 {
    margin-bottom: -1rem !important; }
  .ml-smc-n3,
  .mx-smc-n3 {
    margin-left: -1rem !important; }
  .m-smc-n4 {
    margin: -1.5rem !important; }
  .mt-smc-n4,
  .my-smc-n4 {
    margin-top: -1.5rem !important; }
  .mr-smc-n4,
  .mx-smc-n4 {
    margin-right: -1.5rem !important; }
  .mb-smc-n4,
  .my-smc-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-smc-n4,
  .mx-smc-n4 {
    margin-left: -1.5rem !important; }
  .m-smc-n5 {
    margin: -3rem !important; }
  .mt-smc-n5,
  .my-smc-n5 {
    margin-top: -3rem !important; }
  .mr-smc-n5,
  .mx-smc-n5 {
    margin-right: -3rem !important; }
  .mb-smc-n5,
  .my-smc-n5 {
    margin-bottom: -3rem !important; }
  .ml-smc-n5,
  .mx-smc-n5 {
    margin-left: -3rem !important; }
  .m-smc-auto {
    margin: auto !important; }
  .mt-smc-auto,
  .my-smc-auto {
    margin-top: auto !important; }
  .mr-smc-auto,
  .mx-smc-auto {
    margin-right: auto !important; }
  .mb-smc-auto,
  .my-smc-auto {
    margin-bottom: auto !important; }
  .ml-smc-auto,
  .mx-smc-auto {
    margin-left: auto !important; } }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 350px) {
  .text-s-left {
    text-align: left !important; }
  .text-s-right {
    text-align: right !important; }
  .text-s-center {
    text-align: center !important; } }

@media (min-width: 450px) {
  .text-smc-left {
    text-align: left !important; }
  .text-smc-right {
    text-align: right !important; }
  .text-smc-center {
    text-align: center !important; } }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 300 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFFFFF !important; }

.text-primary {
  color: #026DCE !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #014582 !important; }

.text-secondary {
  color: #FFFFFF !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #d9d9d9 !important; }

.text-success {
  color: #79AA00 !important; }

a.text-success:hover, a.text-success:focus {
  color: #435e00 !important; }

.text-info {
  color: #009BFF !important; }

a.text-info:hover, a.text-info:focus {
  color: #006db3 !important; }

.text-warning {
  color: #F2A007 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #a86f05 !important; }

.text-danger {
  color: #BA3232 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #7e2222 !important; }

.text-light {
  color: #E0E0E0 !important; }

a.text-light:hover, a.text-light:focus {
  color: #bababa !important; }

.text-dark {
  color: #011B34 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-primary-light {
  color: #009BFF !important; }

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #006db3 !important; }

.text-danger-dark {
  color: #BA3232 !important; }

a.text-danger-dark:hover, a.text-danger-dark:focus {
  color: #7e2222 !important; }

.text-gray {
  color: #E0E0E0 !important; }

a.text-gray:hover, a.text-gray:focus {
  color: #bababa !important; }

.text-body {
  color: #4a4a4a !important; }

.text-muted {
  color: #979797 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 1200px) {
  .container--xl {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto; } }

.dx-m-0 {
  margin: 0 !important; }

.dx-mt-0,
.dx-my-0 {
  margin-top: 0 !important; }

.dx-mr-0,
.dx-mx-0 {
  margin-right: 0 !important; }

.dx-mb-0,
.dx-my-0 {
  margin-bottom: 0 !important; }

.dx-ml-0,
.dx-mx-0 {
  margin-left: 0 !important; }

.dx-m-1 {
  margin: 8px !important; }

.dx-mt-1,
.dx-my-1 {
  margin-top: 8px !important; }

.dx-mr-1,
.dx-mx-1 {
  margin-right: 8px !important; }

.dx-mb-1,
.dx-my-1 {
  margin-bottom: 8px !important; }

.dx-ml-1,
.dx-mx-1 {
  margin-left: 8px !important; }

.dx-m-2 {
  margin: 16px !important; }

.dx-mt-2,
.dx-my-2 {
  margin-top: 16px !important; }

.dx-mr-2,
.dx-mx-2 {
  margin-right: 16px !important; }

.dx-mb-2,
.dx-my-2 {
  margin-bottom: 16px !important; }

.dx-ml-2,
.dx-mx-2 {
  margin-left: 16px !important; }

.dx-m-3 {
  margin: 24px !important; }

.dx-mt-3,
.dx-my-3 {
  margin-top: 24px !important; }

.dx-mr-3,
.dx-mx-3 {
  margin-right: 24px !important; }

.dx-mb-3,
.dx-my-3 {
  margin-bottom: 24px !important; }

.dx-ml-3,
.dx-mx-3 {
  margin-left: 24px !important; }

.dx-m-4 {
  margin: 32px !important; }

.dx-mt-4,
.dx-my-4 {
  margin-top: 32px !important; }

.dx-mr-4,
.dx-mx-4 {
  margin-right: 32px !important; }

.dx-mb-4,
.dx-my-4 {
  margin-bottom: 32px !important; }

.dx-ml-4,
.dx-mx-4 {
  margin-left: 32px !important; }

.dx-m-5 {
  margin: 40px !important; }

.dx-mt-5,
.dx-my-5 {
  margin-top: 40px !important; }

.dx-mr-5,
.dx-mx-5 {
  margin-right: 40px !important; }

.dx-mb-5,
.dx-my-5 {
  margin-bottom: 40px !important; }

.dx-ml-5,
.dx-mx-5 {
  margin-left: 40px !important; }

.dx-m-6 {
  margin: 48px !important; }

.dx-mt-6,
.dx-my-6 {
  margin-top: 48px !important; }

.dx-mr-6,
.dx-mx-6 {
  margin-right: 48px !important; }

.dx-mb-6,
.dx-my-6 {
  margin-bottom: 48px !important; }

.dx-ml-6,
.dx-mx-6 {
  margin-left: 48px !important; }

.dx-m-7 {
  margin: 56px !important; }

.dx-mt-7,
.dx-my-7 {
  margin-top: 56px !important; }

.dx-mr-7,
.dx-mx-7 {
  margin-right: 56px !important; }

.dx-mb-7,
.dx-my-7 {
  margin-bottom: 56px !important; }

.dx-ml-7,
.dx-mx-7 {
  margin-left: 56px !important; }

.dx-m-8 {
  margin: 64px !important; }

.dx-mt-8,
.dx-my-8 {
  margin-top: 64px !important; }

.dx-mr-8,
.dx-mx-8 {
  margin-right: 64px !important; }

.dx-mb-8,
.dx-my-8 {
  margin-bottom: 64px !important; }

.dx-ml-8,
.dx-mx-8 {
  margin-left: 64px !important; }

.dx-p-0 {
  padding: 0 !important; }

.dx-pt-0,
.dx-py-0 {
  padding-top: 0 !important; }

.dx-pr-0,
.dx-px-0 {
  padding-right: 0 !important; }

.dx-pb-0,
.dx-py-0 {
  padding-bottom: 0 !important; }

.dx-pl-0,
.dx-px-0 {
  padding-left: 0 !important; }

.dx-p-1 {
  padding: 8px !important; }

.dx-pt-1,
.dx-py-1 {
  padding-top: 8px !important; }

.dx-pr-1,
.dx-px-1 {
  padding-right: 8px !important; }

.dx-pb-1,
.dx-py-1 {
  padding-bottom: 8px !important; }

.dx-pl-1,
.dx-px-1 {
  padding-left: 8px !important; }

.dx-p-2 {
  padding: 16px !important; }

.dx-pt-2,
.dx-py-2 {
  padding-top: 16px !important; }

.dx-pr-2,
.dx-px-2 {
  padding-right: 16px !important; }

.dx-pb-2,
.dx-py-2 {
  padding-bottom: 16px !important; }

.dx-pl-2,
.dx-px-2 {
  padding-left: 16px !important; }

.dx-p-3 {
  padding: 24px !important; }

.dx-pt-3,
.dx-py-3 {
  padding-top: 24px !important; }

.dx-pr-3,
.dx-px-3 {
  padding-right: 24px !important; }

.dx-pb-3,
.dx-py-3 {
  padding-bottom: 24px !important; }

.dx-pl-3,
.dx-px-3 {
  padding-left: 24px !important; }

.dx-p-4 {
  padding: 32px !important; }

.dx-pt-4,
.dx-py-4 {
  padding-top: 32px !important; }

.dx-pr-4,
.dx-px-4 {
  padding-right: 32px !important; }

.dx-pb-4,
.dx-py-4 {
  padding-bottom: 32px !important; }

.dx-pl-4,
.dx-px-4 {
  padding-left: 32px !important; }

.dx-p-5 {
  padding: 40px !important; }

.dx-pt-5,
.dx-py-5 {
  padding-top: 40px !important; }

.dx-pr-5,
.dx-px-5 {
  padding-right: 40px !important; }

.dx-pb-5,
.dx-py-5 {
  padding-bottom: 40px !important; }

.dx-pl-5,
.dx-px-5 {
  padding-left: 40px !important; }

.dx-p-6 {
  padding: 48px !important; }

.dx-pt-6,
.dx-py-6 {
  padding-top: 48px !important; }

.dx-pr-6,
.dx-px-6 {
  padding-right: 48px !important; }

.dx-pb-6,
.dx-py-6 {
  padding-bottom: 48px !important; }

.dx-pl-6,
.dx-px-6 {
  padding-left: 48px !important; }

.dx-p-7 {
  padding: 56px !important; }

.dx-pt-7,
.dx-py-7 {
  padding-top: 56px !important; }

.dx-pr-7,
.dx-px-7 {
  padding-right: 56px !important; }

.dx-pb-7,
.dx-py-7 {
  padding-bottom: 56px !important; }

.dx-pl-7,
.dx-px-7 {
  padding-left: 56px !important; }

.dx-p-8 {
  padding: 64px !important; }

.dx-pt-8,
.dx-py-8 {
  padding-top: 64px !important; }

.dx-pr-8,
.dx-px-8 {
  padding-right: 64px !important; }

.dx-pb-8,
.dx-py-8 {
  padding-bottom: 64px !important; }

.dx-pl-8,
.dx-px-8 {
  padding-left: 64px !important; }

@media (min-width: 350px) {
  .dx-m-s-0 {
    margin: 0 !important; }
  .dx-mt-s-0,
  .dx-my-s-0 {
    margin-top: 0 !important; }
  .dx-mr-s-0,
  .dx-mx-s-0 {
    margin-right: 0 !important; }
  .dx-mb-s-0,
  .dx-my-s-0 {
    margin-bottom: 0 !important; }
  .dx-ml-s-0,
  .dx-mx-s-0 {
    margin-left: 0 !important; }
  .dx-m-s-1 {
    margin: 8px !important; }
  .dx-mt-s-1,
  .dx-my-s-1 {
    margin-top: 8px !important; }
  .dx-mr-s-1,
  .dx-mx-s-1 {
    margin-right: 8px !important; }
  .dx-mb-s-1,
  .dx-my-s-1 {
    margin-bottom: 8px !important; }
  .dx-ml-s-1,
  .dx-mx-s-1 {
    margin-left: 8px !important; }
  .dx-m-s-2 {
    margin: 16px !important; }
  .dx-mt-s-2,
  .dx-my-s-2 {
    margin-top: 16px !important; }
  .dx-mr-s-2,
  .dx-mx-s-2 {
    margin-right: 16px !important; }
  .dx-mb-s-2,
  .dx-my-s-2 {
    margin-bottom: 16px !important; }
  .dx-ml-s-2,
  .dx-mx-s-2 {
    margin-left: 16px !important; }
  .dx-m-s-3 {
    margin: 24px !important; }
  .dx-mt-s-3,
  .dx-my-s-3 {
    margin-top: 24px !important; }
  .dx-mr-s-3,
  .dx-mx-s-3 {
    margin-right: 24px !important; }
  .dx-mb-s-3,
  .dx-my-s-3 {
    margin-bottom: 24px !important; }
  .dx-ml-s-3,
  .dx-mx-s-3 {
    margin-left: 24px !important; }
  .dx-m-s-4 {
    margin: 32px !important; }
  .dx-mt-s-4,
  .dx-my-s-4 {
    margin-top: 32px !important; }
  .dx-mr-s-4,
  .dx-mx-s-4 {
    margin-right: 32px !important; }
  .dx-mb-s-4,
  .dx-my-s-4 {
    margin-bottom: 32px !important; }
  .dx-ml-s-4,
  .dx-mx-s-4 {
    margin-left: 32px !important; }
  .dx-m-s-5 {
    margin: 40px !important; }
  .dx-mt-s-5,
  .dx-my-s-5 {
    margin-top: 40px !important; }
  .dx-mr-s-5,
  .dx-mx-s-5 {
    margin-right: 40px !important; }
  .dx-mb-s-5,
  .dx-my-s-5 {
    margin-bottom: 40px !important; }
  .dx-ml-s-5,
  .dx-mx-s-5 {
    margin-left: 40px !important; }
  .dx-m-s-6 {
    margin: 48px !important; }
  .dx-mt-s-6,
  .dx-my-s-6 {
    margin-top: 48px !important; }
  .dx-mr-s-6,
  .dx-mx-s-6 {
    margin-right: 48px !important; }
  .dx-mb-s-6,
  .dx-my-s-6 {
    margin-bottom: 48px !important; }
  .dx-ml-s-6,
  .dx-mx-s-6 {
    margin-left: 48px !important; }
  .dx-m-s-7 {
    margin: 56px !important; }
  .dx-mt-s-7,
  .dx-my-s-7 {
    margin-top: 56px !important; }
  .dx-mr-s-7,
  .dx-mx-s-7 {
    margin-right: 56px !important; }
  .dx-mb-s-7,
  .dx-my-s-7 {
    margin-bottom: 56px !important; }
  .dx-ml-s-7,
  .dx-mx-s-7 {
    margin-left: 56px !important; }
  .dx-m-s-8 {
    margin: 64px !important; }
  .dx-mt-s-8,
  .dx-my-s-8 {
    margin-top: 64px !important; }
  .dx-mr-s-8,
  .dx-mx-s-8 {
    margin-right: 64px !important; }
  .dx-mb-s-8,
  .dx-my-s-8 {
    margin-bottom: 64px !important; }
  .dx-ml-s-8,
  .dx-mx-s-8 {
    margin-left: 64px !important; }
  .dx-p-s-0 {
    padding: 0 !important; }
  .dx-pt-s-0,
  .dx-py-s-0 {
    padding-top: 0 !important; }
  .dx-pr-s-0,
  .dx-px-s-0 {
    padding-right: 0 !important; }
  .dx-pb-s-0,
  .dx-py-s-0 {
    padding-bottom: 0 !important; }
  .dx-pl-s-0,
  .dx-px-s-0 {
    padding-left: 0 !important; }
  .dx-p-s-1 {
    padding: 8px !important; }
  .dx-pt-s-1,
  .dx-py-s-1 {
    padding-top: 8px !important; }
  .dx-pr-s-1,
  .dx-px-s-1 {
    padding-right: 8px !important; }
  .dx-pb-s-1,
  .dx-py-s-1 {
    padding-bottom: 8px !important; }
  .dx-pl-s-1,
  .dx-px-s-1 {
    padding-left: 8px !important; }
  .dx-p-s-2 {
    padding: 16px !important; }
  .dx-pt-s-2,
  .dx-py-s-2 {
    padding-top: 16px !important; }
  .dx-pr-s-2,
  .dx-px-s-2 {
    padding-right: 16px !important; }
  .dx-pb-s-2,
  .dx-py-s-2 {
    padding-bottom: 16px !important; }
  .dx-pl-s-2,
  .dx-px-s-2 {
    padding-left: 16px !important; }
  .dx-p-s-3 {
    padding: 24px !important; }
  .dx-pt-s-3,
  .dx-py-s-3 {
    padding-top: 24px !important; }
  .dx-pr-s-3,
  .dx-px-s-3 {
    padding-right: 24px !important; }
  .dx-pb-s-3,
  .dx-py-s-3 {
    padding-bottom: 24px !important; }
  .dx-pl-s-3,
  .dx-px-s-3 {
    padding-left: 24px !important; }
  .dx-p-s-4 {
    padding: 32px !important; }
  .dx-pt-s-4,
  .dx-py-s-4 {
    padding-top: 32px !important; }
  .dx-pr-s-4,
  .dx-px-s-4 {
    padding-right: 32px !important; }
  .dx-pb-s-4,
  .dx-py-s-4 {
    padding-bottom: 32px !important; }
  .dx-pl-s-4,
  .dx-px-s-4 {
    padding-left: 32px !important; }
  .dx-p-s-5 {
    padding: 40px !important; }
  .dx-pt-s-5,
  .dx-py-s-5 {
    padding-top: 40px !important; }
  .dx-pr-s-5,
  .dx-px-s-5 {
    padding-right: 40px !important; }
  .dx-pb-s-5,
  .dx-py-s-5 {
    padding-bottom: 40px !important; }
  .dx-pl-s-5,
  .dx-px-s-5 {
    padding-left: 40px !important; }
  .dx-p-s-6 {
    padding: 48px !important; }
  .dx-pt-s-6,
  .dx-py-s-6 {
    padding-top: 48px !important; }
  .dx-pr-s-6,
  .dx-px-s-6 {
    padding-right: 48px !important; }
  .dx-pb-s-6,
  .dx-py-s-6 {
    padding-bottom: 48px !important; }
  .dx-pl-s-6,
  .dx-px-s-6 {
    padding-left: 48px !important; }
  .dx-p-s-7 {
    padding: 56px !important; }
  .dx-pt-s-7,
  .dx-py-s-7 {
    padding-top: 56px !important; }
  .dx-pr-s-7,
  .dx-px-s-7 {
    padding-right: 56px !important; }
  .dx-pb-s-7,
  .dx-py-s-7 {
    padding-bottom: 56px !important; }
  .dx-pl-s-7,
  .dx-px-s-7 {
    padding-left: 56px !important; }
  .dx-p-s-8 {
    padding: 64px !important; }
  .dx-pt-s-8,
  .dx-py-s-8 {
    padding-top: 64px !important; }
  .dx-pr-s-8,
  .dx-px-s-8 {
    padding-right: 64px !important; }
  .dx-pb-s-8,
  .dx-py-s-8 {
    padding-bottom: 64px !important; }
  .dx-pl-s-8,
  .dx-px-s-8 {
    padding-left: 64px !important; } }

@media (min-width: 450px) {
  .dx-m-smc-0 {
    margin: 0 !important; }
  .dx-mt-smc-0,
  .dx-my-smc-0 {
    margin-top: 0 !important; }
  .dx-mr-smc-0,
  .dx-mx-smc-0 {
    margin-right: 0 !important; }
  .dx-mb-smc-0,
  .dx-my-smc-0 {
    margin-bottom: 0 !important; }
  .dx-ml-smc-0,
  .dx-mx-smc-0 {
    margin-left: 0 !important; }
  .dx-m-smc-1 {
    margin: 8px !important; }
  .dx-mt-smc-1,
  .dx-my-smc-1 {
    margin-top: 8px !important; }
  .dx-mr-smc-1,
  .dx-mx-smc-1 {
    margin-right: 8px !important; }
  .dx-mb-smc-1,
  .dx-my-smc-1 {
    margin-bottom: 8px !important; }
  .dx-ml-smc-1,
  .dx-mx-smc-1 {
    margin-left: 8px !important; }
  .dx-m-smc-2 {
    margin: 16px !important; }
  .dx-mt-smc-2,
  .dx-my-smc-2 {
    margin-top: 16px !important; }
  .dx-mr-smc-2,
  .dx-mx-smc-2 {
    margin-right: 16px !important; }
  .dx-mb-smc-2,
  .dx-my-smc-2 {
    margin-bottom: 16px !important; }
  .dx-ml-smc-2,
  .dx-mx-smc-2 {
    margin-left: 16px !important; }
  .dx-m-smc-3 {
    margin: 24px !important; }
  .dx-mt-smc-3,
  .dx-my-smc-3 {
    margin-top: 24px !important; }
  .dx-mr-smc-3,
  .dx-mx-smc-3 {
    margin-right: 24px !important; }
  .dx-mb-smc-3,
  .dx-my-smc-3 {
    margin-bottom: 24px !important; }
  .dx-ml-smc-3,
  .dx-mx-smc-3 {
    margin-left: 24px !important; }
  .dx-m-smc-4 {
    margin: 32px !important; }
  .dx-mt-smc-4,
  .dx-my-smc-4 {
    margin-top: 32px !important; }
  .dx-mr-smc-4,
  .dx-mx-smc-4 {
    margin-right: 32px !important; }
  .dx-mb-smc-4,
  .dx-my-smc-4 {
    margin-bottom: 32px !important; }
  .dx-ml-smc-4,
  .dx-mx-smc-4 {
    margin-left: 32px !important; }
  .dx-m-smc-5 {
    margin: 40px !important; }
  .dx-mt-smc-5,
  .dx-my-smc-5 {
    margin-top: 40px !important; }
  .dx-mr-smc-5,
  .dx-mx-smc-5 {
    margin-right: 40px !important; }
  .dx-mb-smc-5,
  .dx-my-smc-5 {
    margin-bottom: 40px !important; }
  .dx-ml-smc-5,
  .dx-mx-smc-5 {
    margin-left: 40px !important; }
  .dx-m-smc-6 {
    margin: 48px !important; }
  .dx-mt-smc-6,
  .dx-my-smc-6 {
    margin-top: 48px !important; }
  .dx-mr-smc-6,
  .dx-mx-smc-6 {
    margin-right: 48px !important; }
  .dx-mb-smc-6,
  .dx-my-smc-6 {
    margin-bottom: 48px !important; }
  .dx-ml-smc-6,
  .dx-mx-smc-6 {
    margin-left: 48px !important; }
  .dx-m-smc-7 {
    margin: 56px !important; }
  .dx-mt-smc-7,
  .dx-my-smc-7 {
    margin-top: 56px !important; }
  .dx-mr-smc-7,
  .dx-mx-smc-7 {
    margin-right: 56px !important; }
  .dx-mb-smc-7,
  .dx-my-smc-7 {
    margin-bottom: 56px !important; }
  .dx-ml-smc-7,
  .dx-mx-smc-7 {
    margin-left: 56px !important; }
  .dx-m-smc-8 {
    margin: 64px !important; }
  .dx-mt-smc-8,
  .dx-my-smc-8 {
    margin-top: 64px !important; }
  .dx-mr-smc-8,
  .dx-mx-smc-8 {
    margin-right: 64px !important; }
  .dx-mb-smc-8,
  .dx-my-smc-8 {
    margin-bottom: 64px !important; }
  .dx-ml-smc-8,
  .dx-mx-smc-8 {
    margin-left: 64px !important; }
  .dx-p-smc-0 {
    padding: 0 !important; }
  .dx-pt-smc-0,
  .dx-py-smc-0 {
    padding-top: 0 !important; }
  .dx-pr-smc-0,
  .dx-px-smc-0 {
    padding-right: 0 !important; }
  .dx-pb-smc-0,
  .dx-py-smc-0 {
    padding-bottom: 0 !important; }
  .dx-pl-smc-0,
  .dx-px-smc-0 {
    padding-left: 0 !important; }
  .dx-p-smc-1 {
    padding: 8px !important; }
  .dx-pt-smc-1,
  .dx-py-smc-1 {
    padding-top: 8px !important; }
  .dx-pr-smc-1,
  .dx-px-smc-1 {
    padding-right: 8px !important; }
  .dx-pb-smc-1,
  .dx-py-smc-1 {
    padding-bottom: 8px !important; }
  .dx-pl-smc-1,
  .dx-px-smc-1 {
    padding-left: 8px !important; }
  .dx-p-smc-2 {
    padding: 16px !important; }
  .dx-pt-smc-2,
  .dx-py-smc-2 {
    padding-top: 16px !important; }
  .dx-pr-smc-2,
  .dx-px-smc-2 {
    padding-right: 16px !important; }
  .dx-pb-smc-2,
  .dx-py-smc-2 {
    padding-bottom: 16px !important; }
  .dx-pl-smc-2,
  .dx-px-smc-2 {
    padding-left: 16px !important; }
  .dx-p-smc-3 {
    padding: 24px !important; }
  .dx-pt-smc-3,
  .dx-py-smc-3 {
    padding-top: 24px !important; }
  .dx-pr-smc-3,
  .dx-px-smc-3 {
    padding-right: 24px !important; }
  .dx-pb-smc-3,
  .dx-py-smc-3 {
    padding-bottom: 24px !important; }
  .dx-pl-smc-3,
  .dx-px-smc-3 {
    padding-left: 24px !important; }
  .dx-p-smc-4 {
    padding: 32px !important; }
  .dx-pt-smc-4,
  .dx-py-smc-4 {
    padding-top: 32px !important; }
  .dx-pr-smc-4,
  .dx-px-smc-4 {
    padding-right: 32px !important; }
  .dx-pb-smc-4,
  .dx-py-smc-4 {
    padding-bottom: 32px !important; }
  .dx-pl-smc-4,
  .dx-px-smc-4 {
    padding-left: 32px !important; }
  .dx-p-smc-5 {
    padding: 40px !important; }
  .dx-pt-smc-5,
  .dx-py-smc-5 {
    padding-top: 40px !important; }
  .dx-pr-smc-5,
  .dx-px-smc-5 {
    padding-right: 40px !important; }
  .dx-pb-smc-5,
  .dx-py-smc-5 {
    padding-bottom: 40px !important; }
  .dx-pl-smc-5,
  .dx-px-smc-5 {
    padding-left: 40px !important; }
  .dx-p-smc-6 {
    padding: 48px !important; }
  .dx-pt-smc-6,
  .dx-py-smc-6 {
    padding-top: 48px !important; }
  .dx-pr-smc-6,
  .dx-px-smc-6 {
    padding-right: 48px !important; }
  .dx-pb-smc-6,
  .dx-py-smc-6 {
    padding-bottom: 48px !important; }
  .dx-pl-smc-6,
  .dx-px-smc-6 {
    padding-left: 48px !important; }
  .dx-p-smc-7 {
    padding: 56px !important; }
  .dx-pt-smc-7,
  .dx-py-smc-7 {
    padding-top: 56px !important; }
  .dx-pr-smc-7,
  .dx-px-smc-7 {
    padding-right: 56px !important; }
  .dx-pb-smc-7,
  .dx-py-smc-7 {
    padding-bottom: 56px !important; }
  .dx-pl-smc-7,
  .dx-px-smc-7 {
    padding-left: 56px !important; }
  .dx-p-smc-8 {
    padding: 64px !important; }
  .dx-pt-smc-8,
  .dx-py-smc-8 {
    padding-top: 64px !important; }
  .dx-pr-smc-8,
  .dx-px-smc-8 {
    padding-right: 64px !important; }
  .dx-pb-smc-8,
  .dx-py-smc-8 {
    padding-bottom: 64px !important; }
  .dx-pl-smc-8,
  .dx-px-smc-8 {
    padding-left: 64px !important; } }

@media (min-width: 576px) {
  .dx-m-sm-0 {
    margin: 0 !important; }
  .dx-mt-sm-0,
  .dx-my-sm-0 {
    margin-top: 0 !important; }
  .dx-mr-sm-0,
  .dx-mx-sm-0 {
    margin-right: 0 !important; }
  .dx-mb-sm-0,
  .dx-my-sm-0 {
    margin-bottom: 0 !important; }
  .dx-ml-sm-0,
  .dx-mx-sm-0 {
    margin-left: 0 !important; }
  .dx-m-sm-1 {
    margin: 8px !important; }
  .dx-mt-sm-1,
  .dx-my-sm-1 {
    margin-top: 8px !important; }
  .dx-mr-sm-1,
  .dx-mx-sm-1 {
    margin-right: 8px !important; }
  .dx-mb-sm-1,
  .dx-my-sm-1 {
    margin-bottom: 8px !important; }
  .dx-ml-sm-1,
  .dx-mx-sm-1 {
    margin-left: 8px !important; }
  .dx-m-sm-2 {
    margin: 16px !important; }
  .dx-mt-sm-2,
  .dx-my-sm-2 {
    margin-top: 16px !important; }
  .dx-mr-sm-2,
  .dx-mx-sm-2 {
    margin-right: 16px !important; }
  .dx-mb-sm-2,
  .dx-my-sm-2 {
    margin-bottom: 16px !important; }
  .dx-ml-sm-2,
  .dx-mx-sm-2 {
    margin-left: 16px !important; }
  .dx-m-sm-3 {
    margin: 24px !important; }
  .dx-mt-sm-3,
  .dx-my-sm-3 {
    margin-top: 24px !important; }
  .dx-mr-sm-3,
  .dx-mx-sm-3 {
    margin-right: 24px !important; }
  .dx-mb-sm-3,
  .dx-my-sm-3 {
    margin-bottom: 24px !important; }
  .dx-ml-sm-3,
  .dx-mx-sm-3 {
    margin-left: 24px !important; }
  .dx-m-sm-4 {
    margin: 32px !important; }
  .dx-mt-sm-4,
  .dx-my-sm-4 {
    margin-top: 32px !important; }
  .dx-mr-sm-4,
  .dx-mx-sm-4 {
    margin-right: 32px !important; }
  .dx-mb-sm-4,
  .dx-my-sm-4 {
    margin-bottom: 32px !important; }
  .dx-ml-sm-4,
  .dx-mx-sm-4 {
    margin-left: 32px !important; }
  .dx-m-sm-5 {
    margin: 40px !important; }
  .dx-mt-sm-5,
  .dx-my-sm-5 {
    margin-top: 40px !important; }
  .dx-mr-sm-5,
  .dx-mx-sm-5 {
    margin-right: 40px !important; }
  .dx-mb-sm-5,
  .dx-my-sm-5 {
    margin-bottom: 40px !important; }
  .dx-ml-sm-5,
  .dx-mx-sm-5 {
    margin-left: 40px !important; }
  .dx-m-sm-6 {
    margin: 48px !important; }
  .dx-mt-sm-6,
  .dx-my-sm-6 {
    margin-top: 48px !important; }
  .dx-mr-sm-6,
  .dx-mx-sm-6 {
    margin-right: 48px !important; }
  .dx-mb-sm-6,
  .dx-my-sm-6 {
    margin-bottom: 48px !important; }
  .dx-ml-sm-6,
  .dx-mx-sm-6 {
    margin-left: 48px !important; }
  .dx-m-sm-7 {
    margin: 56px !important; }
  .dx-mt-sm-7,
  .dx-my-sm-7 {
    margin-top: 56px !important; }
  .dx-mr-sm-7,
  .dx-mx-sm-7 {
    margin-right: 56px !important; }
  .dx-mb-sm-7,
  .dx-my-sm-7 {
    margin-bottom: 56px !important; }
  .dx-ml-sm-7,
  .dx-mx-sm-7 {
    margin-left: 56px !important; }
  .dx-m-sm-8 {
    margin: 64px !important; }
  .dx-mt-sm-8,
  .dx-my-sm-8 {
    margin-top: 64px !important; }
  .dx-mr-sm-8,
  .dx-mx-sm-8 {
    margin-right: 64px !important; }
  .dx-mb-sm-8,
  .dx-my-sm-8 {
    margin-bottom: 64px !important; }
  .dx-ml-sm-8,
  .dx-mx-sm-8 {
    margin-left: 64px !important; }
  .dx-p-sm-0 {
    padding: 0 !important; }
  .dx-pt-sm-0,
  .dx-py-sm-0 {
    padding-top: 0 !important; }
  .dx-pr-sm-0,
  .dx-px-sm-0 {
    padding-right: 0 !important; }
  .dx-pb-sm-0,
  .dx-py-sm-0 {
    padding-bottom: 0 !important; }
  .dx-pl-sm-0,
  .dx-px-sm-0 {
    padding-left: 0 !important; }
  .dx-p-sm-1 {
    padding: 8px !important; }
  .dx-pt-sm-1,
  .dx-py-sm-1 {
    padding-top: 8px !important; }
  .dx-pr-sm-1,
  .dx-px-sm-1 {
    padding-right: 8px !important; }
  .dx-pb-sm-1,
  .dx-py-sm-1 {
    padding-bottom: 8px !important; }
  .dx-pl-sm-1,
  .dx-px-sm-1 {
    padding-left: 8px !important; }
  .dx-p-sm-2 {
    padding: 16px !important; }
  .dx-pt-sm-2,
  .dx-py-sm-2 {
    padding-top: 16px !important; }
  .dx-pr-sm-2,
  .dx-px-sm-2 {
    padding-right: 16px !important; }
  .dx-pb-sm-2,
  .dx-py-sm-2 {
    padding-bottom: 16px !important; }
  .dx-pl-sm-2,
  .dx-px-sm-2 {
    padding-left: 16px !important; }
  .dx-p-sm-3 {
    padding: 24px !important; }
  .dx-pt-sm-3,
  .dx-py-sm-3 {
    padding-top: 24px !important; }
  .dx-pr-sm-3,
  .dx-px-sm-3 {
    padding-right: 24px !important; }
  .dx-pb-sm-3,
  .dx-py-sm-3 {
    padding-bottom: 24px !important; }
  .dx-pl-sm-3,
  .dx-px-sm-3 {
    padding-left: 24px !important; }
  .dx-p-sm-4 {
    padding: 32px !important; }
  .dx-pt-sm-4,
  .dx-py-sm-4 {
    padding-top: 32px !important; }
  .dx-pr-sm-4,
  .dx-px-sm-4 {
    padding-right: 32px !important; }
  .dx-pb-sm-4,
  .dx-py-sm-4 {
    padding-bottom: 32px !important; }
  .dx-pl-sm-4,
  .dx-px-sm-4 {
    padding-left: 32px !important; }
  .dx-p-sm-5 {
    padding: 40px !important; }
  .dx-pt-sm-5,
  .dx-py-sm-5 {
    padding-top: 40px !important; }
  .dx-pr-sm-5,
  .dx-px-sm-5 {
    padding-right: 40px !important; }
  .dx-pb-sm-5,
  .dx-py-sm-5 {
    padding-bottom: 40px !important; }
  .dx-pl-sm-5,
  .dx-px-sm-5 {
    padding-left: 40px !important; }
  .dx-p-sm-6 {
    padding: 48px !important; }
  .dx-pt-sm-6,
  .dx-py-sm-6 {
    padding-top: 48px !important; }
  .dx-pr-sm-6,
  .dx-px-sm-6 {
    padding-right: 48px !important; }
  .dx-pb-sm-6,
  .dx-py-sm-6 {
    padding-bottom: 48px !important; }
  .dx-pl-sm-6,
  .dx-px-sm-6 {
    padding-left: 48px !important; }
  .dx-p-sm-7 {
    padding: 56px !important; }
  .dx-pt-sm-7,
  .dx-py-sm-7 {
    padding-top: 56px !important; }
  .dx-pr-sm-7,
  .dx-px-sm-7 {
    padding-right: 56px !important; }
  .dx-pb-sm-7,
  .dx-py-sm-7 {
    padding-bottom: 56px !important; }
  .dx-pl-sm-7,
  .dx-px-sm-7 {
    padding-left: 56px !important; }
  .dx-p-sm-8 {
    padding: 64px !important; }
  .dx-pt-sm-8,
  .dx-py-sm-8 {
    padding-top: 64px !important; }
  .dx-pr-sm-8,
  .dx-px-sm-8 {
    padding-right: 64px !important; }
  .dx-pb-sm-8,
  .dx-py-sm-8 {
    padding-bottom: 64px !important; }
  .dx-pl-sm-8,
  .dx-px-sm-8 {
    padding-left: 64px !important; } }

@media (min-width: 768px) {
  .dx-m-md-0 {
    margin: 0 !important; }
  .dx-mt-md-0,
  .dx-my-md-0 {
    margin-top: 0 !important; }
  .dx-mr-md-0,
  .dx-mx-md-0 {
    margin-right: 0 !important; }
  .dx-mb-md-0,
  .dx-my-md-0 {
    margin-bottom: 0 !important; }
  .dx-ml-md-0,
  .dx-mx-md-0 {
    margin-left: 0 !important; }
  .dx-m-md-1 {
    margin: 8px !important; }
  .dx-mt-md-1,
  .dx-my-md-1 {
    margin-top: 8px !important; }
  .dx-mr-md-1,
  .dx-mx-md-1 {
    margin-right: 8px !important; }
  .dx-mb-md-1,
  .dx-my-md-1 {
    margin-bottom: 8px !important; }
  .dx-ml-md-1,
  .dx-mx-md-1 {
    margin-left: 8px !important; }
  .dx-m-md-2 {
    margin: 16px !important; }
  .dx-mt-md-2,
  .dx-my-md-2 {
    margin-top: 16px !important; }
  .dx-mr-md-2,
  .dx-mx-md-2 {
    margin-right: 16px !important; }
  .dx-mb-md-2,
  .dx-my-md-2 {
    margin-bottom: 16px !important; }
  .dx-ml-md-2,
  .dx-mx-md-2 {
    margin-left: 16px !important; }
  .dx-m-md-3 {
    margin: 24px !important; }
  .dx-mt-md-3,
  .dx-my-md-3 {
    margin-top: 24px !important; }
  .dx-mr-md-3,
  .dx-mx-md-3 {
    margin-right: 24px !important; }
  .dx-mb-md-3,
  .dx-my-md-3 {
    margin-bottom: 24px !important; }
  .dx-ml-md-3,
  .dx-mx-md-3 {
    margin-left: 24px !important; }
  .dx-m-md-4 {
    margin: 32px !important; }
  .dx-mt-md-4,
  .dx-my-md-4 {
    margin-top: 32px !important; }
  .dx-mr-md-4,
  .dx-mx-md-4 {
    margin-right: 32px !important; }
  .dx-mb-md-4,
  .dx-my-md-4 {
    margin-bottom: 32px !important; }
  .dx-ml-md-4,
  .dx-mx-md-4 {
    margin-left: 32px !important; }
  .dx-m-md-5 {
    margin: 40px !important; }
  .dx-mt-md-5,
  .dx-my-md-5 {
    margin-top: 40px !important; }
  .dx-mr-md-5,
  .dx-mx-md-5 {
    margin-right: 40px !important; }
  .dx-mb-md-5,
  .dx-my-md-5 {
    margin-bottom: 40px !important; }
  .dx-ml-md-5,
  .dx-mx-md-5 {
    margin-left: 40px !important; }
  .dx-m-md-6 {
    margin: 48px !important; }
  .dx-mt-md-6,
  .dx-my-md-6 {
    margin-top: 48px !important; }
  .dx-mr-md-6,
  .dx-mx-md-6 {
    margin-right: 48px !important; }
  .dx-mb-md-6,
  .dx-my-md-6 {
    margin-bottom: 48px !important; }
  .dx-ml-md-6,
  .dx-mx-md-6 {
    margin-left: 48px !important; }
  .dx-m-md-7 {
    margin: 56px !important; }
  .dx-mt-md-7,
  .dx-my-md-7 {
    margin-top: 56px !important; }
  .dx-mr-md-7,
  .dx-mx-md-7 {
    margin-right: 56px !important; }
  .dx-mb-md-7,
  .dx-my-md-7 {
    margin-bottom: 56px !important; }
  .dx-ml-md-7,
  .dx-mx-md-7 {
    margin-left: 56px !important; }
  .dx-m-md-8 {
    margin: 64px !important; }
  .dx-mt-md-8,
  .dx-my-md-8 {
    margin-top: 64px !important; }
  .dx-mr-md-8,
  .dx-mx-md-8 {
    margin-right: 64px !important; }
  .dx-mb-md-8,
  .dx-my-md-8 {
    margin-bottom: 64px !important; }
  .dx-ml-md-8,
  .dx-mx-md-8 {
    margin-left: 64px !important; }
  .dx-p-md-0 {
    padding: 0 !important; }
  .dx-pt-md-0,
  .dx-py-md-0 {
    padding-top: 0 !important; }
  .dx-pr-md-0,
  .dx-px-md-0 {
    padding-right: 0 !important; }
  .dx-pb-md-0,
  .dx-py-md-0 {
    padding-bottom: 0 !important; }
  .dx-pl-md-0,
  .dx-px-md-0 {
    padding-left: 0 !important; }
  .dx-p-md-1 {
    padding: 8px !important; }
  .dx-pt-md-1,
  .dx-py-md-1 {
    padding-top: 8px !important; }
  .dx-pr-md-1,
  .dx-px-md-1 {
    padding-right: 8px !important; }
  .dx-pb-md-1,
  .dx-py-md-1 {
    padding-bottom: 8px !important; }
  .dx-pl-md-1,
  .dx-px-md-1 {
    padding-left: 8px !important; }
  .dx-p-md-2 {
    padding: 16px !important; }
  .dx-pt-md-2,
  .dx-py-md-2 {
    padding-top: 16px !important; }
  .dx-pr-md-2,
  .dx-px-md-2 {
    padding-right: 16px !important; }
  .dx-pb-md-2,
  .dx-py-md-2 {
    padding-bottom: 16px !important; }
  .dx-pl-md-2,
  .dx-px-md-2 {
    padding-left: 16px !important; }
  .dx-p-md-3 {
    padding: 24px !important; }
  .dx-pt-md-3,
  .dx-py-md-3 {
    padding-top: 24px !important; }
  .dx-pr-md-3,
  .dx-px-md-3 {
    padding-right: 24px !important; }
  .dx-pb-md-3,
  .dx-py-md-3 {
    padding-bottom: 24px !important; }
  .dx-pl-md-3,
  .dx-px-md-3 {
    padding-left: 24px !important; }
  .dx-p-md-4 {
    padding: 32px !important; }
  .dx-pt-md-4,
  .dx-py-md-4 {
    padding-top: 32px !important; }
  .dx-pr-md-4,
  .dx-px-md-4 {
    padding-right: 32px !important; }
  .dx-pb-md-4,
  .dx-py-md-4 {
    padding-bottom: 32px !important; }
  .dx-pl-md-4,
  .dx-px-md-4 {
    padding-left: 32px !important; }
  .dx-p-md-5 {
    padding: 40px !important; }
  .dx-pt-md-5,
  .dx-py-md-5 {
    padding-top: 40px !important; }
  .dx-pr-md-5,
  .dx-px-md-5 {
    padding-right: 40px !important; }
  .dx-pb-md-5,
  .dx-py-md-5 {
    padding-bottom: 40px !important; }
  .dx-pl-md-5,
  .dx-px-md-5 {
    padding-left: 40px !important; }
  .dx-p-md-6 {
    padding: 48px !important; }
  .dx-pt-md-6,
  .dx-py-md-6 {
    padding-top: 48px !important; }
  .dx-pr-md-6,
  .dx-px-md-6 {
    padding-right: 48px !important; }
  .dx-pb-md-6,
  .dx-py-md-6 {
    padding-bottom: 48px !important; }
  .dx-pl-md-6,
  .dx-px-md-6 {
    padding-left: 48px !important; }
  .dx-p-md-7 {
    padding: 56px !important; }
  .dx-pt-md-7,
  .dx-py-md-7 {
    padding-top: 56px !important; }
  .dx-pr-md-7,
  .dx-px-md-7 {
    padding-right: 56px !important; }
  .dx-pb-md-7,
  .dx-py-md-7 {
    padding-bottom: 56px !important; }
  .dx-pl-md-7,
  .dx-px-md-7 {
    padding-left: 56px !important; }
  .dx-p-md-8 {
    padding: 64px !important; }
  .dx-pt-md-8,
  .dx-py-md-8 {
    padding-top: 64px !important; }
  .dx-pr-md-8,
  .dx-px-md-8 {
    padding-right: 64px !important; }
  .dx-pb-md-8,
  .dx-py-md-8 {
    padding-bottom: 64px !important; }
  .dx-pl-md-8,
  .dx-px-md-8 {
    padding-left: 64px !important; } }

@media (min-width: 992px) {
  .dx-m-lg-0 {
    margin: 0 !important; }
  .dx-mt-lg-0,
  .dx-my-lg-0 {
    margin-top: 0 !important; }
  .dx-mr-lg-0,
  .dx-mx-lg-0 {
    margin-right: 0 !important; }
  .dx-mb-lg-0,
  .dx-my-lg-0 {
    margin-bottom: 0 !important; }
  .dx-ml-lg-0,
  .dx-mx-lg-0 {
    margin-left: 0 !important; }
  .dx-m-lg-1 {
    margin: 8px !important; }
  .dx-mt-lg-1,
  .dx-my-lg-1 {
    margin-top: 8px !important; }
  .dx-mr-lg-1,
  .dx-mx-lg-1 {
    margin-right: 8px !important; }
  .dx-mb-lg-1,
  .dx-my-lg-1 {
    margin-bottom: 8px !important; }
  .dx-ml-lg-1,
  .dx-mx-lg-1 {
    margin-left: 8px !important; }
  .dx-m-lg-2 {
    margin: 16px !important; }
  .dx-mt-lg-2,
  .dx-my-lg-2 {
    margin-top: 16px !important; }
  .dx-mr-lg-2,
  .dx-mx-lg-2 {
    margin-right: 16px !important; }
  .dx-mb-lg-2,
  .dx-my-lg-2 {
    margin-bottom: 16px !important; }
  .dx-ml-lg-2,
  .dx-mx-lg-2 {
    margin-left: 16px !important; }
  .dx-m-lg-3 {
    margin: 24px !important; }
  .dx-mt-lg-3,
  .dx-my-lg-3 {
    margin-top: 24px !important; }
  .dx-mr-lg-3,
  .dx-mx-lg-3 {
    margin-right: 24px !important; }
  .dx-mb-lg-3,
  .dx-my-lg-3 {
    margin-bottom: 24px !important; }
  .dx-ml-lg-3,
  .dx-mx-lg-3 {
    margin-left: 24px !important; }
  .dx-m-lg-4 {
    margin: 32px !important; }
  .dx-mt-lg-4,
  .dx-my-lg-4 {
    margin-top: 32px !important; }
  .dx-mr-lg-4,
  .dx-mx-lg-4 {
    margin-right: 32px !important; }
  .dx-mb-lg-4,
  .dx-my-lg-4 {
    margin-bottom: 32px !important; }
  .dx-ml-lg-4,
  .dx-mx-lg-4 {
    margin-left: 32px !important; }
  .dx-m-lg-5 {
    margin: 40px !important; }
  .dx-mt-lg-5,
  .dx-my-lg-5 {
    margin-top: 40px !important; }
  .dx-mr-lg-5,
  .dx-mx-lg-5 {
    margin-right: 40px !important; }
  .dx-mb-lg-5,
  .dx-my-lg-5 {
    margin-bottom: 40px !important; }
  .dx-ml-lg-5,
  .dx-mx-lg-5 {
    margin-left: 40px !important; }
  .dx-m-lg-6 {
    margin: 48px !important; }
  .dx-mt-lg-6,
  .dx-my-lg-6 {
    margin-top: 48px !important; }
  .dx-mr-lg-6,
  .dx-mx-lg-6 {
    margin-right: 48px !important; }
  .dx-mb-lg-6,
  .dx-my-lg-6 {
    margin-bottom: 48px !important; }
  .dx-ml-lg-6,
  .dx-mx-lg-6 {
    margin-left: 48px !important; }
  .dx-m-lg-7 {
    margin: 56px !important; }
  .dx-mt-lg-7,
  .dx-my-lg-7 {
    margin-top: 56px !important; }
  .dx-mr-lg-7,
  .dx-mx-lg-7 {
    margin-right: 56px !important; }
  .dx-mb-lg-7,
  .dx-my-lg-7 {
    margin-bottom: 56px !important; }
  .dx-ml-lg-7,
  .dx-mx-lg-7 {
    margin-left: 56px !important; }
  .dx-m-lg-8 {
    margin: 64px !important; }
  .dx-mt-lg-8,
  .dx-my-lg-8 {
    margin-top: 64px !important; }
  .dx-mr-lg-8,
  .dx-mx-lg-8 {
    margin-right: 64px !important; }
  .dx-mb-lg-8,
  .dx-my-lg-8 {
    margin-bottom: 64px !important; }
  .dx-ml-lg-8,
  .dx-mx-lg-8 {
    margin-left: 64px !important; }
  .dx-p-lg-0 {
    padding: 0 !important; }
  .dx-pt-lg-0,
  .dx-py-lg-0 {
    padding-top: 0 !important; }
  .dx-pr-lg-0,
  .dx-px-lg-0 {
    padding-right: 0 !important; }
  .dx-pb-lg-0,
  .dx-py-lg-0 {
    padding-bottom: 0 !important; }
  .dx-pl-lg-0,
  .dx-px-lg-0 {
    padding-left: 0 !important; }
  .dx-p-lg-1 {
    padding: 8px !important; }
  .dx-pt-lg-1,
  .dx-py-lg-1 {
    padding-top: 8px !important; }
  .dx-pr-lg-1,
  .dx-px-lg-1 {
    padding-right: 8px !important; }
  .dx-pb-lg-1,
  .dx-py-lg-1 {
    padding-bottom: 8px !important; }
  .dx-pl-lg-1,
  .dx-px-lg-1 {
    padding-left: 8px !important; }
  .dx-p-lg-2 {
    padding: 16px !important; }
  .dx-pt-lg-2,
  .dx-py-lg-2 {
    padding-top: 16px !important; }
  .dx-pr-lg-2,
  .dx-px-lg-2 {
    padding-right: 16px !important; }
  .dx-pb-lg-2,
  .dx-py-lg-2 {
    padding-bottom: 16px !important; }
  .dx-pl-lg-2,
  .dx-px-lg-2 {
    padding-left: 16px !important; }
  .dx-p-lg-3 {
    padding: 24px !important; }
  .dx-pt-lg-3,
  .dx-py-lg-3 {
    padding-top: 24px !important; }
  .dx-pr-lg-3,
  .dx-px-lg-3 {
    padding-right: 24px !important; }
  .dx-pb-lg-3,
  .dx-py-lg-3 {
    padding-bottom: 24px !important; }
  .dx-pl-lg-3,
  .dx-px-lg-3 {
    padding-left: 24px !important; }
  .dx-p-lg-4 {
    padding: 32px !important; }
  .dx-pt-lg-4,
  .dx-py-lg-4 {
    padding-top: 32px !important; }
  .dx-pr-lg-4,
  .dx-px-lg-4 {
    padding-right: 32px !important; }
  .dx-pb-lg-4,
  .dx-py-lg-4 {
    padding-bottom: 32px !important; }
  .dx-pl-lg-4,
  .dx-px-lg-4 {
    padding-left: 32px !important; }
  .dx-p-lg-5 {
    padding: 40px !important; }
  .dx-pt-lg-5,
  .dx-py-lg-5 {
    padding-top: 40px !important; }
  .dx-pr-lg-5,
  .dx-px-lg-5 {
    padding-right: 40px !important; }
  .dx-pb-lg-5,
  .dx-py-lg-5 {
    padding-bottom: 40px !important; }
  .dx-pl-lg-5,
  .dx-px-lg-5 {
    padding-left: 40px !important; }
  .dx-p-lg-6 {
    padding: 48px !important; }
  .dx-pt-lg-6,
  .dx-py-lg-6 {
    padding-top: 48px !important; }
  .dx-pr-lg-6,
  .dx-px-lg-6 {
    padding-right: 48px !important; }
  .dx-pb-lg-6,
  .dx-py-lg-6 {
    padding-bottom: 48px !important; }
  .dx-pl-lg-6,
  .dx-px-lg-6 {
    padding-left: 48px !important; }
  .dx-p-lg-7 {
    padding: 56px !important; }
  .dx-pt-lg-7,
  .dx-py-lg-7 {
    padding-top: 56px !important; }
  .dx-pr-lg-7,
  .dx-px-lg-7 {
    padding-right: 56px !important; }
  .dx-pb-lg-7,
  .dx-py-lg-7 {
    padding-bottom: 56px !important; }
  .dx-pl-lg-7,
  .dx-px-lg-7 {
    padding-left: 56px !important; }
  .dx-p-lg-8 {
    padding: 64px !important; }
  .dx-pt-lg-8,
  .dx-py-lg-8 {
    padding-top: 64px !important; }
  .dx-pr-lg-8,
  .dx-px-lg-8 {
    padding-right: 64px !important; }
  .dx-pb-lg-8,
  .dx-py-lg-8 {
    padding-bottom: 64px !important; }
  .dx-pl-lg-8,
  .dx-px-lg-8 {
    padding-left: 64px !important; } }

@media (min-width: 1200px) {
  .dx-m-xl-0 {
    margin: 0 !important; }
  .dx-mt-xl-0,
  .dx-my-xl-0 {
    margin-top: 0 !important; }
  .dx-mr-xl-0,
  .dx-mx-xl-0 {
    margin-right: 0 !important; }
  .dx-mb-xl-0,
  .dx-my-xl-0 {
    margin-bottom: 0 !important; }
  .dx-ml-xl-0,
  .dx-mx-xl-0 {
    margin-left: 0 !important; }
  .dx-m-xl-1 {
    margin: 8px !important; }
  .dx-mt-xl-1,
  .dx-my-xl-1 {
    margin-top: 8px !important; }
  .dx-mr-xl-1,
  .dx-mx-xl-1 {
    margin-right: 8px !important; }
  .dx-mb-xl-1,
  .dx-my-xl-1 {
    margin-bottom: 8px !important; }
  .dx-ml-xl-1,
  .dx-mx-xl-1 {
    margin-left: 8px !important; }
  .dx-m-xl-2 {
    margin: 16px !important; }
  .dx-mt-xl-2,
  .dx-my-xl-2 {
    margin-top: 16px !important; }
  .dx-mr-xl-2,
  .dx-mx-xl-2 {
    margin-right: 16px !important; }
  .dx-mb-xl-2,
  .dx-my-xl-2 {
    margin-bottom: 16px !important; }
  .dx-ml-xl-2,
  .dx-mx-xl-2 {
    margin-left: 16px !important; }
  .dx-m-xl-3 {
    margin: 24px !important; }
  .dx-mt-xl-3,
  .dx-my-xl-3 {
    margin-top: 24px !important; }
  .dx-mr-xl-3,
  .dx-mx-xl-3 {
    margin-right: 24px !important; }
  .dx-mb-xl-3,
  .dx-my-xl-3 {
    margin-bottom: 24px !important; }
  .dx-ml-xl-3,
  .dx-mx-xl-3 {
    margin-left: 24px !important; }
  .dx-m-xl-4 {
    margin: 32px !important; }
  .dx-mt-xl-4,
  .dx-my-xl-4 {
    margin-top: 32px !important; }
  .dx-mr-xl-4,
  .dx-mx-xl-4 {
    margin-right: 32px !important; }
  .dx-mb-xl-4,
  .dx-my-xl-4 {
    margin-bottom: 32px !important; }
  .dx-ml-xl-4,
  .dx-mx-xl-4 {
    margin-left: 32px !important; }
  .dx-m-xl-5 {
    margin: 40px !important; }
  .dx-mt-xl-5,
  .dx-my-xl-5 {
    margin-top: 40px !important; }
  .dx-mr-xl-5,
  .dx-mx-xl-5 {
    margin-right: 40px !important; }
  .dx-mb-xl-5,
  .dx-my-xl-5 {
    margin-bottom: 40px !important; }
  .dx-ml-xl-5,
  .dx-mx-xl-5 {
    margin-left: 40px !important; }
  .dx-m-xl-6 {
    margin: 48px !important; }
  .dx-mt-xl-6,
  .dx-my-xl-6 {
    margin-top: 48px !important; }
  .dx-mr-xl-6,
  .dx-mx-xl-6 {
    margin-right: 48px !important; }
  .dx-mb-xl-6,
  .dx-my-xl-6 {
    margin-bottom: 48px !important; }
  .dx-ml-xl-6,
  .dx-mx-xl-6 {
    margin-left: 48px !important; }
  .dx-m-xl-7 {
    margin: 56px !important; }
  .dx-mt-xl-7,
  .dx-my-xl-7 {
    margin-top: 56px !important; }
  .dx-mr-xl-7,
  .dx-mx-xl-7 {
    margin-right: 56px !important; }
  .dx-mb-xl-7,
  .dx-my-xl-7 {
    margin-bottom: 56px !important; }
  .dx-ml-xl-7,
  .dx-mx-xl-7 {
    margin-left: 56px !important; }
  .dx-m-xl-8 {
    margin: 64px !important; }
  .dx-mt-xl-8,
  .dx-my-xl-8 {
    margin-top: 64px !important; }
  .dx-mr-xl-8,
  .dx-mx-xl-8 {
    margin-right: 64px !important; }
  .dx-mb-xl-8,
  .dx-my-xl-8 {
    margin-bottom: 64px !important; }
  .dx-ml-xl-8,
  .dx-mx-xl-8 {
    margin-left: 64px !important; }
  .dx-p-xl-0 {
    padding: 0 !important; }
  .dx-pt-xl-0,
  .dx-py-xl-0 {
    padding-top: 0 !important; }
  .dx-pr-xl-0,
  .dx-px-xl-0 {
    padding-right: 0 !important; }
  .dx-pb-xl-0,
  .dx-py-xl-0 {
    padding-bottom: 0 !important; }
  .dx-pl-xl-0,
  .dx-px-xl-0 {
    padding-left: 0 !important; }
  .dx-p-xl-1 {
    padding: 8px !important; }
  .dx-pt-xl-1,
  .dx-py-xl-1 {
    padding-top: 8px !important; }
  .dx-pr-xl-1,
  .dx-px-xl-1 {
    padding-right: 8px !important; }
  .dx-pb-xl-1,
  .dx-py-xl-1 {
    padding-bottom: 8px !important; }
  .dx-pl-xl-1,
  .dx-px-xl-1 {
    padding-left: 8px !important; }
  .dx-p-xl-2 {
    padding: 16px !important; }
  .dx-pt-xl-2,
  .dx-py-xl-2 {
    padding-top: 16px !important; }
  .dx-pr-xl-2,
  .dx-px-xl-2 {
    padding-right: 16px !important; }
  .dx-pb-xl-2,
  .dx-py-xl-2 {
    padding-bottom: 16px !important; }
  .dx-pl-xl-2,
  .dx-px-xl-2 {
    padding-left: 16px !important; }
  .dx-p-xl-3 {
    padding: 24px !important; }
  .dx-pt-xl-3,
  .dx-py-xl-3 {
    padding-top: 24px !important; }
  .dx-pr-xl-3,
  .dx-px-xl-3 {
    padding-right: 24px !important; }
  .dx-pb-xl-3,
  .dx-py-xl-3 {
    padding-bottom: 24px !important; }
  .dx-pl-xl-3,
  .dx-px-xl-3 {
    padding-left: 24px !important; }
  .dx-p-xl-4 {
    padding: 32px !important; }
  .dx-pt-xl-4,
  .dx-py-xl-4 {
    padding-top: 32px !important; }
  .dx-pr-xl-4,
  .dx-px-xl-4 {
    padding-right: 32px !important; }
  .dx-pb-xl-4,
  .dx-py-xl-4 {
    padding-bottom: 32px !important; }
  .dx-pl-xl-4,
  .dx-px-xl-4 {
    padding-left: 32px !important; }
  .dx-p-xl-5 {
    padding: 40px !important; }
  .dx-pt-xl-5,
  .dx-py-xl-5 {
    padding-top: 40px !important; }
  .dx-pr-xl-5,
  .dx-px-xl-5 {
    padding-right: 40px !important; }
  .dx-pb-xl-5,
  .dx-py-xl-5 {
    padding-bottom: 40px !important; }
  .dx-pl-xl-5,
  .dx-px-xl-5 {
    padding-left: 40px !important; }
  .dx-p-xl-6 {
    padding: 48px !important; }
  .dx-pt-xl-6,
  .dx-py-xl-6 {
    padding-top: 48px !important; }
  .dx-pr-xl-6,
  .dx-px-xl-6 {
    padding-right: 48px !important; }
  .dx-pb-xl-6,
  .dx-py-xl-6 {
    padding-bottom: 48px !important; }
  .dx-pl-xl-6,
  .dx-px-xl-6 {
    padding-left: 48px !important; }
  .dx-p-xl-7 {
    padding: 56px !important; }
  .dx-pt-xl-7,
  .dx-py-xl-7 {
    padding-top: 56px !important; }
  .dx-pr-xl-7,
  .dx-px-xl-7 {
    padding-right: 56px !important; }
  .dx-pb-xl-7,
  .dx-py-xl-7 {
    padding-bottom: 56px !important; }
  .dx-pl-xl-7,
  .dx-px-xl-7 {
    padding-left: 56px !important; }
  .dx-p-xl-8 {
    padding: 64px !important; }
  .dx-pt-xl-8,
  .dx-py-xl-8 {
    padding-top: 64px !important; }
  .dx-pr-xl-8,
  .dx-px-xl-8 {
    padding-right: 64px !important; }
  .dx-pb-xl-8,
  .dx-py-xl-8 {
    padding-bottom: 64px !important; }
  .dx-pl-xl-8,
  .dx-px-xl-8 {
    padding-left: 64px !important; } }

@media (min-width: 1440px) {
  .dx-m-xxl-0 {
    margin: 0 !important; }
  .dx-mt-xxl-0,
  .dx-my-xxl-0 {
    margin-top: 0 !important; }
  .dx-mr-xxl-0,
  .dx-mx-xxl-0 {
    margin-right: 0 !important; }
  .dx-mb-xxl-0,
  .dx-my-xxl-0 {
    margin-bottom: 0 !important; }
  .dx-ml-xxl-0,
  .dx-mx-xxl-0 {
    margin-left: 0 !important; }
  .dx-m-xxl-1 {
    margin: 8px !important; }
  .dx-mt-xxl-1,
  .dx-my-xxl-1 {
    margin-top: 8px !important; }
  .dx-mr-xxl-1,
  .dx-mx-xxl-1 {
    margin-right: 8px !important; }
  .dx-mb-xxl-1,
  .dx-my-xxl-1 {
    margin-bottom: 8px !important; }
  .dx-ml-xxl-1,
  .dx-mx-xxl-1 {
    margin-left: 8px !important; }
  .dx-m-xxl-2 {
    margin: 16px !important; }
  .dx-mt-xxl-2,
  .dx-my-xxl-2 {
    margin-top: 16px !important; }
  .dx-mr-xxl-2,
  .dx-mx-xxl-2 {
    margin-right: 16px !important; }
  .dx-mb-xxl-2,
  .dx-my-xxl-2 {
    margin-bottom: 16px !important; }
  .dx-ml-xxl-2,
  .dx-mx-xxl-2 {
    margin-left: 16px !important; }
  .dx-m-xxl-3 {
    margin: 24px !important; }
  .dx-mt-xxl-3,
  .dx-my-xxl-3 {
    margin-top: 24px !important; }
  .dx-mr-xxl-3,
  .dx-mx-xxl-3 {
    margin-right: 24px !important; }
  .dx-mb-xxl-3,
  .dx-my-xxl-3 {
    margin-bottom: 24px !important; }
  .dx-ml-xxl-3,
  .dx-mx-xxl-3 {
    margin-left: 24px !important; }
  .dx-m-xxl-4 {
    margin: 32px !important; }
  .dx-mt-xxl-4,
  .dx-my-xxl-4 {
    margin-top: 32px !important; }
  .dx-mr-xxl-4,
  .dx-mx-xxl-4 {
    margin-right: 32px !important; }
  .dx-mb-xxl-4,
  .dx-my-xxl-4 {
    margin-bottom: 32px !important; }
  .dx-ml-xxl-4,
  .dx-mx-xxl-4 {
    margin-left: 32px !important; }
  .dx-m-xxl-5 {
    margin: 40px !important; }
  .dx-mt-xxl-5,
  .dx-my-xxl-5 {
    margin-top: 40px !important; }
  .dx-mr-xxl-5,
  .dx-mx-xxl-5 {
    margin-right: 40px !important; }
  .dx-mb-xxl-5,
  .dx-my-xxl-5 {
    margin-bottom: 40px !important; }
  .dx-ml-xxl-5,
  .dx-mx-xxl-5 {
    margin-left: 40px !important; }
  .dx-m-xxl-6 {
    margin: 48px !important; }
  .dx-mt-xxl-6,
  .dx-my-xxl-6 {
    margin-top: 48px !important; }
  .dx-mr-xxl-6,
  .dx-mx-xxl-6 {
    margin-right: 48px !important; }
  .dx-mb-xxl-6,
  .dx-my-xxl-6 {
    margin-bottom: 48px !important; }
  .dx-ml-xxl-6,
  .dx-mx-xxl-6 {
    margin-left: 48px !important; }
  .dx-m-xxl-7 {
    margin: 56px !important; }
  .dx-mt-xxl-7,
  .dx-my-xxl-7 {
    margin-top: 56px !important; }
  .dx-mr-xxl-7,
  .dx-mx-xxl-7 {
    margin-right: 56px !important; }
  .dx-mb-xxl-7,
  .dx-my-xxl-7 {
    margin-bottom: 56px !important; }
  .dx-ml-xxl-7,
  .dx-mx-xxl-7 {
    margin-left: 56px !important; }
  .dx-m-xxl-8 {
    margin: 64px !important; }
  .dx-mt-xxl-8,
  .dx-my-xxl-8 {
    margin-top: 64px !important; }
  .dx-mr-xxl-8,
  .dx-mx-xxl-8 {
    margin-right: 64px !important; }
  .dx-mb-xxl-8,
  .dx-my-xxl-8 {
    margin-bottom: 64px !important; }
  .dx-ml-xxl-8,
  .dx-mx-xxl-8 {
    margin-left: 64px !important; }
  .dx-p-xxl-0 {
    padding: 0 !important; }
  .dx-pt-xxl-0,
  .dx-py-xxl-0 {
    padding-top: 0 !important; }
  .dx-pr-xxl-0,
  .dx-px-xxl-0 {
    padding-right: 0 !important; }
  .dx-pb-xxl-0,
  .dx-py-xxl-0 {
    padding-bottom: 0 !important; }
  .dx-pl-xxl-0,
  .dx-px-xxl-0 {
    padding-left: 0 !important; }
  .dx-p-xxl-1 {
    padding: 8px !important; }
  .dx-pt-xxl-1,
  .dx-py-xxl-1 {
    padding-top: 8px !important; }
  .dx-pr-xxl-1,
  .dx-px-xxl-1 {
    padding-right: 8px !important; }
  .dx-pb-xxl-1,
  .dx-py-xxl-1 {
    padding-bottom: 8px !important; }
  .dx-pl-xxl-1,
  .dx-px-xxl-1 {
    padding-left: 8px !important; }
  .dx-p-xxl-2 {
    padding: 16px !important; }
  .dx-pt-xxl-2,
  .dx-py-xxl-2 {
    padding-top: 16px !important; }
  .dx-pr-xxl-2,
  .dx-px-xxl-2 {
    padding-right: 16px !important; }
  .dx-pb-xxl-2,
  .dx-py-xxl-2 {
    padding-bottom: 16px !important; }
  .dx-pl-xxl-2,
  .dx-px-xxl-2 {
    padding-left: 16px !important; }
  .dx-p-xxl-3 {
    padding: 24px !important; }
  .dx-pt-xxl-3,
  .dx-py-xxl-3 {
    padding-top: 24px !important; }
  .dx-pr-xxl-3,
  .dx-px-xxl-3 {
    padding-right: 24px !important; }
  .dx-pb-xxl-3,
  .dx-py-xxl-3 {
    padding-bottom: 24px !important; }
  .dx-pl-xxl-3,
  .dx-px-xxl-3 {
    padding-left: 24px !important; }
  .dx-p-xxl-4 {
    padding: 32px !important; }
  .dx-pt-xxl-4,
  .dx-py-xxl-4 {
    padding-top: 32px !important; }
  .dx-pr-xxl-4,
  .dx-px-xxl-4 {
    padding-right: 32px !important; }
  .dx-pb-xxl-4,
  .dx-py-xxl-4 {
    padding-bottom: 32px !important; }
  .dx-pl-xxl-4,
  .dx-px-xxl-4 {
    padding-left: 32px !important; }
  .dx-p-xxl-5 {
    padding: 40px !important; }
  .dx-pt-xxl-5,
  .dx-py-xxl-5 {
    padding-top: 40px !important; }
  .dx-pr-xxl-5,
  .dx-px-xxl-5 {
    padding-right: 40px !important; }
  .dx-pb-xxl-5,
  .dx-py-xxl-5 {
    padding-bottom: 40px !important; }
  .dx-pl-xxl-5,
  .dx-px-xxl-5 {
    padding-left: 40px !important; }
  .dx-p-xxl-6 {
    padding: 48px !important; }
  .dx-pt-xxl-6,
  .dx-py-xxl-6 {
    padding-top: 48px !important; }
  .dx-pr-xxl-6,
  .dx-px-xxl-6 {
    padding-right: 48px !important; }
  .dx-pb-xxl-6,
  .dx-py-xxl-6 {
    padding-bottom: 48px !important; }
  .dx-pl-xxl-6,
  .dx-px-xxl-6 {
    padding-left: 48px !important; }
  .dx-p-xxl-7 {
    padding: 56px !important; }
  .dx-pt-xxl-7,
  .dx-py-xxl-7 {
    padding-top: 56px !important; }
  .dx-pr-xxl-7,
  .dx-px-xxl-7 {
    padding-right: 56px !important; }
  .dx-pb-xxl-7,
  .dx-py-xxl-7 {
    padding-bottom: 56px !important; }
  .dx-pl-xxl-7,
  .dx-px-xxl-7 {
    padding-left: 56px !important; }
  .dx-p-xxl-8 {
    padding: 64px !important; }
  .dx-pt-xxl-8,
  .dx-py-xxl-8 {
    padding-top: 64px !important; }
  .dx-pr-xxl-8,
  .dx-px-xxl-8 {
    padding-right: 64px !important; }
  .dx-pb-xxl-8,
  .dx-py-xxl-8 {
    padding-bottom: 64px !important; }
  .dx-pl-xxl-8,
  .dx-px-xxl-8 {
    padding-left: 64px !important; } }

/* Slider */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "darwinex";
    font-size: 20px;
    line-height: 1;
    color: #011B34;
    opacity: 0.4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: ""; }
    [dir="rtl"] .slick-prev:before {
      content: ""; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: ""; }
    [dir="rtl"] .slick-next:before {
      content: ""; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "darwinex";
        font-size: 11px;
        line-height: 20px;
        text-align: center;
        color: transparent;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #026DCE;
      opacity: 0.4; }

.slick-loading .slick-list {
  background: url("https://cdn2.hubspot.net/hubfs/4288148/scouting-program-landing/images/loaders/ajax-loader.gif") center center no-repeat;
  background-size: 50px; }

.slick-track {
  display: flex; }

.slick-dotted.slick-slider {
  margin-bottom: 0; }
  .slick-dotted.slick-slider.slick-vertical {
    padding-right: 60px;
    margin-bottom: 0; }
    .slick-dotted.slick-slider.slick-vertical .slick-dots {
      bottom: 0;
      top: 0;
      left: auto;
      right: 0;
      width: 30px;
      display: flex;
      justify-content: center;
      flex-direction: column; }
      .slick-dotted.slick-slider.slick-vertical .slick-dots li {
        height: 25px; }
        .slick-dotted.slick-slider.slick-vertical .slick-dots li button {
          margin: auto; }

.slick-slide {
  height: auto; }
  .slick-slide:focus {
    outline: none; }

.slick-dots {
  position: relative;
  bottom: auto;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 1px solid #026DCE;
      border-radius: 50%;
      background: transparent;
      display: block;
      height: 11px;
      width: 11px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        display: none; }
    .slick-dots li.slick-active button {
      background-color: #026DCE; }

.slick-prev {
  left: -1.25rem; }
  @media (min-width: 576px) {
    .slick-prev {
      left: -1.3rem; } }

.slick-next {
  right: -1.25rem; }
  @media (min-width: 576px) {
    .slick-next {
      right: -1.3rem; } }

.slick-prev:before,
.slick-next:before {
  font-size: 2rem; }

.slick-text-dots .slick-dots li {
  width: auto;
  height: auto;
  border-bottom: 2px solid;
  color: #FFF;
  border-bottom-color: #E0E0E0; }
  .slick-text-dots .slick-dots li.slick-active {
    color: #009BFF;
    border-bottom-color: #009BFF; }

.slick-arrow.slick-disabled {
  opacity: 0.25; }

@media (min-width: 992px) {
  .is-animated {
    transition: .5s all ease-out; }
    .is-animated.is-hidden {
      opacity: 0; }
    .is-animated.is-animated--up.is-hidden {
      opacity: 0;
      transform: translateY(2rem); }
    .is-animated.is-animated--right.is-hidden {
      opacity: 0;
      transform: translateX(-2rem); }
    .is-animated.is-animated--left.is-hidden {
      opacity: 0;
      transform: translateX(2rem); }
    .is-animated.is-animated--scaleup {
      transition: .6s all ease-out; }
      .is-animated.is-animated--scaleup.is-hidden {
        opacity: 0;
        transform: scale(0); }
    .is-animated.is-animated--consecutive.is-hidden .is-animated__item {
      opacity: 0;
      transform: translateY(2rem); }
    .is-animated.is-animated--consecutive .is-animated__item:nth-child(1) {
      transition: 0.5s opacity ease-in-out 0s, 0.5s transform ease-in-out 0s; }
    .is-animated.is-animated--consecutive .is-animated__item:nth-child(2) {
      transition: 0.5s opacity ease-in-out 0.2s, 0.5s transform ease-in-out 0.2s; }
    .is-animated.is-animated--consecutive .is-animated__item:nth-child(3) {
      transition: 0.5s opacity ease-in-out 0.4s, 0.5s transform ease-in-out 0.4s; }
    .is-animated.is-animated--consecutive .is-animated__item:nth-child(4) {
      transition: 0.5s opacity ease-in-out 0.6s, 0.5s transform ease-in-out 0.6s; }
    .is-animated.is-animated--consecutive .is-animated__item:nth-child(5) {
      transition: 0.5s opacity ease-in-out 0.8s, 0.5s transform ease-in-out 0.8s; }
    .is-animated.is-animated--consecutive .is-animated__item:nth-child(6) {
      transition: 0.5s opacity ease-in-out 1s, 0.5s transform ease-in-out 1s; }
    .is-animated.is-animated--consecutive .is-animated__item:nth-child(7) {
      transition: 0.5s opacity ease-in-out 1.2s, 0.5s transform ease-in-out 1.2s; }
    .is-animated.is-animated--consecutive .is-animated__item:nth-child(8) {
      transition: 0.5s opacity ease-in-out 1.4s, 0.5s transform ease-in-out 1.4s; }
    .is-animated.is-animated--consecutive .is-animated__item:nth-child(9) {
      transition: 0.5s opacity ease-in-out 1.6s, 0.5s transform ease-in-out 1.6s; }
    .is-animated.is-animated--consecutive .is-animated__item:nth-child(10) {
      transition: 0.5s opacity ease-in-out 1.8s, 0.5s transform ease-in-out 1.8s; }
    .is-animated.is-animated--circlechart #circleChartDscore40 #OvalLight {
      stroke-dasharray: 400;
      transition: .75s all linear; }
    .is-animated.is-animated--circlechart #circleChartDscore40 #OvalDark {
      stroke-dasharray: 200;
      transition: .75s all linear .75s; }
    .is-animated.is-animated--circlechart #circleChartDscore20 #OvalLight2 {
      stroke-dasharray: 400;
      transition: .75s all linear; }
    .is-animated.is-animated--circlechart #circleChartDscore20 #OvalDark2 {
      stroke-dasharray: 200;
      transition: .75s all linear .75s; }
    .is-animated.is-animated--circlechart.is-hidden #circleChartDscore40 #OvalLight {
      stroke-dashoffset: -400; }
    .is-animated.is-animated--circlechart.is-hidden #circleChartDscore40 #OvalDark {
      stroke-dashoffset: -200; }
    .is-animated.is-animated--circlechart.is-hidden #circleChartDscore20 #OvalLight2 {
      stroke-dashoffset: -400; }
    .is-animated.is-animated--circlechart.is-hidden #circleChartDscore20 #OvalDark2 {
      stroke-dashoffset: -200; } }

.animation-backface {
  position: relative;
  transform: translateZ(0); }
  .animation-backface .animation-backface__front {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(1px) rotateY(0);
    transition: .25s transform ease-out; }
  .animation-backface .animation-backface__back {
    width: 100%;
    height: 100%;
    display: inline-block;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(-1px) rotateY(180deg);
    transition: .25s transform ease-out; }
  .is-active .animation-backface .animation-backface__front, .is-selected .animation-backface .animation-backface__front, .animation-backface .is-active .animation-backface__front, .animation-backface .is-selected .animation-backface__front {
    transform: translateZ(-1px) rotateY(180deg); }
  .is-active .animation-backface .animation-backface__back, .is-selected .animation-backface .animation-backface__back, .animation-backface .is-active .animation-backface__back, .animation-backface .is-selected .animation-backface__back {
    transform: translateZ(1px) rotateY(0); }

.bg-shade-100 {
  background-color: #FBFBFB !important; }

a.bg-shade-100:hover, a.bg-shade-100:focus,
button.bg-shade-100:hover,
button.bg-shade-100:focus {
  background-color: #e2e1e1 !important; }

.bg-shade-200 {
  background-color: #edf6fe !important; }

a.bg-shade-200:hover, a.bg-shade-200:focus,
button.bg-shade-200:hover,
button.bg-shade-200:focus {
  background-color: #bddefb !important; }

.bg-shade-300 {
  background-color: #ebf9ff !important; }

a.bg-shade-300:hover, a.bg-shade-300:focus,
button.bg-shade-300:hover,
button.bg-shade-300:focus {
  background-color: #b8eaff !important; }

.bg-shade-400 {
  background-color: #EDFBFF !important; }

a.bg-shade-400:hover, a.bg-shade-400:focus,
button.bg-shade-400:hover,
button.bg-shade-400:focus {
  background-color: #baf0ff !important; }

.bg-shade-500 {
  background-color: #F1F9FF !important; }

a.bg-shade-500:hover, a.bg-shade-500:focus,
button.bg-shade-500:hover,
button.bg-shade-500:focus {
  background-color: #bee3ff !important; }

.bg-shade-600 {
  background-color: #E0F1FF !important; }

a.bg-shade-600:hover, a.bg-shade-600:focus,
button.bg-shade-600:hover,
button.bg-shade-600:focus {
  background-color: #addaff !important; }

.bg-gray-100 {
  background-color: #E0E0E0 !important; }

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #c7c6c6 !important; }

.bg-gray-200 {
  background-color: #979797 !important; }

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #7e7d7d !important; }

.bg-gray-300 {
  background-color: #4a4a4a !important; }

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #313030 !important; }

.bg-gray-400 {
  background-color: #3d3d3d !important; }

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #242323 !important; }

.bg-gray-500 {
  background-color: #373737 !important; }

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #1e1d1d !important; }

.bg-gray-600 {
  background-color: #272727 !important; }

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #0e0d0d !important; }

.bg-gray-700 {
  background-color: #202020 !important; }

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #070606 !important; }

.bg-gray-800 {
  background-color: #343a40 !important; }

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #1d2124 !important; }

.bg-gray-900 {
  background-color: #212529 !important; }

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #0a0c0d !important; }

.bg-gray-default {
  background-color: #9B9B9B !important; }

a.bg-gray-default:hover, a.bg-gray-default:focus,
button.bg-gray-default:hover,
button.bg-gray-default:focus {
  background-color: #828181 !important; }

.bg-section-intro-account-types {
  background-image: url(../images/bg/bg-section-intro-account-types.png);
  background-position: center center; }

.bg-section-intro-stocks-pricing {
  background-image: url(../images/bg/bg-section-intro-stocks-pricing.png);
  background-position: center center; }

.bg-section-intro-futures-pricing {
  background-image: url(../images/bg/bg-section-intro-futures-pricing.png);
  background-position: center center; }

.bg-section-intro-assets-spreads {
  background-image: url(../images/bg/bg-section-intro-assets-spreads.jpg);
  background-position: center center; }

.bg-section-intro-platforms-connectivity {
  background-image: url(../images/bg/bg-section-intro-platforms-connectivity.png); }

.bg-section-intro-professional-traders {
  background-image: url(../images/bg/bg-section-intro-professional-traders.png); }

.bg-section-intro-darwinia {
  background-image: url(../images/bg/bg-section-intro-darwinia.png); }

.bg-section-intro-pfees {
  background-image: url(../images/bg/bg-section-intro-third-party-capital.png); }

.bg-trader-at-work {
  background-image: url(../images/bg/bg-trader-at-work.png);
  background-position: center right; }

.bg-section-intro-invest-in-darwins {
  background-image: url(../images/bg/bg-section-intro-invest-in-darwins.png);
  background-position: center center; }

.bg-section-intro-talent-linked-prices {
  background-image: url(../images/bg/bg-section-intro-talent-linked-pricing.png); }

.bg-section-intro-deposit-withdrawals {
  background-image: url(../images/bg/bg-section-intro-deposit-withdrawals.png);
  background-position: center center; }

.bg-section-intro-deposit-protection {
  background-image: url(../images/bg/bg-section-intro-deposit-protection.png);
  background-size: cover;
  background-position: center center; }

.bg-section-intro-hall-of-fame {
  background-image: url(../images/bg/bg-section-intro-hall-of-fame.png);
  background-position: center center; }

.bg-section-intro-contact-us {
  background-image: url(../images/bg/bg-section-intro-contact-us.png); }

.bg-trader-checking-mobile {
  background-image: url(../images/bg/bg-trader-checking-mobile.png);
  background-size: cover; }

.bg-section-intro-vision-history {
  background-image: url(../images/bg/bg-section-intro-vision-history.png);
  background-size: cover;
  background-position: center center; }

.bg-section-intro-careers {
  background-image: url(../images/bg/bg-section-intro-join-us.png);
  background-position: center center; }

.bg-skyscrappers {
  background-image: url(../images/bg/bg-skyscrappers.jpg); }

.bg-skyscrappers-3 {
  background-image: url(../images/bg/bg-skyscrappers-3.jpg);
  background-position: center top; }

.bg-skyscrappers-4 {
  background-image: url(../images/bg/bg-skyscrappers-4.jpg);
  background-position: center center; }

.bg-skyscrappers-5 {
  background-image: url(../images/bg/bg-skyscrappers-5.jpg);
  background-position: center center; }

.bg-office-futuristic {
  background-image: url(../images/bg/bg-office-futuristic.jpg);
  background-position: center right; }

.bg-traders {
  background-image: url(../images/bg/bg-traders.jpg); }

.bg-trader-winner {
  background-image: url(../images/bg/bg-trader-winner.jpg); }

.bg-trader-winner-2 {
  background-image: url(../images/bg/bg-trader-winner-2.jpg);
  background-position: top center; }

.bg-trader-stairs-2 {
  background-image: url(../images/bg/bg-trader-stairs-2.jpg);
  background-position: center center; }

.bg-trader-calling {
  background-image: url(../images/bg/bg-trader-calling.jpg); }

.bg-globe-chart {
  background-image: url(../images/bg/bg-globe-chart.jpg); }

.bg-trader-charts {
  background-image: url(../images/bg/bg-trader-charts.jpg); }

.bg-charts-candles-dots {
  background-image: url(../images/bg/bg-charts-candles-dots.jpg); }

.bg-candle-chart {
  background-image: url(../images/bg/bg-candle-chart.jpg); }

.bg-motif-candles {
  background-image: url(../images/bg/bg-motif-candles.png);
  background-size: cover;
  background-position: center center; }

.bg-safelock-binary {
  background-image: url(../images/bg/bg-safelock-binary.jpg);
  background-size: cover;
  background-position: center center; }

.bg-traders-office {
  background-image: url(../images/bg/bg-traders-office.jpg);
  background-size: cover;
  background-position: center center; }

.bg-darwinex-staff {
  background-image: url(../images/bg/bg-darwinex-staff.jpg); }

.bg-60 {
  background-size: 60%;
  background-repeat: no-repeat; }

.bg-laptop-imac {
  background-image: url(../images/global/laptop-and-imac.png); }

.bg-half {
  background-position: left center;
  background-repeat: no-repeat; }

.bg-technology {
  background-image: url(../images/bg/bg-technology.png);
  background-position: center center; }

.bg-technology-2 {
  background-image: url(../images/bg/bg-technology-2.png);
  background-position: center center; }

.bg-technology-3 {
  background-image: url(../images/bg/bg-technology-3.png);
  background-position: center center; }

.bg-technology-4 {
  background-image: url(../images/bg/bg-technology-4.png);
  background-position: center center; }

.bg-world-connected {
  background-image: url(../images/bg/bg-world-connected.jpg);
  background-position: center bottom; }

.bg-staff {
  background-image: url(../images/bg/bg-staff.jpg);
  background-position: center center; }

.bg-join-us {
  background-image: url(../images/bg/bg-join-us.jpg);
  background-position: center center; }

.bg-teamwork {
  background-image: url(../images/bg/bg-teamwork.jpg);
  background-position: center center; }

.bg-black {
  background-color: #202020; }
  .bg-black-img {
    background-position: center center;
    background-size: cover;
    background-image: url(../images/bg/bg-black.svg); }
  .bg-black-white {
    background: linear-gradient(to bottom, #202020 0%, #202020 50%, #FFF 50%, #FFF 100%); }

.bg-black-left {
  background-position: center;
  background-size: cover;
  background-image: url(../images/bg/bg-black-left.svg); }

.bg-black-right {
  background-position: center;
  background-size: cover;
  background-image: url(../images/bg/bg-black-right.svg); }

.bg-white-left {
  background-position: center;
  background-size: cover;
  background-image: url(../images/bg/bg-light-left.svg); }

.bg-white-right {
  background-position: center;
  background-size: cover;
  background-image: url(../images/bg/bg-light-right.svg); }

.bg-become-investable {
  background-size: cover;
  background-image: url(../images/bg/bg-become-investable.png); }

.bg-black-owl {
  background: linear-gradient(to right, #202020 50%, rgba(50, 68, 84, 0.8)), url(../images/ibkr/shutterstock_1135978331.png) center right/cover no-repeat;
  mix-blend-mode: multiply; }

.bg-black-client {
  background: linear-gradient(to right, #202020 50%, rgba(50, 68, 84, 0.8)), url(../images/ibkr/ibkr-client.png) center right/cover no-repeat;
  mix-blend-mode: multiply; }

:root {
  --color-0a: #FFFFFF;
  --color-00: #979797;
  --color-01: #84A1A7;
  --color-02: #6FB0B6;
  --color-03: #E35527;
  --color-04: #C48ED1;
  --color-05: #F9BF3B;
  --color-06: #FF6464;
  --color-07: #F62459;
  --color-08: #D2527F;
  --color-09: #2AC7CA;
  --color-10: #2ECC71;
  --color-11: #26A65B;
  --color-12: #f4b350;
  --color-13: #2AC7CA;
  --color-14: #03C9A9;
  --color-15: #E08283;
  --color-16: #BA7209;
  --color-17: #E67E22;
  --color-18: #AEA8D3;
  --color-19: #00BD87;
  --color-20: #81A7AA;
  --color-21: #4ECDC4;
  --color-22: #D24D57;
  --color-23: #FF4141;
  --color-24: #9A12B3;
  --color-25: #007bba;
  --color-26: #026DCE;
  --color-27: #3da0cc;
  --color-28: #3d93a0;
  --color-29: #00AD34;
  --color-30: #F27507;
  --color-31: #BA3232; }

body {
  overflow-x: hidden;
  position: relative; }
  @media (max-width: 991.98px) {
    body.has-sidebar-navbar-open, body.has-navbar-fixed-open, body.has-dropdown-fixed-open {
      overflow: hidden; } }
  body .page-content {
    padding-top: 169px; }
    body .page-content_noEsma {
      padding-top: 96px; }

.ie-show {
  display: none; }
  .ie-show _:-ms-lang(x), .ie-show {
    display: block; }

.ie-hide {
  display: block; }
  .ie-hide _:-ms-lang(x), .ie-hide {
    display: none; }

.white-space-normal {
  white-space: normal !important; }

a:focus,
.btn:focus {
  outline: 0; }

.link-dark:focus {
  outline: 0; }

strong, b,
.text-bold,
.font-weight-bold {
  font-family: "sofia-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700; }

.font-weight-semibold {
  font-weight: 500 !important; }

p, li {
  font-style: normal;
  font-weight: 300;
  line-height: 1.4; }

small {
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 14px; }

a {
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #026DCE; }

.link-bg-black {
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #8BC1FF; }

.link-bg-black:hover {
  color: #009BFF; }

.font-size-xxl {
  font-size: 4rem; }

.font-size-h1 {
  font-size: 3rem; }

.font-size-h2 {
  font-size: 2.5rem; }

.font-size-h3 {
  font-size: 2rem; }

.font-size-h4 {
  font-size: 1.5rem; }

.font-size-lg {
  font-size: 1.25rem; }

.font-size-base, .portrait-hoverable .portrait-name, .section--legal-documents {
  font-size: 1rem; }

.font-size-sm, .portrait-hoverable .portrait-position {
  font-size: 0.875rem; }

.font-size-xs {
  font-size: 0.75rem; }

.font-size-xxs {
  font-size: 0.625rem; }

@media (max-width: 1199.98px) {
  .font-size-xxl {
    font-size: 3rem; }
  .font-size-h1 {
    font-size: 2.5rem; }
  .font-size-h2 {
    font-size: 2rem; }
  .font-size-h3 {
    font-size: 1.5rem; }
  .font-size-h4 {
    font-size: 1.25rem; }
  .font-size-lg {
    font-size: 1rem; }
  .font-size-base, .portrait-hoverable .portrait-name, .section--legal-documents {
    font-size: 0.875rem; }
  .font-size-sm, .portrait-hoverable .portrait-position {
    font-size: 0.75rem; }
  .font-size-xs {
    font-size: 0.625rem; }
  .font-size-xxs {
    font-size: 0.5rem; }
  h1, .h1 {
    font-size: 2.5rem; }
  h2, .h2 {
    font-size: 2rem; }
  h3, .h3 {
    font-size: 1.5rem; }
  h4, .h4 {
    font-size: 1.25rem; }
  h5, .h5 {
    font-size: 1rem; }
  h6, .h6 {
    font-size: 0.875rem; } }

.btn {
  white-space: normal;
  text-transform: uppercase; }
  .btn--dot {
    padding: 0;
    border-radius: 50%;
    width: 11px;
    height: 11px; }
  .btn--minwidth {
    min-width: 266px; }
  .btn--minwidth-sm {
    min-width: 150px; }
  @media (max-width: 767.98px) {
    .btn--mobile-minwidth {
      min-width: 266px; } }
  .btn--rect {
    border-radius: 0; }
  .btn.rounded-circle {
    width: 34px;
    height: 34px;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1rem;
    font-size: 1rem; }
  .btn--rect {
    border-radius: 0; }
  .btn.btn-link:hover, .btn.btn-link:focus {
    text-decoration: none; }
  @media (max-width: 1199.98px) {
    .btn {
      font-size: 0.875rem; } }
  @media (min-width: 992px) {
    .btn--lg-rounded {
      border-radius: 20px; } }
  @media (max-width: 767.98px) {
    .btn--xs-block {
      display: block;
      width: 100%; } }
  .btn.btn-gray {
    color: #FFFFFF; }
  .btn.btn-dark {
    background-color: #202020; }
    .btn.btn-dark:hover {
      opacity: 0.8; }

.border-effect {
  position: relative;
  padding-top: 1rem; }
  .border-effect:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 3px;
    border-radius: 10px;
    background: linear-gradient(90deg, transparent, #026DCE); }
  .border-effect--sm:before {
    width: 70px; }
  .border-effect.text-center:before {
    left: 50%;
    margin-left: -50px; }
  @media (min-width: 992px) {
    .border-effect.is-animated:before {
      transition: .5s all ease-out; }
    .border-effect.is-animated.is-hidden:before {
      width: 0;
      opacity: 0; } }

.darwin-name {
  display: inline-flex;
  align-items: center;
  font-weight: 500; }
  .darwin-name:before {
    content: '';
    width: 15px;
    height: 15px;
    background-color: #026DCE;
    background-color: var(--blue);
    margin-right: 0.4375rem;
    border-radius: 50%;
    flex-shrink: 0; }
  .darwin-name--compact:before {
    margin-right: .2rem; }
  .darwin-name--blue:before {
    background-color: #026DCE;
    background-color: var(--blue); }
  .darwin-name--indigo:before {
    background-color: #6610f2;
    background-color: var(--indigo); }
  .darwin-name--purple:before {
    background-color: #6f42c1;
    background-color: var(--purple); }
  .darwin-name--pink:before {
    background-color: #e83e8c;
    background-color: var(--pink); }
  .darwin-name--red:before {
    background-color: #FF4141;
    background-color: var(--red); }
  .darwin-name--orange:before {
    background-color: #F2A007;
    background-color: var(--orange); }
  .darwin-name--yellow:before {
    background-color: #ffce00;
    background-color: var(--yellow); }
  .darwin-name--green:before {
    background-color: #79AA00;
    background-color: var(--green); }
  .darwin-name--teal:before {
    background-color: #20c997;
    background-color: var(--teal); }
  .darwin-name--cyan:before {
    background-color: #17a2b8;
    background-color: var(--cyan); }
  .darwin-name--white:before {
    background-color: #FFFFFF;
    background-color: var(--white); }
  .darwin-name--gray:before {
    background-color: #9B9B9B;
    background-color: var(--gray); }
  .darwin-name--gray-dark:before {
    background-color: #343a40;
    background-color: var(--gray-dark); }
  .darwin-name--blue-alt:before {
    background-color: #089CFB;
    background-color: var(--blue-alt); }
  .darwin-name--blue-light:before {
    background-color: #009BFF;
    background-color: var(--blue-light); }
  .darwin-name--blue-dark:before {
    background-color: #011B34;
    background-color: var(--blue-dark); }
  .darwin-name--blue-dark-alt:before {
    background-color: #03071A;
    background-color: var(--blue-dark-alt); }
  .darwin-name--blue-darker:before {
    background-color: #052847;
    background-color: var(--blue-darker); }
  .darwin-name--blue-gray:before {
    background-color: #324454;
    background-color: var(--blue-gray); }
  .darwin-name--dark-grayish-cian:before {
    background-color: #81A7AA;
    background-color: var(--dark-grayish-cian); }
  .darwin-name--magenta:before {
    background-color: #C48ED1;
    background-color: var(--magenta); }
  .darwin-name--orange-soft:before {
    background-color: #f4b350;
    background-color: var(--orange-soft); }
  .darwin-name--orange-dark:before {
    background-color: #BA7209;
    background-color: var(--orange-dark); }
  .darwin-name--green-dark:before {
    background-color: #79AA00;
    background-color: var(--green-dark); }
  .darwin-name--red-dark:before {
    background-color: #BA3232;
    background-color: var(--red-dark); }
  .darwin-name--red-bright:before {
    background-color: #E35527;
    background-color: var(--red-bright); }
  .darwin-name--color-0a:before {
    background-color: #FFFFFF;
    background-color: var(--color-0a); }
  .darwin-name--color-00:before {
    background-color: #979797;
    background-color: var(--color-00); }
  .darwin-name--color-01:before {
    background-color: #84A1A7;
    background-color: var(--color-01); }
  .darwin-name--color-02:before {
    background-color: #6FB0B6;
    background-color: var(--color-02); }
  .darwin-name--color-03:before {
    background-color: #E35527;
    background-color: var(--color-03); }
  .darwin-name--color-04:before {
    background-color: #C48ED1;
    background-color: var(--color-04); }
  .darwin-name--color-05:before {
    background-color: #F9BF3B;
    background-color: var(--color-05); }
  .darwin-name--color-06:before {
    background-color: #FF6464;
    background-color: var(--color-06); }
  .darwin-name--color-07:before {
    background-color: #F62459;
    background-color: var(--color-07); }
  .darwin-name--color-08:before {
    background-color: #D2527F;
    background-color: var(--color-08); }
  .darwin-name--color-09:before {
    background-color: #2AC7CA;
    background-color: var(--color-09); }
  .darwin-name--color-10:before {
    background-color: #2ECC71;
    background-color: var(--color-10); }
  .darwin-name--color-11:before {
    background-color: #26A65B;
    background-color: var(--color-11); }
  .darwin-name--color-12:before {
    background-color: #f4b350;
    background-color: var(--color-12); }
  .darwin-name--color-13:before {
    background-color: #2AC7CA;
    background-color: var(--color-13); }
  .darwin-name--color-14:before {
    background-color: #03C9A9;
    background-color: var(--color-14); }
  .darwin-name--color-15:before {
    background-color: #E08283;
    background-color: var(--color-15); }
  .darwin-name--color-16:before {
    background-color: #BA7209;
    background-color: var(--color-16); }
  .darwin-name--color-17:before {
    background-color: #E67E22;
    background-color: var(--color-17); }
  .darwin-name--color-18:before {
    background-color: #AEA8D3;
    background-color: var(--color-18); }
  .darwin-name--color-19:before {
    background-color: #00BD87;
    background-color: var(--color-19); }
  .darwin-name--color-20:before {
    background-color: #81A7AA;
    background-color: var(--color-20); }
  .darwin-name--color-21:before {
    background-color: #4ECDC4;
    background-color: var(--color-21); }
  .darwin-name--color-22:before {
    background-color: #D24D57;
    background-color: var(--color-22); }
  .darwin-name--color-23:before {
    background-color: #FF4141;
    background-color: var(--color-23); }
  .darwin-name--color-24:before {
    background-color: #9A12B3;
    background-color: var(--color-24); }
  .darwin-name--color-25:before {
    background-color: #007bba;
    background-color: var(--color-25); }
  .darwin-name--color-26:before {
    background-color: #026DCE;
    background-color: var(--color-26); }
  .darwin-name--color-27:before {
    background-color: #3da0cc;
    background-color: var(--color-27); }
  .darwin-name--color-28:before {
    background-color: #3d93a0;
    background-color: var(--color-28); }
  .darwin-name--color-29:before {
    background-color: #00AD34;
    background-color: var(--color-29); }
  .darwin-name--color-30:before {
    background-color: #F27507;
    background-color: var(--color-30); }
  .darwin-name--color-31:before {
    background-color: #BA3232;
    background-color: var(--color-31); }
  .darwin-name--xxl {
    font-size: 4rem; }
    .darwin-name--xxl:before {
      width: 2.8rem;
      height: 2.8rem; }
  .darwin-name--h1 {
    font-size: 3rem; }
    .darwin-name--h1:before {
      width: 2.1rem;
      height: 2.1rem; }
  .darwin-name--h2 {
    font-size: 2.5rem; }
    .darwin-name--h2:before {
      width: 1.75rem;
      height: 1.75rem; }
  .darwin-name--h3 {
    font-size: 2rem; }
    .darwin-name--h3:before {
      width: 1.4rem;
      height: 1.4rem; }
  .darwin-name--h4 {
    font-size: 1.5rem; }
    .darwin-name--h4:before {
      width: 1.05rem;
      height: 1.05rem; }
  .darwin-name--lg {
    font-size: 1.25rem; }
    .darwin-name--lg:before {
      width: 0.875rem;
      height: 0.875rem; }
  .darwin-name--base {
    font-size: 1rem; }
    .darwin-name--base:before {
      width: 0.7rem;
      height: 0.7rem; }
  .darwin-name--sm {
    font-size: 0.875rem; }
    .darwin-name--sm:before {
      width: 0.6125rem;
      height: 0.6125rem; }
  .darwin-name--xs {
    font-size: 0.75rem; }
    .darwin-name--xs:before {
      width: 0.525rem;
      height: 0.525rem; }
  .darwin-name--xxs {
    font-size: 0.625rem; }
    .darwin-name--xxs:before {
      width: 0.4375rem;
      height: 0.4375rem; }

.table {
  line-height: 1.2;
  margin-bottom: 0;
  border-spacing: 0;
  border-collapse: unset; }
  .table th {
    font-weight: normal; }
  .table thead th {
    border-width: 1px;
    vertical-align: middle; }
  .table--no-border-top tr:first-of-type td, .table--no-border-top tr:first-of-type th {
    border-top: 0; }
  .table--no-space-break td, .table--no-space-break th {
    white-space: nowrap; }

.table-responsive {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .table-responsive::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .table-responsive::-webkit-scrollbar-track {
    background: #E0E0E0; }
  .table-responsive::-webkit-scrollbar-thumb {
    background: #979797; }
  .table-responsive::-webkit-scrollbar-thumb:hover {
    background: #979797; }

.table--border-primary td, .table--border-primary th, .table--border-primary thead th {
  border-color: #026DCE; }

.table--border-secondary td, .table--border-secondary th, .table--border-secondary thead th {
  border-color: #FFFFFF; }

.table--border-success td, .table--border-success th, .table--border-success thead th {
  border-color: #79AA00; }

.table--border-info td, .table--border-info th, .table--border-info thead th {
  border-color: #009BFF; }

.table--border-warning td, .table--border-warning th, .table--border-warning thead th {
  border-color: #F2A007; }

.table--border-danger td, .table--border-danger th, .table--border-danger thead th {
  border-color: #BA3232; }

.table--border-light td, .table--border-light th, .table--border-light thead th {
  border-color: #E0E0E0; }

.table--border-dark td, .table--border-dark th, .table--border-dark thead th {
  border-color: #011B34; }

.table--border-primary-light td, .table--border-primary-light th, .table--border-primary-light thead th {
  border-color: #009BFF; }

.table--border-danger-dark td, .table--border-danger-dark th, .table--border-danger-dark thead th {
  border-color: #BA3232; }

.table--border-gray td, .table--border-gray th, .table--border-gray thead th {
  border-color: #E0E0E0; }

ul {
  list-style-type: disc; }

ol.counter-increment {
  counter-reset: section;
  list-style-type: none;
  padding: 0; }
  ol.counter-increment ol {
    padding: 0;
    counter-reset: section;
    list-style-type: none;
    margin-top: 2rem; }
  ol.counter-increment li {
    counter-increment: section;
    margin-bottom: 2rem; }
    ol.counter-increment li:before {
      content: counters(section, ".") ". "; }

.list-colorbullet {
  list-style: none;
  /* Remove default bullets */
  padding-left: 1rem; }
  .list-colorbullet li {
    margin-bottom: 1rem; }
    .list-colorbullet li:before {
      content: "\2022";
      /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: #E0E0E0;
      /* Change the color */
      display: inline-block;
      /* Needed to add space between the bullet and the text */
      width: 1rem;
      /* Also needed for space (tweak if needed) */
      margin-left: -1rem;
      /* Also needed for space (tweak if needed) */
      font-size: 1.1875rem;
      line-height: 1; }
  .list-colorbullet--primary li::before {
    color: #026DCE;
    /* Change the color */ }

.form-control {
  border-radius: 2px;
  box-shadow: 0 0 4px 0 rgba(156, 156, 156, 0.5);
  padding-right: 2.75rem; }
  .form-control[readonly] {
    background-color: inherit; }

.form-group {
  margin-bottom: 1.5rem;
  position: relative; }

.checkbox label {
  display: flex;
  align-items: flex-start; }

.checkbox input[type=checkbox], .checkbox input[type=radio] {
  margin-right: .4rem;
  margin-top: 4px;
  flex-shrink: 0; }

.feedback-icon {
  font-size: 1rem; }

.valid-feedback.feedback-icon,
.invalid-feedback.feedback-icon {
  position: absolute;
  line-height: 1;
  top: 9px;
  right: 1.5rem;
  width: auto; }

.js-display-after-form-submit {
  display: none; }

.js-form-feedback {
  display: none; }
  .js-form-feedback__inner {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 768px) {
      .js-form-feedback__inner {
        height: 300px; } }

.form-onboarding {
  padding: 2rem;
  margin-left: auto;
  margin-right: auto; }
  .form-onboarding .checkbox label {
    margin: 0; }
  .form-onboarding__footer {
    border-top: 1px solid #E0E0E0;
    padding-top: 1.5rem;
    margin-top: 1.5rem; }
  .form-onboarding__register-link, .form-onboarding__btn-link {
    box-shadow: 0 0 4px 0 rgba(156, 156, 156, 0.5);
    padding: .5rem .75rem;
    color: #011B34;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-weight: 500;
    border-radius: 4px; }
    .form-onboarding__register-link:not(:last-child), .form-onboarding__btn-link:not(:last-child) {
      margin-bottom: 1rem; }
    .form-onboarding__register-link:hover, .form-onboarding__register-link:focus, .form-onboarding__btn-link:hover, .form-onboarding__btn-link:focus {
      text-decoration: none;
      background-color: #FBFBFB;
      color: #011B34; }
    @media (min-width: 576px) {
      .form-onboarding__register-link, .form-onboarding__btn-link {
        padding: 1rem; } }
  .form-onboarding__icon-register-as, .form-onboarding__btn-link-icon {
    margin-right: .75rem;
    width: 1.25rem;
    height: auto; }
    @media (min-width: 992px) {
      .form-onboarding__icon-register-as, .form-onboarding__btn-link-icon {
        width: 1.6875rem;
        margin-right: 1rem; } }
  .form-onboarding__disclaimer-cnmv {
    margin-bottom: 16px; }
  @media (min-width: 576px) {
    .form-onboarding__btn-link-group .form-onboarding__btn-link {
      min-height: 72px; } }
  @media (min-width: 768px) {
    .form-onboarding {
      width: 33.4375rem;
      padding: 2rem 3rem; } }

.btn-clear-input,
.btn-toggle-select,
.form-add-on {
  position: absolute;
  right: 1.5rem;
  top: .8rem;
  color: #b4b4b4;
  padding: 0;
  font-size: 1rem;
  background: transparent;
  border: 0;
  line-height: 1; }

.was-validated .form-control:valid, .form-control.is-valid,
.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:valid,
.custom-select.is-valid,
.was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  padding-right: 4.5rem; }
  .was-validated .form-control:valid ~ .btn-clear-input, .was-validated .form-control:valid ~ .btn-toggle-select, .form-control.is-valid ~ .btn-clear-input, .form-control.is-valid ~ .btn-toggle-select,
  .was-validated .form-control:invalid ~ .btn-clear-input,
  .was-validated .form-control:invalid ~ .btn-toggle-select, .form-control.is-invalid ~ .btn-clear-input, .form-control.is-invalid ~ .btn-toggle-select, .was-validated
  .custom-select:valid ~ .btn-clear-input, .was-validated
  .custom-select:valid ~ .btn-toggle-select,
  .custom-select.is-valid ~ .btn-clear-input,
  .custom-select.is-valid ~ .btn-toggle-select,
  .was-validated
  .custom-select:invalid ~ .btn-clear-input,
  .was-validated
  .custom-select:invalid ~ .btn-toggle-select,
  .custom-select.is-invalid ~ .btn-clear-input,
  .custom-select.is-invalid ~ .btn-toggle-select {
    right: 3rem; }
  .was-validated .form-control:valid select.form-control, .form-control.is-valid select.form-control,
  .was-validated .form-control:invalid select.form-control, .form-control.is-invalid select.form-control, .was-validated
  .custom-select:valid select.form-control,
  .custom-select.is-valid select.form-control,
  .was-validated
  .custom-select:invalid select.form-control,
  .custom-select.is-invalid select.form-control {
    background-position: right 3.8rem center; }

select.form-control {
  padding: 0.5rem 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-image: url(../images/icons/chevron-down.svg);
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: right 1rem center; }
  select.form-control.default-value {
    color: rgba(1, 27, 52, 0.7); }
  select.form-control select.form-control::-ms-expand {
    display: none; }

label.file {
  width: 100%;
  color: #011B34;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  position: relative; }
  label.file .file-custom {
    position: relative;
    width: 100%;
    display: block;
    padding-right: 60px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  label.file input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer; }
  label.file .btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    position: absolute;
    right: -1px;
    top: -1px;
    height: 45px;
    padding: .815rem 1rem;
    line-height: 1;
    font-size: .875rem;
    z-index: 3; }

.loading-spinner {
  display: inline-block; }
  .loading-spinner.spinner-info {
    color: #026DCE; }
  .loading-spinner.spinner-warning {
    color: #F2A007; }
  .loading-spinner.spinner-danger {
    color: #BA3232; }
  .loading-spinner.spinner-bubble {
    width: 30px;
    height: 30px;
    position: relative; }
    .loading-spinner.spinner-bubble > span {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto; }
      .loading-spinner.spinner-bubble > span:after {
        -webkit-animation: spinner-bubble 1.3s linear 0s infinite;
        -ms-animation: spinner-bubble 1.3s linear 0s infinite;
        animation: spinner-bubble 1.3s linear 0s infinite;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 100%;
        box-shadow: 0 0 0 3px;
        margin: auto;
        width: 10px;
        height: 10px;
        opacity: 0; }
      .loading-spinner.spinner-bubble > span:before {
        -webkit-animation: spinner-bubble 1.3s linear 0.7s infinite;
        -ms-animation: spinner-bubble 1.3s linear 0.7s infinite;
        animation: spinner-bubble 1.3s linear 0.7s infinite;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 100%;
        box-shadow: 0 0 0 3px;
        margin: auto;
        width: 10px;
        height: 10px;
        opacity: 0; }
    .loading-spinner.spinner-bubble.spinner-bubble--xl {
      width: 50px;
      height: 50px; }
      .loading-spinner.spinner-bubble.spinner-bubble--xl > span {
        width: 50px;
        height: 50px; }
        .loading-spinner.spinner-bubble.spinner-bubble--xl > span:after {
          -webkit-animation: spinner-bubble-xl 1.3s linear 0s infinite;
          -ms-animation: spinner-bubble-xl 1.3s linear 0s infinite;
          animation: spinner-bubble-xl 1.3s linear 0s infinite;
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border-radius: 100%;
          box-shadow: 0 0 0 3px;
          margin: auto;
          width: 10px;
          height: 10px;
          opacity: 0; }
        .loading-spinner.spinner-bubble.spinner-bubble--xl > span:before {
          -webkit-animation: spinner-bubble-xl 1.3s linear 0.7s infinite;
          -ms-animation: spinner-bubble-xl 1.3s linear 0.7s infinite;
          animation: spinner-bubble-xl 1.3s linear 0.7s infinite;
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border-radius: 100%;
          box-shadow: 0 0 0 3px;
          margin: auto;
          width: 10px;
          height: 10px;
          opacity: 0; }

.list-divided {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem; }
  .list-divided__item {
    padding: 0 1rem; }
    .list-divided__item:not(:last-child) {
      border-right: 1px solid #011B34; }

.dropdown__currency {
  border: 1px solid #011B34; }
  .dropdown__currency .dropdown-item {
    border-bottom: 1px solid #979797;
    height: 32px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    color: #011B34; }
    .dropdown__currency .dropdown-item:last-of-type {
      border-bottom: none; }
    .dropdown__currency .dropdown-item:focus, .dropdown__currency .dropdown-item:active {
      background-color: #E0E0E0; }
    .dropdown__currency .dropdown-item i {
      padding-left: 9px; }
  .dropdown__currency .dropdown-menu {
    padding: 0;
    min-width: 0;
    width: 100%; }

.dropdown__toggler {
  display: flex; }

.stats {
  list-style: none;
  padding-left: 0;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .stats {
      padding-top: 0; } }
  .stats__new {
    padding-top: 2rem;
    padding-bottom: 48px; }
    .stats__new .stats__item {
      width: 100%; }
      .stats__new .stats__item:first-of-type {
        width: unset; }
        .stats__new .stats__item:first-of-type::before {
          display: none; }
      .stats__new .stats__item::before {
        height: 100%; }
  .stats__item {
    position: relative;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .stats__value {
    line-height: 1;
    font-size: 2.5rem;
    font-weight: 500; }
    .stats__value + .stats__text {
      margin-top: 0.5rem; }
    .stats__value > small {
      font-size: 1rem;
      font-weight: 500; }
  .stats__icon + .stats__text {
    margin-top: 0.5rem; }
  .stats--vertical-divider .stats__item {
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .stats--vertical-divider .stats__item_home {
      padding-top: 32px;
      padding-bottom: 76px; }
      @media (max-width: 767.98px) {
        .stats--vertical-divider .stats__item_home {
          padding-top: 0px !important;
          padding-bottom: 0px !important; } }
  .stats__text {
    margin-bottom: 0;
    font-weight: 300; }
  @media (min-width: 768px) {
    .stats--vertical-divider {
      align-items: stretch; }
      .stats--vertical-divider .stats__item {
        padding: 0.75rem 2rem; }
        .stats--vertical-divider .stats__item_home {
          padding-top: 32px;
          padding-bottom: 76px; }
      .stats--vertical-divider.stats--xl .stats__item:not(:last-child):before {
        display: none; } }
  @media (min-width: 992px) {
    .stats--vertical-divider.stats--xl .stats__item:not(:last-child):before {
      display: block;
      content: '';
      position: absolute;
      height: 3.5rem;
      right: 0;
      width: 1px;
      background-color: #4a4a4a; } }
  @media (max-width: 767.98px) {
    .stats {
      flex-wrap: wrap;
      justify-content: center;
      margin-left: 0;
      margin-right: 0; }
      .stats__item {
        max-width: 100%; } }
  .stats_line {
    background-color: #858585;
    width: 2px; }
    @media (max-width: 1199.98px) {
      .stats_line {
        display: none; } }

.steps {
  list-style: none;
  padding-left: 0;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: 0; }
  .steps__row {
    display: flex;
    flex-direction: row; }
    @media (max-width: 991.98px) {
      .steps__row {
        flex-direction: column; } }
  .steps__number {
    color: #026DCE; }
  .steps__item {
    list-style: none;
    padding-bottom: 2rem; }
  .steps__value {
    line-height: 1;
    font-size: 2.5rem;
    font-weight: 500; }
    .steps__value + .steps__text {
      margin-top: 0.5rem; }
    .steps__value > small {
      font-size: 1rem;
      font-weight: 500; }
  .steps__icon + .steps__text {
    margin-top: 0.5rem; }
  .steps--vertical-divider .steps__item {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .steps__title {
    margin-bottom: 0;
    font-weight: 300; }
  .steps__text {
    margin-bottom: 0;
    font-weight: 300; }
    @media (max-width: 991.98px) {
      .steps__text {
        margin-right: 5px; } }
  @media (min-width: 768px) {
    .steps--vertical-divider {
      align-items: stretch; }
      .steps--vertical-divider .steps__item {
        padding: 0.75rem 2rem;
        justify-content: flex-start; }
        .steps--vertical-divider .steps__item:not(:last-child):before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          height: 3.5rem;
          right: 0;
          width: 1px;
          background-color: #4a4a4a; }
      .steps--vertical-divider.steps--xl .steps__item:not(:last-child):before {
        display: none; } }
  @media (min-width: 992px) {
    .steps--vertical-divider.steps--xl .steps__item:not(:last-child):before {
      display: block;
      content: '';
      position: absolute;
      height: 3.5rem;
      right: 0;
      width: 1px;
      background-color: #4a4a4a; } }
  @media (max-width: 991.98px) {
    .steps {
      flex-wrap: wrap; } }

.columns {
  list-style: none;
  padding-left: 0;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: 0;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row; }
  .columns__item {
    position: relative;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    list-style: none; }
  .columns__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 25px; }
    @media (max-width: 767.98px) {
      .columns__grid {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px; } }
  .columns__list {
    list-style: none; }
  .columns__title {
    margin-bottom: 0;
    font-weight: 300; }
  .columns__icon {
    margin-right: 7px; }
  .columns__iconbox {
    margin-right: 7px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 36px;
    padding: 5px; }
  .columns__icontext {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
  @media (max-width: 767.98px) {
    .columns {
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: 0;
      margin-right: 0; } }

.cta-float {
  min-width: 350px;
  min-height: 90px;
  position: fixed;
  right: 0;
  top: 229px;
  width: 280px;
  color: white;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  text-transform: uppercase;
  animation-name: fromRightToZero;
  animation-duration: 3s; }
  @media (max-width: 576px) {
    .cta-float {
      width: 100%;
      bottom: -5px;
      padding-left: 16px;
      padding-right: 16px; } }
  .cta-float__container-blue {
    padding: 1rem;
    border-radius: 10px 0 0 10px;
    background-color: #026DCE;
    background-color: var(--primary);
    transition: all 300ms ease; }
    .cta-float__container-blue:hover, .cta-float__container-blue:focus {
      background-color: #02529b; }
  .cta-float__container-purple {
    padding: 1rem;
    border-radius: 10px 0 0 10px;
    background-color: #1e1727;
    transition: all 300ms ease; }
    .cta-float__container-purple:hover, .cta-float__container-purple:focus {
      background-color: #050407; }
  .cta-float__container {
    width: 350px;
    min-height: 90px;
    background-color: #026DCE;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    position: relative; }
    .cta-float__container:hover .cta-float__circle {
      transform: translateX(270px);
      transition: transform 0.2s; }
    .cta-float__container:hover .cta-float__invest {
      transform: translateX(-75px);
      transition: transform 0.2s; }
    @media (max-width: 576px) {
      .cta-float__container {
        width: 100%; } }
  .cta-float__mobile {
    min-width: 290px;
    min-height: 70px;
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    background-color: #026DCE;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px; }
    .cta-float__mobile__invest {
      margin-left: 20px;
      width: 65%; }
    .cta-float__mobile__circle {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: #009BFF;
      box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25) inset;
      border: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center; }
  .cta-float__text-container {
    display: flex;
    align-items: center;
    gap: 10px; }
  .cta-float__circle {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #009BFF;
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25) inset;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0; }
  .cta-float__invest {
    text-align: start;
    font-size: 16px;
    position: absolute;
    left: 110px;
    top: 0;
    height: 90px;
    display: flex;
    align-items: center; }
  .cta-float__icon {
    display: block;
    margin: 0 auto 1rem; }
  .cta-float__text {
    font-weight: 500;
    text-align: center; }
  .cta-float__arrow-blue {
    width: 30px;
    height: 30px;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    background-image: url(../images/icons/mini-chevron-right.svg);
    transition: background 0.2s ease-out;
    background-position: center center;
    background-repeat: repeat-x;
    margin: 0 auto 1rem; }
  .cta-float__arrow-purple {
    width: 30px;
    height: 30px;
    border: 2px solid #e27139;
    border-radius: 50%;
    background-image: url(../images/icons/mini-chevron-down.svg);
    transform: rotate(90deg);
    transition: background 0.2s ease-out;
    background-position: center center;
    background-repeat: repeat-x;
    margin: 0 auto 1rem; }
  .cta-float:hover .cta-float__arrow-blue,
  .cta-float:hover .cta-float__arrow-purple, .cta-float:focus .cta-float__arrow-blue,
  .cta-float:focus .cta-float__arrow-purple {
    background-position: 29px center; }
  .cta-float a {
    line-height: 1.5;
    color: #FFFFFF; }
    .cta-float a:hover, .cta-float a:focus {
      text-decoration: none; }

@keyframes fromRightToZero {
  from {
    right: -100%; }
  to {
    right: 0; } }

.make-trading {
  display: inline-flex;
  align-items: center; }
  .make-trading__quote {
    margin-right: 0.4375rem; }

.darwin-stats tr {
  height: 76px; }

.darwin-stats td {
  vertical-align: middle; }

.darwin-stats__title {
  font-size: 1.25rem; }

.darwin-stats__data {
  font-size: 2.5rem;
  font-weight: 500;
  text-align: right; }

.darwin-stats--sm tr {
  height: 50px; }

.darwin-stats--sm .darwin-stats__title {
  font-size: 1rem;
  max-width: 100px;
  padding-left: 0;
  padding-right: 0; }

.darwin-stats--sm .darwin-stats__data {
  font-size: 1rem;
  text-align: right;
  padding-left: 0;
  padding-right: 0; }

@media screen and (max-height: 850px) {
  .horizontal .darwin-stats tr {
    height: 65px; }
  .horizontal .darwin-stats--sm tr {
    height: 40px; } }

@media (max-width: 991.98px) {
  .darwin-stats__title {
    font-size: 1rem; }
  .darwin-stats__data {
    font-size: 1.25rem; }
  .darwin-stats--sm .darwin-stats__title {
    font-size: 0.875rem; }
  .darwin-stats--sm .darwin-stats__data {
    font-size: 0.875rem; }
  .darwin-stats tr {
    height: 60px; }
  .darwin-stats--sm tr {
    height: 50px; } }

.user-intro {
  text-align: center; }
  .user-intro .user-avatar {
    margin: 0 auto 1rem;
    width: 3.125rem; }
  .user-intro__name {
    display: block;
    color: #011B34;
    font-weight: 700; }
    .user-intro__name > a {
      color: #011B34;
      font-weight: 700;
      text-decoration: underline; }
    .user-intro__name + .user-intro__link {
      margin-top: .5rem; }

.user-review {
  border-radius: 0.5rem;
  padding: 2rem 1rem;
  text-align: center; }
  .user-review__content {
    max-width: 21.875rem;
    margin: 0 auto; }
  .user-review__quote {
    margin-bottom: 0; }

.press-review {
  padding: 0 2rem;
  text-align: center; }
  .press-review__short-quote {
    display: block;
    color: #011B34;
    color: var(--dark);
    margin-bottom: 1.5rem; }
    .press-review__short-quote:before {
      display: none; }
  .press-review__quote {
    margin-bottom: 0; }
  .press-review__link {
    margin-top: 1rem;
    display: inline-block;
    word-break: break-all; }
  .press-review__logo {
    margin-top: 1.5rem; }
    .press-review__logo > img {
      margin: auto; }

.cta-group {
  text-align: center; }
  .cta-group .btn {
    margin: .5rem auto; }
  @media (min-width: 576px) {
    .cta-group .btn {
      display: inline-block;
      margin: .5rem .2rem;
      width: auto;
      min-width: 240px;
      max-width: 100%; } }
  @media (min-width: 768px) {
    .cta-group .btn {
      min-width: 266px; } }

.list-logos {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 1199.98px) {
    .list-logos__logo:not(:last-child) {
      margin-right: 2rem; } }
  @media (max-width: 767.98px) {
    .list-logos {
      flex-wrap: wrap;
      justify-content: center; }
      .list-logos__logo {
        margin: .5rem;
        max-width: 50%;
        flex-basis: 40%;
        text-align: center; }
        .list-logos__logo:not(:last-child) {
          margin: 1rem .5rem; } }

.list-icons__item {
  padding-left: 3.875rem;
  padding-top: 0.3125rem;
  margin: 1rem 0;
  background-image: url(../images/icons/icon-question.svg);
  background-repeat: no-repeat;
  background-position: 0 .2rem;
  background-size: 2.25rem 2.25rem; }
  .list-icons__item:not(:last-child) {
    margin-bottom: 3rem; }

.list-icons__title {
  color: #011B34;
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 2rem; }

@media (max-width: 1199.98px) {
  .list-icons__item {
    background-size: 1.5rem 1.5rem;
    padding-left: 2.5rem;
    background-position: 0 .5rem; }
  .list-icons__title {
    font-size: 1.25rem;
    margin-bottom: 1rem; } }

.go-top {
  text-align: right;
  position: absolute;
  right: 56px;
  top: -154px;
  display: flex;
  flex-direction: column;
  gap: 24px; }
  @media (max-width: 991.98px) {
    .go-top {
      right: 8px; } }
  .go-top a {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    line-height: 1;
    cursor: pointer;
    border: 0; }
    .go-top a:hover {
      border: 0; }
    .go-top a:focus {
      box-shadow: none; }
  .go-top__btn_arrow {
    background-color: #00154E;
    color: #FFFFFF; }
    .go-top__btn_arrow:hover {
      background-color: #026DCE;
      border-color: unset;
      color: #FFFFFF; }
  .go-top__btn_contact {
    background-color: #009BFF; }
    .go-top__btn_contact:hover {
      background-color: #007BCA;
      border-color: unset; }

.navbar-main-toggler {
  position: absolute;
  left: 16px;
  border: 1px solid transparent;
  padding: 0;
  background: transparent;
  color: #FFFFFF;
  font-size: 1.75rem;
  line-height: 1;
  cursor: pointer;
  z-index: 1031; }
  .navbar-main-toggler:focus {
    outline: 0; }
  .navbar-main-toggler .icon-close-thin {
    display: none;
    color: #009BFF; }
  .navbar-main-toggler.active .icon-close-thin {
    display: inline-block; }
  .navbar-main-toggler.active .icon-bars-alt {
    display: none; }
  @media (min-width: 992px) {
    .navbar-main-toggler {
      display: none; } }

.dropdown__arrow {
  display: inline-block;
  transition: all 0.2s ease-in-out; }

.dropdown.show .dropdown__arrow {
  transform: rotateX(180deg); }

@media (min-width: 992px) {
  .dropdown--hover-lg:hover .dropdown-menu {
    display: block; }
  .dropdown--hover-lg:hover .dropdown__arrow {
    transform: rotateX(180deg); } }

.dropdown--divided .dropdown-item:not(:last-of-type) {
  border-bottom: 1px solid #E0E0E0; }

.dropdown--nosize .dropdown-menu {
  min-width: auto; }

.dropdown--md .dropdown-menu {
  min-width: 16rem; }

.dropdown--alt .dropdown-menu {
  padding: 0;
  background-color: #FBFBFB; }

.dropdown--alt .dropdown-item {
  padding: .5rem 1rem;
  pointer-events: none;
  font-size: 0.75rem; }
  .dropdown--alt .dropdown-item:not(:last-child) {
    border-bottom: 1px solid #E0E0E0; }

.navbar-main {
  transition: all 0.2s ease-in-out;
  position: fixed;
  background: #373737;
  top: 9rem;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden; }
  .navbar-main::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .navbar-main::-webkit-scrollbar-track {
    display: none; }
  .navbar-main::-webkit-scrollbar-thumb {
    background: gray("300"); }
  .navbar-main .go-to-platform > .btn {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 2.5rem; }
  .navbar-main__mobile_entity {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    font-size: 14px;
    padding-left: 16px;
    margin-bottom: 19px; }
    .navbar-main__mobile_entity span {
      margin-bottom: 19px;
      font-weight: 600; }
    .navbar-main__mobile_entity a {
      font-weight: 800;
      color: #FFFFFF; }
      .navbar-main__mobile_entity a:active, .navbar-main__mobile_entity a:hover, .navbar-main__mobile_entity a[aria-selected="true"] {
        color: #009BFF;
        font-weight: 700; }
      .navbar-main__mobile_entity a:hover {
        text-decoration: none;
        cursor: pointer; }
    .navbar-main__mobile_entity__links {
      display: flex;
      align-items: center;
      gap: 8px; }
      .navbar-main__mobile_entity__links_link {
        padding: 0; }
      .navbar-main__mobile_entity__links span {
        margin-bottom: 0; }
  .navbar-main__mobile_links {
    display: flex;
    padding-left: 16px;
    position: relative;
    list-style: none; }
    .navbar-main__mobile_links::before {
      content: "";
      position: absolute;
      top: 0;
      height: 1px;
      width: 100%;
      background-color: #C4C4C4; }
    .navbar-main__mobile_links_link {
      padding: 16px 24px;
      text-transform: uppercase; }
      .navbar-main__mobile_links_link:first-of-type {
        padding-left: 8px; }
      .navbar-main__mobile_links_link:last-of-type {
        padding-right: 8px; }
      .navbar-main__mobile_links_link a {
        text-decoration: none;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 600; }
        .navbar-main__mobile_links_link a:active, .navbar-main__mobile_links_link a:hover, .navbar-main__mobile_links_link a[aria-selected="true"] {
          color: #009BFF; }
        .navbar-main__mobile_links_link a .icon {
          margin-right: 12px; }
  .navbar-main__nav {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 29px; }
  .navbar-main__link {
    font-weight: 500;
    transition: all 0.2s ease-in-out; }
  .navbar-main__regulator-entity-selector {
    padding: 0;
    display: inline-flex;
    position: relative;
    border-top: 0;
    line-height: 3.5rem; }
    @media (max-width: 991.98px) {
      .navbar-main__regulator-entity-selector {
        display: none; } }

@media (min-width: 992px) {
  .navbar-main-mobile {
    display: none; } }

.login-signup, .login-signup-mobile {
  display: flex; }
  @media (max-width: 991.98px) {
    .login-signup, .login-signup-mobile {
      display: none; } }
  .login-signup .btn, .login-signup-mobile .btn {
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 21.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 160px; }
  .login-signup .btn:last-of-type, .login-signup-mobile .btn:last-of-type {
    margin-left: 24px; }

.login-signup-mobile {
  display: none; }
  @media (max-width: 991.98px) {
    .login-signup-mobile {
      display: flex;
      padding-left: 13px;
      padding-right: 7px;
      justify-content: center; }
      .login-signup-mobile .btn {
        flex: unset; }
      .login-signup-mobile .btn:first-of-type {
        margin-right: 5.5px; }
      .login-signup-mobile .btn:last-of-type {
        margin-left: 5.5px; } }

.dropdown-menu_multilist {
  display: flex;
  gap: 40px; }
  .dropdown-menu_multilist_container:first-of-type {
    min-width: 155px; }
  .dropdown-menu_multilist_container:last-of-type {
    margin-right: 0; }
  .dropdown-menu_multilist_title {
    font-size: 16px;
    color: #979797;
    font-weight: 600;
    position: relative;
    margin-bottom: 28px; }
    .dropdown-menu_multilist_title::after {
      content: "";
      position: absolute;
      bottom: -14px;
      display: block;
      background-color: #979797;
      width: 100%;
      height: 1px; }
  .dropdown-menu_multilist .dropdown-item {
    padding: 0; }

@media (max-width: 991.98px) {
  .navbar-main {
    transform: translateX(-100%); }
    .has-sidebar-navbar-open .navbar-main {
      transform: translateX(-9px);
      padding-left: 9px; }
    .navbar-main .dropdown.show .dropdown-toggle {
      color: #026DCE;
      background-color: #010e1b; }
    .navbar-main .dropdown .dropdown-menu {
      margin: 0; }
    .navbar-main .dropdown--icon .dropdown-item {
      padding: 1rem 1.25rem 1rem 3.5rem; }
    .navbar-main .dropdown-menu {
      position: static;
      float: none;
      border: 0; }
    .navbar-main .menu-item {
      padding-left: 16px;
      margin-bottom: 6px;
      border: none; }
      .navbar-main .menu-item:last-of-type {
        margin-bottom: 0; }
      .navbar-main .menu-item__link {
        background: #4A4A4A;
        padding: 12px;
        font-size: 14px; }
        .navbar-main .menu-item__link:hover {
          background: #272727;
          color: #FFFFFF; } }

@media (min-width: 1200px) {
  .navbar-main {
    width: 60%; } }

@media (min-width: 992px) {
  .navbar-main {
    position: static;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    background-color: transparent;
    overflow: visible;
    justify-content: space-evenly;
    height: 100%; }
    .navbar-main__logo {
      display: inline-flex;
      align-items: center; }
    .navbar-main__nav {
      display: inline-flex;
      flex: 1;
      justify-content: space-between; }
    .navbar-main__item {
      display: inline-flex;
      position: relative;
      border-top: 0;
      line-height: 3.5rem;
      transition: all 0.2s ease-in-out;
      background-position: bottom;
      background-size: 100% 0; }
      .navbar-main__item.is-active > .navbar-main__link, .navbar-main__item:hover > .navbar-main__link {
        color: #009BFF; }
      .navbar-main__item:hover .submenu {
        display: block; }
    .navbar-main__link {
      padding: 0px 0px;
      display: inline-flex;
      align-items: flex-end;
      color: #FFFFFF;
      font-size: 14px; }
      .navbar-main__link .icon-globe-framework {
        font-size: 1.25rem; }
      .navbar-main__link:hover, .navbar-main__link:focus {
        background-color: #353535;
        color: #026DCE;
        text-decoration: none; }
      .navbar-main__link:active {
        color: #FFFFFF; }
    .navbar-main__label {
      padding: 0px 13px;
      padding-bottom: 16px;
      line-height: 1; }
    .navbar-main .menu-item__arrow {
      display: none; }
    .navbar-main .go-to-platform > .btn {
      line-height: 2.1875rem; }
    .navbar-main .dropdown-menu {
      margin-left: 0;
      background-color: #4a4a4a;
      border: 0;
      padding: 24px 12px !important; }
      .is-scrolling .navbar-main .dropdown-menu {
        background-color: #4a4a4a; }
      .navbar-main .dropdown-menu.dropdown-menu-right {
        margin-left: 0;
        margin-right: -1rem; }
    .navbar-main .dropdown-item {
      color: white;
      font-weight: 500;
      margin-top: 1rem;
      margin-bottom: 1rem; }
      .navbar-main .dropdown-item:first-of-type {
        margin-top: 0; }
      .navbar-main .dropdown-item:last-of-type {
        margin-bottom: 0; }
      .navbar-main .dropdown-item:hover, .navbar-main .dropdown-item:focus {
        color: #026DCE;
        background-color: transparent; } }

.submenu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #373737;
  transition: all 0.2s ease-in-out;
  z-index: 1030;
  color: #FFFFFF;
  font-size: 1rem;
  overflow: auto; }
  .submenu::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .submenu::-webkit-scrollbar-track {
    display: none; }
  .submenu::-webkit-scrollbar-thumb {
    background: gray("300"); }
  .submenu__container .nav-secondary__item {
    box-shadow: none;
    margin-bottom: 34px;
    display: flex;
    flex-direction: column;
    padding-left: 9px; }
    .submenu__container .nav-secondary__item::before {
      display: none; }
    .submenu__container .nav-secondary__item_label {
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
      padding: 14px 0;
      background-color: #272727;
      padding-left: 23px; }
  .submenu__container .nav-secondary__link {
    justify-content: flex-start; }
    .submenu__container .nav-secondary__link .menu-item__start {
      position: relative;
      top: unset;
      left: unset;
      margin-right: 12px;
      padding-left: 20px; }
  .submenu__container .nav-secondary .dropdown-menu_multilist {
    display: flex;
    flex-direction: column;
    padding-left: 35px; }
    .submenu__container .nav-secondary .dropdown-menu_multilist_title {
      font-size: 14px;
      font-weight: 600;
      color: #979797;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 12px; }
      .submenu__container .nav-secondary .dropdown-menu_multilist_title::after {
        content: "";
        width: 158px;
        margin-bottom: 8px; }
  .submenu__container .nav-secondary .menu-item__link {
    background: none;
    padding-left: 0; }
    .submenu__container .nav-secondary .menu-item__link:hover {
      color: #026DCE; }
    .submenu__container .nav-secondary .menu-item__link_container:hover {
      background: none; }
    .submenu__container .nav-secondary .menu-item__link_simple {
      background: none;
      padding-left: 8px;
      color: #FFFFFF;
      margin-bottom: 16px; }
      .submenu__container .nav-secondary .menu-item__link_simple:last-of-type {
        margin-bottom: 0; }
      .submenu__container .nav-secondary .menu-item__link_simple:hover {
        color: #026DCE; }
  .submenu__cta-block {
    display: none; }
  .submenu.is-open {
    display: block; }
  @media (min-width: 992px) {
    .submenu {
      position: fixed;
      top: 3.5rem;
      left: 0;
      right: auto;
      bottom: auto;
      width: 100%;
      padding: 1rem 0 3rem;
      z-index: 1030;
      background-color: rgba(3, 25, 56, 0.95); }
      .submenu .nav-secondary {
        width: 100%; }
      .submenu__container {
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
        display: flex;
        justify-content: space-between; }
      .submenu__cta-block {
        width: 15rem; }
      .submenu--dropdown {
        position: absolute;
        left: 0;
        top: 3.5rem;
        margin-top: 1rem;
        z-index: 1031;
        min-width: 14rem; }
      .is-scrolling .submenu {
        background-color: #011B34; } }
  @media (min-width: 1200px) {
    .submenu .nav-secondary {
      width: 55rem; }
    .submenu__cta-block {
      display: block; } }
  @media (max-width: 991.98px) {
    .submenu {
      display: block;
      transform: translateX(-100%); }
      .submenu.is-open {
        transform: translateX(0); } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    .has-submenu-dropdown-lg .submenu {
      position: absolute;
      left: 0;
      min-width: 11rem;
      padding: 0; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .submenu--dropdown-lg {
      position: absolute;
      left: 0;
      top: 100%;
      min-width: 11rem;
      padding: 0;
      background-color: rgba(3, 25, 56, 0.95); }
      .submenu--dropdown-lg .nav-secondary .menu-item__link {
        padding: 1rem;
        margin: 0;
        position: relative; }
        .submenu--dropdown-lg .nav-secondary .menu-item__link:hover, .submenu--dropdown-lg .nav-secondary .menu-item__link:focus {
          background-color: rgba(3, 25, 56, 0.95);
          color: #026DCE; }
          .submenu--dropdown-lg .nav-secondary .menu-item__link:hover:before, .submenu--dropdown-lg .nav-secondary .menu-item__link:focus:before {
            content: '';
            width: 5px;
            background-color: #026DCE;
            position: absolute;
            left: 0;
            top: 1rem;
            bottom: 1rem; }
      .submenu--dropdown-lg .submenu__container {
        padding: 0;
        display: block; } }

.nav-secondary {
  overflow: auto; }
  .nav-secondary__title {
    display: block;
    text-transform: uppercase;
    margin-bottom: 0; }
  .nav-secondary__toggler {
    text-transform: uppercase;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4); }
    .nav-secondary__toggler.menu-item.is-active {
      background-color: transparent; }
    .nav-secondary__toggler .nav-secondary__link {
      min-height: 4rem; }
    .nav-secondary__toggler.menu-item .icon-arrow-left-thin, .nav-secondary__toggler.menu-item .icon-arrow-right-thin {
      line-height: 2rem; }
  .nav-secondary .menu-item__link {
    padding-left: 3.5rem;
    transition: all 0.2s ease-in-out; }
  @media (min-width: 992px) {
    .nav-secondary {
      display: flex;
      overflow: visible; }
      .nav-secondary__toggler {
        display: none; }
      .nav-secondary__item {
        flex: 1;
        padding: 0; }
        .nav-secondary__item:not(:last-child) {
          margin-right: 2rem; }
      .nav-secondary__title {
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 1rem;
        border-bottom: 2px solid white; }
      .nav-secondary__link {
        display: block;
        color: #FFFFFF; }
        .nav-secondary__link:hover, .nav-secondary__link:focus {
          text-decoration: none;
          color: #009BFF; }
        .nav-secondary__link.nav-secondary__title:hover, .nav-secondary__link.nav-secondary__title:focus {
          text-decoration: none;
          color: #FFFFFF; }
      .nav-secondary .menu-item__link {
        padding: 0; }
        .nav-secondary .menu-item__link.nav-secondary__title {
          padding-bottom: .75rem; }
      .nav-secondary__link:not(:last-child) {
        margin-bottom: 1.5rem; }
      .nav-secondary .dropdown__arrow {
        display: none; } }
  @media (min-width: 1200px) {
    .nav-secondary .menu-item__link.nav-secondary__title {
      padding-bottom: 0; }
    .nav-secondary__title {
      min-height: 3rem; } }
  @media (max-width: 991.98px) {
    .nav-secondary__dropdown-menu {
      display: none; } }
  @media (max-width: 767.98px) {
    .nav-secondary .menu-item__link {
      padding-left: 2.5rem; }
    .nav-secondary .menu-item__start {
      left: 1rem; } }

.menu-item.dropdown .dropdown-toggle:after {
  display: none; }

.menu-item.dropdown .dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 1px;
  min-width: unset; }

.menu-item.dropdown .dropdown-item {
  position: relative;
  transition: all 0.2s ease-in-out;
  padding: 0; }
  .menu-item.dropdown .dropdown-item:before {
    content: '';
    width: 0;
    background-color: #026DCE;
    position: absolute;
    left: 0;
    top: 1rem;
    bottom: 1rem;
    transition: all 0.2s ease-in-out; }
  .menu-item.dropdown .dropdown-item:hover:before, .menu-item.dropdown .dropdown-item:focus:before {
    width: 8px;
    top: 0;
    height: 100%;
    left: -12px; }

.menu-item__arrow {
  display: none; }

.menu-item .icon-arrow-left-thin, .menu-item .icon-arrow-right-thin {
  font-size: 0.8125rem;
  line-height: 1.125rem; }

@media (max-width: 991.98px) {
  .menu-item {
    display: block;
    justify-content: space-between;
    position: relative;
    color: #FFFFFF;
    border-top: 1px solid #052847; }
    .menu-item:hover, .menu-item:focus {
      color: #026DCE;
      outline: 0; }
      .menu-item:hover > a, .menu-item:focus > a {
        color: #026DCE; }
    .menu-item.is-active {
      color: #026DCE;
      background-color: #010e1b; }
      .menu-item.is-active:before {
        content: '';
        width: 5px;
        background-color: #026DCE;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0; }
      .menu-item.is-active > .menu-item__link {
        color: #026DCE; }
    .menu-item.dropdown .dropdown-menu {
      margin: 0; }
    .menu-item.dropdown.show {
      background-color: #010e1b; }
      .menu-item.dropdown.show .dropdown-toggle {
        color: #026DCE;
        background-color: #010e1b; }
    .menu-item.dropdown .dropdown-item:hover:before, .menu-item.dropdown .dropdown-item:focus:before {
      display: none; }
    .menu-item--dropdown {
      cursor: pointer; }
      .menu-item--dropdown.is-open.is-open {
        background-color: #010e1b; }
        .menu-item--dropdown.is-open.is-open .nav-secondary__dropdown-toggle {
          color: #026DCE; }
        .menu-item--dropdown.is-open.is-open .dropdown-menu, .menu-item--dropdown.is-open.is-open .menu-item__dropdown-menu {
          display: block; }
      .menu-item--dropdown.is-open .dropdown__arrow {
        transform: rotateX(180deg); }
      .menu-item--dropdown .dropdown-menu, .menu-item--dropdown .menu-item__dropdown-menu {
        display: none;
        margin: 0; }
    .menu-item__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 2rem;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      color: white;
      font-size: 1rem; }
      .menu-item__link:hover, .menu-item__link:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: none;
        background-color: #353535;
        color: #026DCE; }
    .menu-item--subitem .menu-item__link {
      padding-left: 3.5rem; }
    .menu-item__start {
      position: absolute;
      top: 1rem;
      left: 2rem; }
    .menu-item__arrow {
      display: block; } }

@media (max-width: 767.98px) {
  .menu-item__link {
    padding-left: 1rem;
    padding-right: 1rem; }
  .menu-item--subitem .menu-item__link {
    padding-left: 2.5rem; }
  .menu-item--subitem .menu-item__start {
    left: 1rem; } }

.grid-checkboxes__item {
  flex-basis: 50%;
  padding-left: 1.8rem;
  padding-top: 0.3125rem;
  margin: 1rem 0;
  background-image: url(../images/icons/icon-checked.svg);
  background-repeat: no-repeat;
  background-position: 0 .2rem;
  background-size: 1.25rem 1.25rem; }

.grid-checkboxes__title {
  color: #011B34;
  font-weight: 500; }

@media (min-width: 768px) {
  .grid-checkboxes {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem; } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
    .grid-checkboxes {
      margin-right: -2rem;
      margin-bottom: -2rem; }
      .grid-checkboxes > * {
        margin-right: 2rem;
        margin-bottom: 2rem; } }

@media (min-width: 768px) {
    .grid-checkboxes__item {
      padding-top: 0.1875rem;
      margin: 0; }
      .grid-checkboxes__item:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-column-span: 2-1;
        -ms-grid-row: 1;
        -ms-grid-row-span: 2-1;
        grid-column: 1/2;
        grid-row: 1/2; }
      .grid-checkboxes__item:nth-child(2) {
        -ms-grid-column: 2;
        -ms-grid-column-span: 3-2;
        -ms-grid-row: 1;
        -ms-grid-row-span: 2-1;
        grid-column: 2/3;
        grid-row: 1/2; }
      .grid-checkboxes__item:nth-child(3) {
        -ms-grid-column: 1;
        -ms-grid-column-span: 2-1;
        -ms-grid-row: 2;
        -ms-grid-row-span: 3-2;
        grid-column: 1/2;
        grid-row: 2/3; }
      .grid-checkboxes__item:nth-child(4) {
        -ms-grid-column: 2;
        -ms-grid-column-span: 3-2;
        -ms-grid-row: 2;
        -ms-grid-row-span: 3-2;
        grid-column: 2/3;
        grid-row: 2/3; } }

.chart-circle-outline {
  position: relative;
  max-width: 14rem;
  margin-left: auto;
  margin-right: auto; }
  .chart-circle-outline__caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3rem; }
  .chart-circle-outline__amount {
    font-size: 3rem;
    line-height: 1;
    margin-bottom: .5rem;
    display: block; }
  .chart-circle-outline__text {
    display: block;
    margin-bottom: 0;
    font-size: 0.875rem; }

.card, .card-listitems, .r-calculator__result {
  box-shadow: 0 0 9px 0 rgba(156, 156, 156, 0.5); }
  .card--border-radius-sm {
    border-radius: 0.25rem; }
  .card--dark {
    background-color: #011B34;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.5); }
  .card--gray {
    background-color: #FCFCFC; }

.card-deck--sm {
  max-width: 665px;
  margin: auto; }
  .card-deck--sm .card, .card-deck--sm .card-listitems, .card-deck--sm .r-calculator__result {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.card-deck--numbered .card, .card-deck--numbered .card-listitems, .card-deck--numbered .r-calculator__result {
  position: relative;
  counter-increment: item; }
  .card-deck--numbered .card:before, .card-deck--numbered .card-listitems:before, .card-deck--numbered .r-calculator__result:before {
    content: counter(item);
    color: #011B34;
    width: 1.875rem;
    height: 1.875rem;
    font-size: 1.25rem;
    border-radius: 50%;
    border: 1px solid #011B34;
    display: flex;
    justify-content: center;
    margin: 1rem auto 0; }

@media (min-width: 576px) {
  .card-deck--responsive {
    flex-direction: column; }
    .card-deck--responsive .card, .card-deck--responsive .card-listitems, .card-deck--responsive .r-calculator__result {
      margin-bottom: 1rem; }
  .card-deck--responsive-4 {
    flex-flow: wrap row; }
    .card-deck--responsive-4 .card, .card-deck--responsive-4 .card-listitems, .card-deck--responsive-4 .r-calculator__result {
      flex-basis: calc(50% - 2rem);
      margin-bottom: 2rem; } }

@media (min-width: 768px) {
  .card-deck--responsive {
    flex-flow: wrap row; }
    .card-deck--responsive .card, .card-deck--responsive .card-listitems, .card-deck--responsive .r-calculator__result {
      margin-bottom: 0; } }

@media (min-width: 992px) {
  .card-deck--responsive-4 .card, .card-deck--responsive-4 .card-listitems, .card-deck--responsive-4 .r-calculator__result {
    flex-basis: calc(25% - 2rem);
    margin-bottom: 0; } }

@media (min-width: 1200px) {
  .card-deck--numbered .card:before, .card-deck--numbered .card-listitems:before, .card-deck--numbered .r-calculator__result:before {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    border-width: 2px; } }

.warning-text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-weight: 500; }
  .warning-text__img {
    margin-right: 1rem;
    margin-top: .2rem; }
  @media (max-width: 767.98px) {
    .warning-text--centered-sm {
      text-align: center; }
      .warning-text--centered-sm .warning-text__text:before {
        content: '*'; }
      .warning-text--centered-sm .warning-text__img {
        margin-bottom: .5rem;
        display: none; } }

.claim-and-button {
  display: flex;
  align-items: center;
  justify-content: center; }
  .claim-and-button__text {
    font-weight: 500; }
  .claim-and-button__btn {
    margin-left: 1rem; }
  @media (max-width: 767.98px) {
    .claim-and-button {
      flex-direction: column; }
      .claim-and-button__btn {
        margin-left: 0;
        margin-top: 2rem; } }

.legend-colors {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0; }
  .legend-colors__color {
    font-size: 0.75rem;
    display: inline-flex;
    align-items: center;
    margin: 0 .3rem; }
    .legend-colors__color:before {
      content: '';
      display: inline-block;
      margin-right: .3rem;
      height: 5px;
      width: 26px;
      background-color: #9B9B9B;
      background-color: var(--gray); }
    .legend-colors__color.is--blue:before {
      background-color: #007BBA;
      background-color: var(--#007BBA); }
    .legend-colors__color.is--blue-light:before {
      background-color: #8BB3DA;
      background-color: var(--#8BB3DA); }
    .legend-colors__color.is--gray:before {
      background-color: #9B9B9B;
      background-color: var(--#9B9B9B); }
    .legend-colors__color.is--red:before {
      background-color: #9F3535;
      background-color: var(--#9F3535); }
    .legend-colors__color.is--yellow:before {
      background-color: #F1CC30;
      background-color: var(--#F1CC30); }
    .legend-colors__color.is--yellow-dark:before {
      background-color: #E0A335;
      background-color: var(--#E0A335); }
    .legend-colors__color.is--green:before {
      background-color: #87A727;
      background-color: var(--#87A727); }

.chart {
  position: relative;
  font-size: 0.75rem;
  text-align: center; }
  .chart__box {
    position: relative;
    padding-left: 1.5rem; }
  .chart__legend-axis-y {
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(-180deg);
    writing-mode: tb-rl;
    height: 100%; }
  .chart__img {
    width: 100%;
    height: auto;
    margin-bottom: .5rem; }
  .chart__legend-currencies {
    margin-top: 1rem; }
  @media (max-width: 991.98px) {
    .chart .legend-colors {
      justify-content: flex-start;
      align-items: flex-start; }
    .chart .legend-colors__color {
      line-height: 1.4;
      flex: 0 1 95px;
      margin: 0; }
      .chart .legend-colors__color:before {
        height: 7px; } }

.nav--fill .nav-item {
  width: 100%;
  margin-bottom: 0.5rem; }

@media (min-width: 576px) {
  .nav--fill {
    align-items: stretch;
    margin-left: -.5rem;
    margin-right: -.5rem; }
    .nav--fill .nav-item {
      flex: 50%;
      padding: .65rem .5rem;
      margin-bottom: 0; }
    .nav--fill .nav-link {
      height: 100%;
      display: flex;
      align-items: center; } }

@media (min-width: 768px) {
  .nav--fill .nav-item {
    flex: 1; } }

.nav-pills .nav-item--minwidth {
  min-width: 15rem; }

.nav-pills .nav-link {
  color: #011B34;
  box-shadow: 0 0 4px 0 rgba(156, 156, 156, 0.5);
  border-radius: 0;
  padding: 1rem;
  margin: .2rem;
  transition: all 0.2s ease-in-out;
  background-size: 100% 0;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: linear-gradient(#026DCE, #026DCE); }
  .nav-pills .nav-link.active, .nav-pills .nav-link .show > .nav-link {
    background-size: 100% 4px; }
  .nav-pills .nav-link:hover, .nav-pills .nav-link:focus, .nav-pills .nav-link:active {
    background-color: #FBFBFB; }

.nav-pills--dark .nav-link {
  background-image: linear-gradient(#011B34, #011B34); }
  .nav-pills--dark .nav-link.active, .nav-pills--dark .nav-link .show > .nav-link {
    color: #011B34; }
  .nav-pills--dark .nav-link:hover, .nav-pills--dark .nav-link:focus, .nav-pills--dark .nav-link:active {
    background-color: #FBFBFB; }

.nav-pills--group {
  box-shadow: 0 0 9px 0 rgba(156, 156, 156, 0.5); }
  .nav-pills--group .nav-item:not(:last-child) {
    border-right: 1px solid #E0E0E0; }
  .nav-pills--group .nav-link {
    margin: 0;
    box-shadow: none; }

.nav-pills--alt .nav-link {
  color: #011B34;
  background-image: none; }

.nav-pills--alt .nav-link.active,
.nav-pills--alt .show > .nav-link {
  background-position: bottom;
  color: #FFFFFF;
  background: #011B34; }

.nav-pills--primary .nav-link {
  color: #011B34;
  background-image: none; }

.nav-pills--primary .nav-link.active,
.nav-pills--primary .show > .nav-link {
  background-position: bottom;
  color: #FFFFFF;
  background: #026DCE; }

@media (max-width: 767.98px) {
  .nav-pills--xs-flex-1 .nav-item {
    flex: 1;
    text-align: center; } }

@media (max-width: 449.98px) {
  .nav-pills {
    display: block; } }

.table-scrollable {
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.24);
  max-height: calc(100vh - 3.5rem - 6rem);
  overflow-x: auto;
  /* Firefox Only*/
  scrollbar-color: #E0E0E0 transparent;
  scrollbar-width: thin;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .table-scrollable _:-ms-lang(x), .table-scrollable {
    box-shadow: none;
    border: 1px solid #E0E0E0; }
  .table-scrollable::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .table-scrollable::-webkit-scrollbar-track {
    background: transparent; }
  .table-scrollable::-webkit-scrollbar-thumb {
    background: #E0E0E0; }
  .table-scrollable::-webkit-scrollbar-thumb:hover {
    background: #979797; }
  .table-scrollable tr {
    height: 3.5rem; }
  .table-scrollable td, .table-scrollable th {
    padding-top: .25rem;
    padding-bottom: .25rem;
    vertical-align: middle;
    white-space: nowrap; }
  .table-scrollable thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border: 0;
    z-index: 1; }
  .table-scrollable thead th:first-child {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2; }
  .table-scrollable tr td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    box-shadow: 4px 3px 10px 0px rgba(0, 0, 0, 0.1);
    transform: translateX(-2px);
    background-color: #f6f6f6;
    border-color: transparent;
    z-index: 1; }

.table-sticky-scroll table td, .table-sticky-scroll table th {
  width: 33%;
  text-align: left; }

.table-sticky-scroll__header {
  padding: 0 1.325rem 0 1rem;
  border-bottom: 1px solid #e7e7e7;
  box-shadow: 0 5px 6px 0px rgba(0, 0, 0, 0.2); }
  .table-sticky-scroll__header th {
    vertical-align: top; }

.table-sticky-scroll__content {
  padding-top: .5rem;
  padding-bottom: .5rem; }

.table-sticky-scroll .scroller-nano {
  margin: 0 1rem; }

@media (max-width: 767.98px) {
  .table-sticky-scroll td:first-child, .table-sticky-scroll th:first-child {
    min-width: 120px; }
  .table-sticky-scroll__header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 0 1rem;
    border-bottom: 1px solid #e7e7e7; }
    .table-sticky-scroll__header th {
      background-color: #FFFFFF;
      padding-top: .5rem;
      padding-bottom: .5rem; }
  .modal .table-sticky-scroll .scroller-nano {
    height: auto;
    overflow: visible; } }

.list-group-item {
  background-image: linear-gradient(#026DCE, #026DCE);
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-position: left;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out; }
  .list-group-item.active {
    background-size: 4px 100%;
    box-shadow: inset 0 0 2px 1px rgba(158, 158, 158, 0.5); }
    .list-group-item.active:first-of-type {
      box-shadow: inset 0 -1px 2px 0 rgba(158, 158, 158, 0.5); }
    .list-group-item.active:last-of-type {
      box-shadow: inset 0 1px 2px 0 rgba(158, 158, 158, 0.5); }
  @media (min-width: 992px) {
    .list-group-item.active {
      box-shadow: inset 0 0 3px 1px rgba(158, 158, 158, 0.5); }
      .list-group-item.active:first-of-type {
        box-shadow: inset 0 -2px 3px 0 rgba(158, 158, 158, 0.5); }
      .list-group-item.active:last-of-type {
        box-shadow: inset 0 2px 3px 0 rgba(158, 158, 158, 0.5); } }

.navbar-fixed__toggler {
  display: none;
  background-color: #026DCE;
  height: 3.5rem;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.25rem;
  padding: 0 1rem; }
  .navbar-fixed__toggler.collapsed .navbar-fixed__icon [class^="icon-"]:before, .navbar-fixed__toggler.collapsed .navbar-fixed__icon [class*=" icon-"]:before {
    content: "\e99b"; }

.navbar-fixed__icon {
  width: 1.875rem;
  margin-left: auto;
  font-size: 1.875rem;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%); }

.navbar-fixed__content {
  max-height: calc(100vh - 275px);
  overflow-y: auto;
  /* Firefox Only*/
  scrollbar-color: #E0E0E0 transparent;
  scrollbar-width: thin;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .navbar-fixed__content::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .navbar-fixed__content::-webkit-scrollbar-track {
    background: transparent; }
  .navbar-fixed__content::-webkit-scrollbar-thumb {
    background: #E0E0E0; }
  .navbar-fixed__content::-webkit-scrollbar-thumb:hover {
    background: #979797; }

@media (max-width: 991.98px) {
  .navbar-fixed {
    position: fixed;
    top: 3.5rem;
    left: 0;
    right: 0;
    z-index: 1018; }
    .navbar-fixed__toggler {
      display: flex;
      position: relative;
      font-size: 1rem; }
    .navbar-fixed__content {
      background-color: #FFFFFF; }
    .navbar-fixed .list-group-item {
      text-align: center;
      font-size: 1rem; } }

@media (max-width: 575.98px) {
  .navbar-fixed__toggler {
    justify-content: space-between;
    padding: 0 3.5rem 0 1rem; } }

@media (min-width: 992px) {
  .navbar-fixed__items {
    box-shadow: 0 0 5px 0 rgba(158, 158, 158, 0.5);
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    /* Firefox Only*/
    scrollbar-color: #E0E0E0 transparent;
    scrollbar-width: thin;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .navbar-fixed__items::-webkit-scrollbar {
      width: 5px;
      height: 5px; }
    .navbar-fixed__items::-webkit-scrollbar-track {
      background: transparent; }
    .navbar-fixed__items::-webkit-scrollbar-thumb {
      background: #E0E0E0; }
    .navbar-fixed__items::-webkit-scrollbar-thumb:hover {
      background: #979797; }
  .navbar-fixed__content {
    max-height: 100%;
    overflow-y: visible; }
    .navbar-fixed__content.collapse {
      display: block; }
  .navbar-fixed--sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 6rem; } }

.scroller-nano {
  overflow-y: scroll;
  /* Firefox Only*/
  scrollbar-color: #E0E0E0 transparent;
  scrollbar-width: thin;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .scroller-nano::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .scroller-nano::-webkit-scrollbar-track {
    background: transparent; }
  .scroller-nano::-webkit-scrollbar-thumb {
    background: #E0E0E0; }
  .scroller-nano::-webkit-scrollbar-thumb:hover {
    background: #979797; }
  .scroller-nano--height-1 {
    height: 100px; }
  .scroller-nano--height-2 {
    height: 200px; }
  .scroller-nano--height-3 {
    height: 300px; }
  .scroller-nano--height-4 {
    height: 400px; }
  .scroller-nano--height-5 {
    height: 500px; }
  .scroller-nano__top-shadow td {
    padding: 0; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background-color: rgba(24, 48, 70, 0.13); }

.embed-container:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "darwinex" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e970";
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  opacity: 1; }

.embed-container iframe, .embed-container object, .embed-container embed, .embed-container video {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.list-timeline {
  padding-left: 2.5rem;
  position: relative; }
  .list-timeline:before {
    content: "";
    width: 4px;
    background-color: #026DCE;
    position: absolute;
    top: 5px;
    bottom: 0;
    left: 1rem;
    margin-left: -2px; }
  .list-timeline__item {
    margin-bottom: 1.5rem;
    position: relative; }
    .list-timeline__item:after {
      content: '';
      width: 12px;
      height: 12px;
      background-color: #026DCE;
      border-radius: 50%;
      position: absolute;
      left: -30px;
      top: 4px; }
    .list-timeline__item:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #026DCE;
      opacity: .3;
      border-radius: 50%;
      left: -34px;
      top: 0; }
  .list-timeline__text {
    color: #011B34;
    font-weight: 500; }
  @media (min-width: 992px) {
    .list-timeline {
      display: flex;
      padding: 0 2rem;
      height: 19.625rem; }
      .list-timeline .list-timeline__title {
        margin-bottom: 1rem; }
      .list-timeline:before {
        height: 4px;
        top: 50%;
        margin-top: -2px;
        left: 8%;
        width: 84%; }
      .list-timeline__item {
        flex: 1;
        height: 9.8125rem;
        margin: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin-right: -2rem;
        margin-left: -2rem; }
        .list-timeline__item:after {
          width: 1rem;
          height: 1rem; }
        .list-timeline__item:before {
          width: 1.5rem;
          height: 1.5rem; }
        .list-timeline__item:nth-child(odd) {
          transform: translateY(100%);
          padding-top: 1.5rem; }
          .list-timeline__item:nth-child(odd):before {
            bottom: auto;
            top: -12px;
            left: 50%;
            margin-left: -1rem; }
          .list-timeline__item:nth-child(odd):after {
            bottom: auto;
            top: -8px;
            left: 50%;
            margin-left: -.75rem; }
        .list-timeline__item:nth-child(even) {
          padding-bottom: 1.5rem;
          justify-content: flex-end; }
          .list-timeline__item:nth-child(even):before {
            top: auto;
            bottom: -12px;
            left: 50%;
            margin-left: -1rem; }
          .list-timeline__item:nth-child(even):after {
            top: auto;
            bottom: -8px;
            left: 50%;
            margin-left: -.75rem; }
          .list-timeline__item:nth-child(even) .list-timeline__title {
            margin-bottom: 0;
            margin-top: 1rem;
            order: 1; }
      .list-timeline.is-animated:before {
        transition: 1.5s width ease-in; }
      .list-timeline.is-animated .list-timeline__item:nth-child(1) .list-timeline__title, .list-timeline.is-animated .list-timeline__item:nth-child(1) .list-timeline__text {
        transition: 0.5s opacity ease-in-out 0.125s, 0.5s transform ease-in-out 0.125s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(1):after, .list-timeline.is-animated .list-timeline__item:nth-child(1):before {
        transition: 0.5s opacity ease-in-out 0.125s, 0.5s transform ease-in-out 0.125s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(2) .list-timeline__title, .list-timeline.is-animated .list-timeline__item:nth-child(2) .list-timeline__text {
        transition: 0.5s opacity ease-in-out 0.25s, 0.5s transform ease-in-out 0.25s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(2):after, .list-timeline.is-animated .list-timeline__item:nth-child(2):before {
        transition: 0.5s opacity ease-in-out 0.25s, 0.5s transform ease-in-out 0.25s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(3) .list-timeline__title, .list-timeline.is-animated .list-timeline__item:nth-child(3) .list-timeline__text {
        transition: 0.5s opacity ease-in-out 0.375s, 0.5s transform ease-in-out 0.375s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(3):after, .list-timeline.is-animated .list-timeline__item:nth-child(3):before {
        transition: 0.5s opacity ease-in-out 0.375s, 0.5s transform ease-in-out 0.375s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(4) .list-timeline__title, .list-timeline.is-animated .list-timeline__item:nth-child(4) .list-timeline__text {
        transition: 0.5s opacity ease-in-out 0.5s, 0.5s transform ease-in-out 0.5s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(4):after, .list-timeline.is-animated .list-timeline__item:nth-child(4):before {
        transition: 0.5s opacity ease-in-out 0.5s, 0.5s transform ease-in-out 0.5s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(5) .list-timeline__title, .list-timeline.is-animated .list-timeline__item:nth-child(5) .list-timeline__text {
        transition: 0.5s opacity ease-in-out 0.625s, 0.5s transform ease-in-out 0.625s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(5):after, .list-timeline.is-animated .list-timeline__item:nth-child(5):before {
        transition: 0.5s opacity ease-in-out 0.625s, 0.5s transform ease-in-out 0.625s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(6) .list-timeline__title, .list-timeline.is-animated .list-timeline__item:nth-child(6) .list-timeline__text {
        transition: 0.5s opacity ease-in-out 0.75s, 0.5s transform ease-in-out 0.75s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(6):after, .list-timeline.is-animated .list-timeline__item:nth-child(6):before {
        transition: 0.5s opacity ease-in-out 0.75s, 0.5s transform ease-in-out 0.75s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(7) .list-timeline__title, .list-timeline.is-animated .list-timeline__item:nth-child(7) .list-timeline__text {
        transition: 0.5s opacity ease-in-out 0.875s, 0.5s transform ease-in-out 0.875s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(7):after, .list-timeline.is-animated .list-timeline__item:nth-child(7):before {
        transition: 0.5s opacity ease-in-out 0.875s, 0.5s transform ease-in-out 0.875s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(8) .list-timeline__title, .list-timeline.is-animated .list-timeline__item:nth-child(8) .list-timeline__text {
        transition: 0.5s opacity ease-in-out 1s, 0.5s transform ease-in-out 1s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(8):after, .list-timeline.is-animated .list-timeline__item:nth-child(8):before {
        transition: 0.5s opacity ease-in-out 1s, 0.5s transform ease-in-out 1s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(9) .list-timeline__title, .list-timeline.is-animated .list-timeline__item:nth-child(9) .list-timeline__text {
        transition: 0.5s opacity ease-in-out 1.125s, 0.5s transform ease-in-out 1.125s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(9):after, .list-timeline.is-animated .list-timeline__item:nth-child(9):before {
        transition: 0.5s opacity ease-in-out 1.125s, 0.5s transform ease-in-out 1.125s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(10) .list-timeline__title, .list-timeline.is-animated .list-timeline__item:nth-child(10) .list-timeline__text {
        transition: 0.5s opacity ease-in-out 1.25s, 0.5s transform ease-in-out 1.25s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(10):after, .list-timeline.is-animated .list-timeline__item:nth-child(10):before {
        transition: 0.5s opacity ease-in-out 1.25s, 0.5s transform ease-in-out 1.25s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(11) .list-timeline__title, .list-timeline.is-animated .list-timeline__item:nth-child(11) .list-timeline__text {
        transition: 0.5s opacity ease-in-out 1.375s, 0.5s transform ease-in-out 1.375s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(11):after, .list-timeline.is-animated .list-timeline__item:nth-child(11):before {
        transition: 0.5s opacity ease-in-out 1.375s, 0.5s transform ease-in-out 1.375s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(12) .list-timeline__title, .list-timeline.is-animated .list-timeline__item:nth-child(12) .list-timeline__text {
        transition: 0.5s opacity ease-in-out 1.5s, 0.5s transform ease-in-out 1.5s; }
      .list-timeline.is-animated .list-timeline__item:nth-child(12):after, .list-timeline.is-animated .list-timeline__item:nth-child(12):before {
        transition: 0.5s opacity ease-in-out 1.5s, 0.5s transform ease-in-out 1.5s; }
      .list-timeline.is-animated.is-hidden:before {
        width: 0; }
      .list-timeline.is-animated.is-hidden .list-timeline__item .list-timeline__title, .list-timeline.is-animated.is-hidden .list-timeline__item .list-timeline__text {
        opacity: 0;
        transform: translateY(-1rem); }
      .list-timeline.is-animated.is-hidden .list-timeline__item:nth-child(even) .list-timeline__title, .list-timeline.is-animated.is-hidden .list-timeline__item:nth-child(even) .list-timeline__text {
        transform: translateY(1rem); }
      .list-timeline.is-animated.is-hidden .list-timeline__item:after, .list-timeline.is-animated.is-hidden .list-timeline__item:before {
        transform: scale(0); } }
  @media (min-width: 1200px) {
    .list-timeline {
      padding: 0 3.5rem; } }

.card-listitems {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .card-listitems__header {
    padding: 0 1rem;
    height: 7.5rem;
    flex-shrink: 0; }
  .card-listitems__icon {
    margin-right: 1rem; }
  .card-listitems__list {
    margin-bottom: 0;
    padding: 0 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .card-listitems__item {
    color: #011B34;
    font-weight: 500;
    font-size: 0.875rem; }
  .card-listitems__logo {
    height: 3.25rem;
    max-width: 9.375rem;
    margin: 0 auto .5rem;
    display: flex;
    align-items: center; }
    .card-listitems__logo > img {
      max-width: 100%;
      margin: auto; }
  @media (max-width: 767.98px) {
    .card-listitems__header {
      height: 6.25rem; } }

.popover {
  background-color: #FBFBFB;
  border-radius: 0.25rem; }

.popover-body {
  color: #011B34;
  font-size: 0.75rem; }

.list-criteria__icon {
  margin-bottom: 1rem;
  width: 1.5625rem;
  height: 1.5625rem; }

.list-criteria__item {
  text-align: center;
  margin-top: 1.5rem; }

.list-criteria__text {
  padding-bottom: 1.5rem; }

@media (min-width: 768px) {
  .list-criteria__item:nth-child(n+4) {
    margin-top: 2rem; } }

@media (min-width: 1200px) {
  .list-criteria__icon {
    width: 1.9375rem;
    height: 1.9375rem; } }

@media (max-width: 767.98px) {
  .table-double {
    box-shadow: 0 0 9px 0 rgba(156, 156, 156, 0.5);
    border-radius: 0.25rem;
    margin: 0 .5rem; }
    .table-double__col {
      padding: 0; }
      .table-double__col .card, .table-double__col .card-listitems, .table-double__col .r-calculator__result {
        box-shadow: none;
        border-radius: 0; }
      .table-double__col:nth-child(n+2) .card, .table-double__col:nth-child(n+2) .card-listitems, .table-double__col:nth-child(n+2) .r-calculator__result {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: 1px solid #E0E0E0; }
      .table-double__col:nth-child(n+2) thead {
        display: none; } }

.alert-lopd {
  display: none;
  color: #FFFFFF;
  justify-content: flex-end;
  z-index: -1; }
  .alert-lopd__icon {
    margin-bottom: 1rem; }
  .alert-lopd__content {
    box-shadow: 0 0 30px 0 rgba(128, 128, 128, 0.5);
    border-radius: 4px;
    background: white;
    max-width: 320px;
    margin: 1.5rem;
    pointer-events: auto; }
  .alert-lopd__body {
    padding: 1rem 2rem; }
    .alert-lopd__body p:first-of-type::before {
      content: '';
      display: block;
      height: 0;
      width: 0;
      margin-top: calc((1 - 1.3) * 0.5em); }
    .alert-lopd__body p, .alert-lopd__body a {
      line-height: 1.3; }
  .alert-lopd__footer {
    display: flex;
    align-items: center;
    padding: 0 2rem 1rem;
    justify-content: space-between; }
  .alert-lopd__btn-decline {
    padding-left: 0;
    padding-right: 0;
    color: #011B34; }
  .alert-lopd__btn-accept {
    min-width: 150px; }
  .alert-lopd p:last-child {
    margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .alert-lopd {
      padding: .5rem; }
      .alert-lopd__content {
        box-shadow: 0 0 20px 0 rgba(128, 128, 128, 0.5);
        max-width: 100%;
        margin: 0; }
      .alert-lopd__body {
        padding: .5rem; }
        .alert-lopd__body p {
          margin-bottom: 0; }
          .alert-lopd__body p:first-of-type::before {
            content: '';
            display: block;
            height: 0;
            width: 0;
            margin-top: calc((1 - 1.2) * 0.5em); }
        .alert-lopd__body p, .alert-lopd__body a {
          line-height: 1.2; }
      .alert-lopd__icon {
        margin-bottom: .5rem;
        width: 2.5rem; }
      .alert-lopd__footer {
        padding: .5rem; }
      .alert-lopd__btn-accept {
        min-width: 130px; } }
  @media screen and (max-height: 400px) {
    .alert-lopd {
      overflow: auto;
      flex-shrink: 1; }
      .alert-lopd__icon {
        display: none; }
      .alert-lopd__content {
        overflow: auto; } }

.portrait-list {
  margin-top: -1rem;
  margin-bottom: -1rem; }

.portrait-hoverable {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  text-align: center;
  margin: 1rem 0; }
  .portrait-hoverable .portrait-content {
    position: relative;
    display: inline-block; }
  .portrait-hoverable .portrait-img {
    max-width: 100%; }
  .portrait-hoverable .portrait-hover-content {
    transition: all 0.2s ease-in-out; }
  .portrait-hoverable > blockquote {
    font-weight: 300;
    position: relative;
    color: #FFFFFF;
    z-index: 2;
    bottom: 30%;
    opacity: 0;
    transition: .3s all ease-in-out; }
    .portrait-hoverable > blockquote:before {
      content: "“";
      position: absolute;
      top: -40px;
      left: -11px;
      font-size: 150px;
      line-height: 1;
      font-family: Arial;
      color: rgba(255, 255, 255, 0.2);
      width: 50px;
      height: 50px; }
  .portrait-hoverable .list-social-network {
    margin-top: 1rem; }
    .portrait-hoverable .list-social-network a {
      font-size: 2rem;
      text-decoration: none;
      color: #011B34;
      transition: .3s all ease-out; }
      .portrait-hoverable .list-social-network a:hover {
        color: #026DCE; }
  .portrait-hoverable .portrait-info {
    display: block;
    text-align: center;
    margin-top: 1rem;
    transition: .3s all ease-in-out; }
  .portrait-hoverable .portrait-name {
    display: block;
    color: #011B34;
    margin-bottom: .25rem; }
  .portrait-hoverable .portrait-position {
    display: block; }
  .portrait-hoverable.hover-toggle .portrait-hover-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    margin: 0;
    opacity: 0;
    z-index: 2;
    bottom: 25%;
    background-color: rgba(1, 27, 52, 0.6);
    border-radius: 2px;
    padding: .8rem; }
  .portrait-hoverable.hover-toggle .list-social-network a {
    color: #FFFFFF; }
    .portrait-hoverable.hover-toggle .list-social-network a:hover {
      color: #026DCE; }
  .portrait-hoverable.hover-toggle:hover .portrait-hover-content {
    opacity: 1; }
  @media (max-width: 991.98px) {
    .portrait-hoverable .portrait-info {
      margin-top: .75rem; }
    .portrait-hoverable .list-social-network {
      margin-top: 0; }
    .portrait-hoverable.hover-toggle .portrait-hover-content {
      position: relative;
      opacity: 1;
      border-radius: 0;
      background-color: transparent;
      padding: 0;
      margin-top: .9rem;
      height: auto; }
    .portrait-hoverable.hover-toggle .list-social-network a {
      color: #011B34; }
      .portrait-hoverable.hover-toggle .list-social-network a:hover, .portrait-hoverable.hover-toggle .list-social-network a:focus {
        color: #026DCE; } }

.btn-spinner-loading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  border: 1px solid #E0E0E0;
  cursor: not-allowed; }

.esma-warning {
  box-shadow: rgba(0, 0, 0, 0.25) 0px -5px 6px -2px;
  background-color: #FFFFFF;
  color: #202020;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  padding: .5rem 0;
  pointer-events: auto;
  width: 100%;
  padding: 14px 36px; }
  .esma-warning__container {
    padding: .5rem 6rem; }
  .esma-warning p {
    margin: 0; }
  @media (max-width: 767.98px) {
    .esma-warning p {
      margin: 0;
      line-height: 1.1; }
    .esma-warning__container {
      padding: .5rem; } }
  @media (max-height: 300px) {
    .esma-warning {
      display: none; } }
  @media print {
    .esma-warning {
      display: none; } }

.regulator-selector {
  height: 100%;
  display: flex;
  align-items: center;
  color: #FFFFFF; }
  @media (max-width: 575.98px) {
    .regulator-selector {
      padding: 16px 8px 8px 16px;
      margin-top: 4px; } }
  .regulator-selector__item {
    color: #FFFFFF;
    font-weight: 300;
    padding: 0 8px; }
    .regulator-selector__item + .regulator-selector__item {
      border-left: 1px solid #FFFFFF; }
    .regulator-selector__item.active {
      font-weight: bold; }
    .regulator-selector__item:hover, .regulator-selector__item:focus {
      color: #009BFF;
      text-decoration: none; }
  .regulator-selector__extra-info {
    color: #026DCE;
    font-size: 0.75rem;
    margin-top: 1px; }
  .regulator-selector__toggler {
    display: flex;
    align-items: center; }
    .regulator-selector__toggler:hover {
      text-decoration: none; }
    .regulator-selector__toggler::after {
      display: none; }
  .regulator-selector__arrow {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-top: 1px; }
  .regulator-selector .dropdown {
    height: 100%;
    display: flex; }
    .regulator-selector .dropdown .dropdown-menu {
      background-color: #011B34;
      margin-left: 0; }
      .regulator-selector .dropdown .dropdown-menu .dropdown-item {
        font-weight: 300;
        padding: 8px;
        transition: all 0.2s ease-in-out; }
        .regulator-selector .dropdown .dropdown-menu .dropdown-item:hover, .regulator-selector .dropdown .dropdown-menu .dropdown-item:focus {
          background-color: #011B34;
          color: #009BFF;
          text-decoration: none; }
  @media (min-width: 576px) and (max-width: 991.98px) {
    .regulator-selector .dropdown {
      display: flex; }
      .regulator-selector .dropdown-menu {
        top: 0;
        margin: 0;
        padding: 0;
        display: block;
        min-width: auto;
        position: relative;
        background-color: transparent;
        border: none; }
        .regulator-selector .dropdown-menu .dropdown-item {
          border: none; }
          .regulator-selector .dropdown-menu .dropdown-item:hover {
            background-color: transparent; }
      .regulator-selector .dropdown-toggle {
        order: 0;
        pointer-events: none; }
        .regulator-selector .dropdown-toggle .dropdown__arrow {
          display: none; }
    .regulator-selector .dropdown > .dropdown-toggle > .regulator-selector__item {
      border-right: 1px solid #FFFFFF; }
    .regulator-selector .dropdown > .dropdown-menu {
      background-color: transparent; }
      .regulator-selector .dropdown > .dropdown-menu > .regulator-selector__item {
        padding: 0 8px; }
        .regulator-selector .dropdown > .dropdown-menu > .regulator-selector__item, .regulator-selector .dropdown > .dropdown-menu > .regulator-selector__item:hover, .regulator-selector .dropdown > .dropdown-menu > .regulator-selector__item:focus {
          background-color: transparent; } }
  @media (min-width: 1200px) {
    .regulator-selector .dropdown {
      display: flex; }
      .regulator-selector .dropdown-menu {
        top: 0;
        margin: 0;
        padding: 0;
        display: block;
        min-width: auto;
        position: relative;
        background-color: transparent;
        border: none; }
        .regulator-selector .dropdown-menu .dropdown-item {
          border: none; }
          .regulator-selector .dropdown-menu .dropdown-item:hover {
            background-color: transparent; }
      .regulator-selector .dropdown-toggle {
        order: 0;
        pointer-events: none; }
        .regulator-selector .dropdown-toggle .dropdown__arrow {
          display: none; }
    .regulator-selector .dropdown > .dropdown-toggle > .regulator-selector__item {
      border-right: 1px solid #FFFFFF; }
    .regulator-selector .dropdown > .dropdown-menu {
      background-color: transparent;
      display: flex;
      align-items: center; }
      .regulator-selector .dropdown > .dropdown-menu > .regulator-selector__item {
        padding: 0 8px; }
        .regulator-selector .dropdown > .dropdown-menu > .regulator-selector__item, .regulator-selector .dropdown > .dropdown-menu > .regulator-selector__item:hover {
          background-color: transparent; } }

.table-features-comparison {
  table-layout: fixed;
  min-width: 560px; }
  .table-features-comparison__selector {
    text-align: center;
    text-transform: uppercase; }
    .table-features-comparison__selector.table-features-comparison__selector.btn.active {
      color: #011B34;
      border: none;
      background-color: transparent;
      cursor: pointer; }
      .table-features-comparison__selector.table-features-comparison__selector.btn.active:focus {
        box-shadow: none; }
  .table-features-comparison .table-features-comparison__selector {
    text-transform: uppercase;
    color: #011B34;
    border-color: #011B34; }
    .table-features-comparison .table-features-comparison__selector:hover {
      background-color: #011B34;
      color: #FFFFFF; }
    .table-features-comparison .table-features-comparison__selector.active {
      border: none;
      box-shadow: none;
      color: #011B34;
      background-color: transparent; }
  .modal .table-features-comparison .table-features-comparison__col-header, .table-features-comparison .table-features-comparison__col-header {
    border: none;
    text-align: center;
    font-size: 1.5rem;
    padding: 32px 0;
    color: #4a4a4a; }
  .table-features-comparison .table-features-comparison__row-header {
    color: #373737;
    vertical-align: middle;
    height: 56px;
    padding-left: 0; }
    @media (min-width: 992px) {
      .table-features-comparison .table-features-comparison__row-header {
        width: 20%; } }
  .table-features-comparison .table-features-comparison__cell {
    color: #373737;
    text-align: center;
    vertical-align: middle;
    width: auto;
    height: 56px; }
    @media (min-width: 1200px) {
      .table-features-comparison .table-features-comparison__cell {
        padding-left: 64px;
        padding-right: 64px; } }
    @media (min-width: 1200px) and (max-width: 1439.98px) {
      .table-features-comparison .table-features-comparison__cell {
        padding-left: 48px;
        padding-right: 48px; } }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .table-features-comparison .table-features-comparison__cell {
        padding-left: 32px;
        padding-right: 32px; } }
    @media (max-width: 991.98px) {
      .table-features-comparison .table-features-comparison__cell {
        padding-left: 16px;
        padding-right: 16px; } }
  .table-features-comparison thead th {
    border: none; }
  .table-features-comparison tbody tr:first-child > td {
    border-top: none; }
  .table-features-comparison--selected-1 .table-features-comparison__cell:nth-child(2),
  .table-features-comparison--selected-1 .table-features-comparison__col-header:nth-child(2) {
    background-color: rgba(224, 224, 224, 0.2); }
  .table-features-comparison--selected-1 .table-features-comparison__col-header:nth-child(2) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }
  .table-features-comparison--selected-1 > tbody > tr:last-child .table-features-comparison__cell:nth-child(2) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 8px 8px -8px rgba(0, 0, 0, 0.25); }
  .table-features-comparison--selected-2 .table-features-comparison__cell:nth-child(3),
  .table-features-comparison--selected-2 .table-features-comparison__col-header:nth-child(3) {
    background-color: rgba(224, 224, 224, 0.2); }
  .table-features-comparison--selected-2 .table-features-comparison__col-header:nth-child(3) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }
  .table-features-comparison--selected-2 > tbody > tr:last-child .table-features-comparison__cell:nth-child(3) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 8px 8px -8px rgba(0, 0, 0, 0.25); }

.badge__container {
  position: relative; }

.badge__item {
  width: auto;
  right: 0;
  top: -5.75rem;
  position: absolute;
  transform: scale(0.5);
  transform-origin: 100% 0; }

.header-main {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1031;
  transition: background .15s linear; }
  .header-main--empty {
    display: flex;
    align-items: center;
    background-color: #000000;
    min-height: 3.125rem; }
    .is-scrolling .header-main--empty.header-main {
      background-color: #000000; }
    @media (max-width: 767.98px) {
      .header-main--empty .header-main__logo {
        margin: 0 auto; } }
  @media (min-width: 1200px) {
    .header-main__logo {
      margin-right: 25px; } }
  @media (min-width: 768px) {
    .header-main__logo {
      padding-bottom: 16px; } }
  @media (min-width: 1200px) {
    .header-main .login-signup, .header-main .login-signup-mobile {
      margin-left: 20px; } }
  @media (min-width: 768px) {
    .header-main .login-signup, .header-main .login-signup-mobile {
      padding-bottom: 16px; } }
  @media (max-width: 991.98px) {
    .header-main .login-signup, .header-main .login-signup-mobile {
      padding-top: 20px; } }
  @media (max-width: 1439.98px) {
    .header-main .login-signup_fr .btn-signup {
      display: none; } }
  .header-main .login-signup--scroll {
    display: none; }
  .header-main__entity {
    background-color: #E0E0E0;
    color: #202020;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 65px;
    height: 40px; }
    @media (max-width: 1199.98px) {
      .header-main__entity {
        padding-right: 16px;
        padding-left: 16px; } }
    @media (max-width: 991.98px) {
      .header-main__entity {
        display: none; } }
    .header-main__entity__links {
      font-weight: 700;
      font-size: 16px;
      padding: 8px 0px;
      display: flex;
      align-items: center;
      gap: 8px; }
      .header-main__entity__links_link {
        font-weight: 400;
        position: relative;
        text-decoration: none;
        color: #202020; }
        .header-main__entity__links_link:active, .header-main__entity__links_link:hover, .header-main__entity__links_link[aria-selected="true"] {
          color: #009BFF;
          font-weight: 700; }
        .header-main__entity__links_link:hover {
          text-decoration: none;
          cursor: pointer; }
    .header-main__entity_selector {
      height: 100%; }
      .header-main__entity_selector:hover {
        background-color: #979797;
        cursor: pointer; }
      .header-main__entity_selector a {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
      .header-main__entity_selector i {
        margin-left: 7px; }
      .header-main__entity_selector .dropdown-menu {
        background-color: #4a4a4a;
        padding: 48px 30px;
        width: 105px; }
        .header-main__entity_selector .dropdown-menu .dropdown-item {
          color: #FFFFFF;
          padding: 0;
          text-align: left;
          justify-content: flex-start; }
          .header-main__entity_selector .dropdown-menu .dropdown-item:hover {
            background-color: transparent;
            color: #026DCE; }
          .header-main__entity_selector .dropdown-menu .dropdown-item:focus {
            background-color: transparent; }
            .header-main__entity_selector .dropdown-menu .dropdown-item:focus i {
              transform: rotate(-180deg); }
          .header-main__entity_selector .dropdown-menu .dropdown-item {
            margin-bottom: 16px; }
  .header-main__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 1rem;
    width: 100%;
    height: 55px;
    background-color: #202020; }
    @media (max-width: 991.98px) {
      .header-main__container {
        align-items: center; } }
    @media (min-width: 992px) {
      .header-main__container {
        padding-right: 65px;
        padding-left: 65px; }
        .header-main__container .btn-logged {
          min-width: 233px; } }
    @media (max-width: 1199.98px) {
      .header-main__container {
        padding-right: 16px;
        padding-left: 16px; }
        .header-main__container .login-signup .btn-signup, .header-main__container .login-signup-mobile .btn-signup {
          display: none; } }
    @media (max-width: 991.98px) {
      .header-main__container {
        padding-right: 8px;
        padding-left: 8px;
        justify-content: center; }
        .header-main__container .login-signup .btn-signup, .header-main__container .login-signup-mobile .btn-signup {
          display: flex; } }
  .is-scrolling .header-main {
    background-color: #202020; }
  .header-main .dropdown-menu .dropdown-language {
    display: none; }
  .header-main .atomic-nav-item > * {
    text-decoration: none; }
  .header-main.has-submenu-open {
    background-color: rgba(3, 25, 56, 0.95); }
    .is-scrolling .header-main.has-submenu-open {
      background-color: #011B34; }
  .header-main__regco-toggler {
    display: inline-block; }
  @media (max-width: 991.98px) {
    .has-sidebar-navbar-open .header-main {
      background-color: #373737; } }
  @media (min-width: 992px) {
    .header-main {
      border-bottom: 1px solid #FFFFFF; }
      .header-main__regco-toggler {
        display: none; }
      .is-scrolling .header-main {
        border-bottom-color: transparent; }
      .header-main .login-signup.login-signup--scroll, .header-main .login-signup--scroll.login-signup-mobile {
        display: inline-flex;
        align-items: center; }
        .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn, .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn {
          position: relative;
          overflow: hidden;
          height: 2.1875rem;
          width: 11.25rem; }
          .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn > a, .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn > a {
            color: #FFFFFF;
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0; }
          .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn:hover > a, .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn:hover > a, .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn:focus > a, .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn:focus > a {
            color: #011B34;
            text-decoration: none; }
        .header-main .login-signup.login-signup--scroll .btn-login, .header-main .login-signup--scroll.login-signup-mobile .btn-login {
          transition: all 0.2s ease-in-out; }
        .header-main .login-signup.login-signup--scroll .btn-signup, .header-main .login-signup--scroll.login-signup-mobile .btn-signup {
          transform: translateX(100%);
          transition: all 0.2s ease-in-out; }
        .is-scrolling .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn, .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn {
          color: #FFFFFF;
          background-color: #026DCE;
          border-color: #026DCE; }
          .is-scrolling .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn:hover, .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn:hover {
            color: #FFFFFF;
            background-color: #0259a8;
            border-color: #02529b; }
          .is-scrolling .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn:focus, .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn:focus, .is-scrolling .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn.focus, .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn.focus {
            color: #FFFFFF;
            background-color: #0259a8;
            border-color: #02529b;
            box-shadow: 0 0 0 0.15rem rgba(40, 131, 213, 0.5); }
          .is-scrolling .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn.disabled, .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn.disabled, .is-scrolling .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn:disabled, .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn:disabled {
            color: #FFFFFF;
            background-color: #026DCE;
            border-color: #026DCE; }
          .is-scrolling .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn:not(:disabled):not(.disabled):active, .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn:not(:disabled):not(.disabled):active, .is-scrolling .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn:not(:disabled):not(.disabled).active, .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn:not(:disabled):not(.disabled).active,
          .show > .is-scrolling .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn.dropdown-toggle,
          .show > .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn.dropdown-toggle {
            color: #FFFFFF;
            background-color: #02529b;
            border-color: #014c8f; }
            .is-scrolling .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn:not(:disabled):not(.disabled):active:focus, .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn:not(:disabled):not(.disabled):active:focus, .is-scrolling .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn:not(:disabled):not(.disabled).active:focus, .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn:not(:disabled):not(.disabled).active:focus,
            .show > .is-scrolling .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn.dropdown-toggle:focus,
            .show > .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn.dropdown-toggle:focus {
              box-shadow: 0 0 0 0.15rem rgba(40, 131, 213, 0.5); }
          .is-scrolling .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn:hover > a, .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn:hover > a, .is-scrolling .header-main .login-signup.login-signup--scroll .login-signup--scroll__btn:focus > a, .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .login-signup--scroll__btn:focus > a {
            color: #FFFFFF; }
        .is-scrolling .header-main .login-signup.login-signup--scroll .btn-login, .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .btn-login {
          transform: translateX(-100%); }
        .is-scrolling .header-main .login-signup.login-signup--scroll .btn-signup, .is-scrolling .header-main .login-signup--scroll.login-signup-mobile .btn-signup {
          transform: translateX(0); } }

.section {
  position: relative;
  scroll-margin-top: 169px; }
  .section--fullscreen {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
    position: relative;
    background-size: cover; }
  .section--bg-mocked {
    background-color: #3d3d3d;
    color: white; }
  .section--bg-cover-right {
    background-size: cover;
    background-position: center right; }
    @media (max-width: 767.98px) {
      .section--bg-cover-right {
        position: relative; }
        .section--bg-cover-right:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(3, 25, 56, 0.5); } }
  .section--bg-office {
    background-image: url(../images/bg/office.jpg); }
  .section--intro {
    display: flex;
    height: 550px;
    align-items: center;
    justify-content: center;
    color: white;
    background-size: cover; }
  .section__content {
    padding-top: 5.75rem;
    padding-bottom: 5.75rem; }
    .section__content_textOnly {
      min-height: calc(100vh - 10rem);
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center; }
      @media (max-width: 991.98px) {
        .section__content_textOnly {
          min-height: unset;
          padding-top: 44px;
          padding-bottom: 32px; } }
      .section__content_textOnly .uptitle {
        color: #FFFFFF;
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 10px; }
        @media (max-width: 991.98px) {
          .section__content_textOnly .uptitle {
            font-size: 16px; } }
      .section__content_textOnly .h1 {
        font-size: 24px; }
      .section__content_textOnly h2 {
        font-size: 16px; }
    .section__content__text_title {
      font-size: 40px;
      line-height: 62px;
      margin-bottom: 32px; }
      @media (max-width: 991.98px) {
        .section__content__text_title {
          font-size: 24px;
          line-height: 38px;
          margin-bottom: 23px; } }
    .section__content__text_subtitle {
      font-size: 24px;
      line-height: 38px; }
      @media (max-width: 991.98px) {
        .section__content__text_subtitle {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 18px; } }
      .section__content__text_subtitle * {
        font-size: 24px;
        line-height: 38px; }
        @media (max-width: 991.98px) {
          .section__content__text_subtitle * {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 18px; } }
    .section__content__container__component__text {
      font-size: 14px;
      line-height: 22px; }
    .section__content__container__component__title {
      font-size: 24px;
      line-height: 38px;
      margin-bottom: 48px; }
      @media (max-width: 991.98px) {
        .section__content__container__component__title {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 36px; } }
    .section__content__container__component__list {
      padding: 0; }
      .section__content__container__component__list_title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 800;
        margin-bottom: 36px; }
      .section__content__container__component__list_item {
        display: flex;
        align-items: flex-start;
        padding-left: 4px; }
        .section__content__container__component__list_item:not(.section__content__container__component__list_item:last-of-type) {
          margin-bottom: 24px; }
          @media (max-width: 991.98px) {
            .section__content__container__component__list_item:not(.section__content__container__component__list_item:last-of-type) {
              margin-bottom: 40px; } }
        .section__content__container__component__list_item_imgBg {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          height: 28px;
          padding: 5px;
          margin-right: 13px; }
    @media (max-width: 991.98px) {
      .section__content__container__media {
        padding-top: 20px; } }
  .section__pretitle {
    margin-bottom: 1.5rem; }
  .section.bg-dark {
    color: #FFFFFF;
    background-color: #272727 !important; }
  .section .h2 {
    margin-bottom: 2.5rem;
    font-weight: 300; }
  .section .h3 {
    margin-bottom: 2.5rem;
    font-weight: 300; }
  .section .h4 {
    margin-bottom: 2.5rem;
    font-weight: 300; }
  .section p:last-child {
    margin-bottom: 0; }
  .section--sm h4, .section--sm .h4 {
    margin-bottom: 1.75rem; }
  .section--sm .section__content {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .section--xs .section__content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  @media (max-width: 1199.98px) {
    .section--intro {
      height: 400px; } }
  @media (max-width: 991.98px) {
    .section .h2 {
      margin-bottom: 2rem; }
    .section .h3 {
      margin-bottom: 2rem; }
    .section .h4 {
      margin-bottom: 1rem; }
    .section--intro {
      height: auto;
      padding: 4rem 2rem 3rem; } }
  @media (max-width: 991.98px) {
    .section__content {
      padding: 2rem .5rem; }
    .section .badge__item {
      top: -2rem; }
    .section--intro .section__content {
      padding: 0; }
    .section--sm .section__content {
      padding-top: 1.75rem;
      padding-bottom: 1.75rem; }
    .section--xs .section__content {
      padding-top: 1rem;
      padding-bottom: 1rem; }
    .section--fullscreen {
      width: auto;
      height: auto;
      padding-top: 4.5rem;
      padding-bottom: 3rem; } }
  @media (min-width: 576px) {
    .section .badge__item {
      transform: none; } }
  @media (min-width: 1440px) {
    .section .badge__item {
      transform: translateX(100%); } }

.ibkr__checkmark {
  width: 18px;
  height: 18px;
  margin-right: 16px; }

.ibkr__icon {
  width: 28px;
  height: 28px;
  margin-right: 14px; }

.ibkr__list {
  padding-inline-start: 0;
  list-style: none; }
  .ibkr__list-item--flex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 16px;
    list-style: none; }

@media screen and (min-width: 992px) {
  .ibkr__img--mobile {
    display: none; } }

@media screen and (max-width: 992px) {
  .ibkr__img--desk {
    display: none; } }

ol > li::marker {
  font-weight: bold; }

.assets__container {
  display: flex;
  justify-content: space-between;
  padding-top: 60px; }
  @media (max-width: 991.98px) {
    .assets__container {
      flex-direction: column; } }
  .assets__container_item {
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(153, 151, 151, 0.5);
    padding: 32px 52px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 991.98px) {
      .assets__container_item:not(.assets__container_item:last-of-type) {
        margin-bottom: 32px; } }
    .assets__container_item img {
      margin-bottom: 16px; }
    .assets__container_item p {
      height: 100%;
      display: flex;
      align-items: center;
      text-align: center; }

.section-intro-main {
  position: relative; }
  .section-intro-main_container {
    padding: 46px 0px; }
  .section-intro-main__image {
    width: 100%; }
    @media (max-width: 991.98px) {
      .section-intro-main__image {
        display: none; } }
  .section-intro-main__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .section-intro-main__bottom {
    margin: 2rem auto 1rem; }
  .section-intro-main h2 {
    margin-bottom: 1rem; }
  .section-intro-main .h3 {
    font-size: 1.125rem; }
  .section-intro-main .stats__item {
    max-width: 240px; }
  @media (max-height: 650px) {
    .section-intro-main {
      height: auto; } }
  @media (min-width: 576px) {
    .section-intro-main__content {
      margin-bottom: 2rem; }
    .section-intro-main__bottom {
      margin-top: 0; } }
  @media (min-width: 768px) {
    .section-intro-main__bottom {
      margin: auto auto 3.5rem; }
    .section-intro-main h1,
    .section-intro-main .h1 {
      margin-bottom: 1.5625rem; }
    .section-intro-main h3,
    .section-intro-main .h3 {
      margin-bottom: 1.0625rem;
      font-size: 1.5rem; } }
  @media (min-width: 992px) {
    .section-intro-main__content {
      padding: 5rem 3rem 0rem; }
    .section-intro-main h3,
    .section-intro-main .h3 {
      margin-bottom: 1.0625rem;
      font-size: 2rem; } }
  @media (max-width: 991.98px) {
    .section-intro-main .stats {
      flex-wrap: wrap;
      justify-content: center; }
      .section-intro-main .stats__item {
        min-width: 33%; } }
  @media (max-width: 767.98px) {
    .section-intro-main .stats {
      margin-left: 0;
      margin-right: 0; }
      .section-intro-main .stats__item {
        flex: 50%; } }
  .section-intro-main_video {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    object-fit: none;
    opacity: 0.5; }

.section-broker-quality {
  background-image: url(../images/bg/laptop.png); }

.section__line {
  padding: 100px 0; }
  @media (max-width: 991.98px) {
    .section__line {
      padding: 68px 16px; } }
  .section__line_title {
    margin-bottom: 38px; }
    @media (max-width: 991.98px) {
      .section__line_title {
        margin-bottom: 30px; } }
  .section__line_subtitle {
    margin-bottom: 56px; }
    @media (max-width: 991.98px) {
      .section__line_subtitle {
        margin-bottom: 30px; } }
  .section__line_text {
    margin-bottom: 102px; }
    @media (max-width: 991.98px) {
      .section__line_text {
        margin-bottom: 62px; } }
  .section__line__bar {
    margin-bottom: 80px; }
    @media (max-width: 991.98px) {
      .section__line__bar {
        margin-bottom: 40px; } }
    .section__line__bar__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 991.98px) {
        .section__line__bar__item {
          flex-direction: row; } }
      .section__line__bar__item_title {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 12px; }
        @media (max-width: 991.98px) {
          .section__line__bar__item_title {
            padding: 0;
            display: block;
            margin-bottom: 16px;
            text-align: left; } }
      .section__line__bar__item_text {
        margin-bottom: 0;
        padding: 12px; }
        @media (max-width: 991.98px) {
          .section__line__bar__item_text {
            position: relative;
            margin-bottom: 34px;
            padding: 0; } }
      .section__line__bar__item__img {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        position: relative;
        height: 62px; }
        @media (max-width: 991.98px) {
          .section__line__bar__item__img {
            height: 100%; } }
        .section__line__bar__item__img_bar {
          background-color: #FFFFFF;
          width: 100%;
          height: 24px;
          border-radius: 124px; }
          @media (max-width: 991.98px) {
            .section__line__bar__item__img_bar {
              height: 100%;
              width: 18px; } }
          .section__line__bar__item__img_bar_start {
            background-color: #FFFFFF;
            width: 100%;
            height: 24px;
            border-radius: 124px; }
            @media (max-width: 991.98px) {
              .section__line__bar__item__img_bar_start {
                height: 85%;
                width: 18px; } }
        .section__line__bar__item__img_container {
          display: flex;
          justify-content: space-around;
          width: 100%;
          position: absolute;
          height: 100%;
          align-items: center; }
          @media (max-width: 991.98px) {
            .section__line__bar__item__img_container {
              height: 100%;
              width: 56px;
              flex-direction: column;
              justify-content: space-between;
              left: -84px;
              transform: translate(-33%, 0);
              top: -15px;
              align-items: unset; } }
          .section__line__bar__item__img_container div {
            border-radius: 50%;
            width: 56px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center; }
          .section__line__bar__item__img_container_black {
            background-color: #202020;
            border: 6px solid #FFFFFF; }
          .section__line__bar__item__img_container_light {
            background-color: #FFFFFF;
            border: 6px solid #272727; }
      @media (max-width: 991.98px) {
        .section__line__bar__item__mobile {
          padding-top: 66px 0;
          display: flex; } }
      .section__line__bar__item__mobile_images {
        padding: 0 66px;
        position: relative; }
      .section__line__bar__item__mobile_textContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 66px;
        position: relative; }
      .section__line__bar__item__mobile_text {
        position: relative; }

.section-features__features {
  margin: 1rem auto; }

.section-features__feature {
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem; }

.section-features__content {
  max-width: 100%; }

.section-features__icon {
  width: auto;
  height: 3.125rem;
  margin-bottom: 1rem; }

.section-features__title {
  margin-bottom: .75rem;
  color: #011B34;
  line-height: 1.2; }

.section-features__text {
  padding: 0;
  line-height: 20px; }

@media (min-width: 576px) {
  .section-features__features {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap; }
  .section-features__feature {
    flex-basis: 50%;
    margin-bottom: 0; }
  .section-features__title br {
    display: block; }
  .section-features__text {
    padding: 0 12px; }
  .section-features__content {
    padding-left: 0; } }

@media (min-width: 992px) {
  .section-features__features {
    display: flex;
    justify-content: space-between; }
  .section-features__feature {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0; }
  .section-features--lg .section-features__features {
    justify-content: center; }
  .section-features--lg .section-features__feature {
    flex-basis: 33%;
    max-width: 33%; } }

@media (min-width: 1200px) {
  .section-features__icon {
    width: auto;
    height: 4.0625rem; } }

.section-features-bg .underline-effect {
  margin-bottom: .5rem; }
  .section-features-bg .underline-effect > h3 {
    padding-bottom: .5rem;
    position: relative; }
    .section-features-bg .underline-effect > h3:after {
      content: '';
      height: 1px;
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      background-color: #009BFF;
      transition: .75s all ease-in-out; }

.section-features-bg__feature:nth-child(n+3) .underline-effect > h3:after {
  left: auto;
  right: 0; }

.section-features-bg__img {
  max-width: 90%;
  display: block;
  margin: -3rem auto 1rem; }

.section-features-bg__feature:not(.last-child) {
  margin-bottom: 1.75rem; }

@media (min-width: 768px) {
  .section-features-bg__grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 6.25rem;
    margin-bottom: 3rem; } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
    .section-features-bg__grid {
      margin-right: -6.25rem; }
      .section-features-bg__grid > * {
        margin-right: 6.25rem; } }

@media (min-width: 768px) {
  .section-features-bg__feature:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2-1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2-1;
    grid-column: 1/2;
    grid-row: 1/2; }
  .section-features-bg__feature:nth-child(2) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2-1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 3-2;
    grid-column: 1/2;
    grid-row: 2/3; }
  .section-features-bg__feature:nth-child(3) {
    -ms-grid-column: 2;
    -ms-grid-column-span: 3-2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2-1;
    grid-column: 2/3;
    grid-row: 1/2; }
  .section-features-bg__feature:nth-child(4) {
    -ms-grid-column: 2;
    -ms-grid-column-span: 3-2;
    -ms-grid-row: 2;
    -ms-grid-row-span: 3-2;
    grid-column: 2/3;
    grid-row: 2/3; } }

@media (min-width: 992px) {
  .section-features-bg .h2 {
    margin-bottom: 4rem; }
  .section-features-bg__grid {
    grid-column-gap: 13rem; } }
  @media screen and (min-width: 992px) and (-ms-high-contrast: active), (min-width: 992px) and (-ms-high-contrast: none) {
    .section-features-bg__grid {
      margin-right: -13rem; }
      .section-features-bg__grid > * {
        margin-right: 13rem; } }

@media (min-width: 992px) {
  .section-features-bg__img {
    left: 50%;
    position: absolute;
    top: 8.1rem;
    transform: translateX(-50%);
    margin: 0;
    width: 25rem; }
  .section-features-bg__feature.is-animated .underline-effect:after, .section-features-bg__feature.is-animated .underline-effect:before {
    transition: .5s all ease-in-out .8s; }
  .section-features-bg__feature.is-animated:not(.is-hidden) .underline-effect:before {
    -webkit-animation: pulse 1s ease-in-out infinite 0.7s;
    -ms-animation: pulse 1s ease-in-out infinite 0.7s;
    animation: pulse 1s ease-in-out infinite 0.7s; }
  .section-features-bg__feature.is-animated:nth-child(n+3):not(.is-hidden) .underline-effect:before {
    -webkit-animation: pulse-alt 1s ease-in-out infinite 0.7s;
    -ms-animation: pulse-alt 1s ease-in-out infinite 0.7s;
    animation: pulse-alt 1s ease-in-out infinite 0.7s; }
  .section-features-bg__feature.is-animated.is-hidden {
    opacity: 0;
    transform: translateX(-2rem); }
    .section-features-bg__feature.is-animated.is-hidden .underline-effect:after, .section-features-bg__feature.is-animated.is-hidden .underline-effect:before {
      opacity: 0 !important; }
    .section-features-bg__feature.is-animated.is-hidden .underline-effect > h3:after {
      width: 0; }
    .section-features-bg__feature.is-animated.is-hidden:nth-child(n+3) {
      opacity: 0;
      transform: translateX(2rem); }
  .section-features-bg__feature .underline-effect {
    position: relative; }
    .section-features-bg__feature .underline-effect:after {
      content: '';
      width: 1rem;
      height: 1rem;
      background-color: #009BFF;
      border-radius: 50%;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateY(50%) translateX(50%); }
    .section-features-bg__feature .underline-effect:before {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      background-color: #009BFF;
      opacity: .5;
      border-radius: 50%;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateY(50%) translateX(50%); }
  .section-features-bg__feature:nth-child(-n+2) h3, .section-features-bg__feature:nth-child(-n+2) p {
    padding-right: 6.5rem; }
  .section-features-bg__feature:nth-child(n+3) .underline-effect:after, .section-features-bg__feature:nth-child(n+3) .underline-effect:before {
    right: auto;
    left: 0;
    transform: translateY(50%) translateX(-50%); }
  .section-features-bg__feature:nth-child(n+3) h3, .section-features-bg__feature:nth-child(n+3) p {
    padding-left: 6.5rem;
    text-align: right; } }

@media (min-width: 1200px) {
  .section-features-bg__grid {
    grid-column-gap: 15.5rem; } }
  @media screen and (min-width: 1200px) and (-ms-high-contrast: active), (min-width: 1200px) and (-ms-high-contrast: none) {
    .section-features-bg__grid {
      margin-right: -15.5rem; }
      .section-features-bg__grid > * {
        margin-right: 15.5rem; } }

@media (min-width: 1200px) {
  .section-features-bg__img {
    top: 6rem;
    width: 30rem; } }

.section-half {
  position: relative;
  max-width: 540px; }
  .section-half h2,
  .section-half .h2 {
    position: relative; }
  .section-half:before {
    content: '';
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    background-image: url(../images/bg/office.jpg); }
  .section-half--investable::before {
    background-image: url(../images/home/investable.png); }
  .section-half--seed:before {
    background-image: url(../images/home/seed.png); }
  .section-half--darwinex-pro:before {
    background-image: url(../images/home/darwinex-pro.png); }
  .section-half--invest:before {
    background-image: url(../images/home/invest.png); }
  .section-half--technology:before {
    background-image: url(../images/home/technology.png); }
  .section-half--trust:before {
    background-image: url(../images/home/trust.png); }
  .section-half--laptop-mobile:before {
    background-image: url(../images/bg/bg-laptop-mobile.jpg);
    background-position: center left; }
  .section-half--skyscrappers:before {
    background-image: url(../images/bg/bg-skyscrappers-2.jpg);
    background-position: center left; }
  .section-half--office:before {
    background-image: url(../images/bg/office-alt.jpg); }
  .section-half--skyscrapper-plane:before {
    background-image: url(../images/bg/bg-skyscrapper-plane.jpg);
    background-position: center top; }
  .section-half--handshake:before {
    background-image: url(../images/bg/bg-handshake.jpg);
    background-position: center left; }
  .section-half--safelock:before {
    background-image: url(../images/bg/bg-safelock.jpg);
    background-position: center center; }
  .section-half--trader-stairs:before {
    background-image: url(../images/bg/bg-trader-stairs.jpg);
    background-position: center center; }
  .section-half--darwinex-staff:before {
    background-image: url(../images/bg/bg-darwinex-staff.jpg);
    background-position: center center; }
  .section-half--darwinex-staff-2:before {
    background-image: url(../images/bg/bg-darwinex-staff-2.jpg);
    background-position: center center; }
  .section-half--map:before {
    background-image: url(../images/bg/bg-map.png);
    background-position: center left; }
  @media (min-width: 768px) {
    .section-half.section-half-vision .section-half__content {
      display: flex;
      align-items: center;
      min-height: 412px; } }
  @media (min-width: 1200px) {
    .section-half.section-half-vision .section-half__content {
      min-height: 500px; } }
  .section-half__content {
    padding: 3.75rem 2rem 3.75rem 0; }
  .section-half__cta {
    margin-top: 2rem; }
  @media (min-width: 576px) {
    .section-half {
      max-width: 100%; }
      .section-half:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: calc(50% + 0.25rem);
        border-left: 0.5rem solid white; }
      .section-half--right:before {
        right: auto;
        left: 0;
        border-left: 0;
        border-right: 0.5rem solid white; }
      .section-half--right .section-half__content {
        padding-right: 0; }
      .section-half__cta {
        margin-top: 2.5rem; }
      .section-half--sm:before {
        width: calc( 66.66666667% + 0.25rem); }
      .section-half--lg:before {
        width: calc(40% + 0.25rem); } }
  @media (min-width: 768px) {
    .section-half--right .section-half__content {
      padding-left: 2rem; } }
  @media (max-width: 767.98px) {
    .section-half {
      display: flex;
      flex-direction: column; }
      .section-half:before {
        position: relative;
        order: 2;
        width: 100%;
        padding-bottom: 50%;
        border: 0; }
      .section-half__content {
        padding: 2rem 0.5rem; } }

@media (min-width: 576px) {
  .section-ibkr {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'title image' 'text image' 'cta image'; } }

.section-ibkr__h2 {
  grid-area: title; }

.section-ibkr__img {
  grid-area: image;
  align-self: center;
  margin: 1rem auto 2rem; }
  @media (min-width: 576px) {
    .section-ibkr__img {
      margin: auto; } }
  .section-ibkr__img img {
    width: 100%; }

.section-ibkr__text {
  grid-area: text; }

.section-ibkr__cta {
  grid-area: cta; }

.prefooter__right {
  display: flex;
  justify-content: left;
  padding: 4rem 0 4rem 6rem; }
  @media (max-width: 1439.98px) {
    .prefooter__right {
      padding: 1.5rem;
      justify-content: center; } }

.prefooter__left {
  display: flex;
  justify-content: left;
  background-color: #262435;
  padding: 4rem 0 4rem 6rem; }
  @media (max-width: 1439.98px) {
    .prefooter__left {
      padding: 1.5rem;
      justify-content: center; } }

.prefooter__container {
  display: flex;
  flex-direction: column;
  max-width: 550px; }

.prefooter__logo {
  margin-left: -0.5rem; }

.prefooter__cta {
  justify-self: flex-end; }

.prefooter strong {
  font-weight: bold; }

.virtual {
  background: linear-gradient(#202020 50%, #ffffff 50%, #ffffff); }
  .virtual__container {
    background-color: #1e1727;
    border-top: 8px solid #e27139;
    border-radius: 8px; }
  .virtual__icon {
    color: #e27139;
    height: 2.3rem;
    margin-bottom: 0.875rem; }
  .virtual__info {
    font-size: 22px;
    line-height: 28px; }
  .virtual__info-title {
    font-size: 24px;
    line-height: 24px; }
  .virtual__info-text {
    font-size: 16px;
    line-height: 20px; }
  .virtual__info-block {
    font-size: 16px;
    line-height: 20px; }
  .virtual__img-container {
    padding: 0; }
  .virtual__image {
    width: 100%;
    -webkit-clip-path: polygon(0% 0%, 0% 0%, 100% 0%, 100% 2%, 80% 100%, 0% 100%, 0% 0%, 0% 0%);
    clip-path: polygon(0% 0%, 0% 0%, 100% 0%, 100% 2%, 80% 100%, 0% 100%, 0% 0%, 0% 0%); }
    @media (max-width: 991.98px) {
      .virtual__image {
        max-height: 294px;
        object-fit: cover;
        clip-path: none; } }

.darwin-card {
  background-color: tranparent;
  border-radius: 0.5rem;
  box-shadow: 0 0 9px 0 rgba(156, 156, 156, 0.5);
  color: #011B34;
  color: var(--dark); }
  .darwin-card--sm {
    max-width: 350px; }
  .darwin-card__header {
    display: flex;
    justify-content: space-between;
    padding: 0.8rem; }
  .darwin-card__chart {
    padding: 0.8rem 0.8rem 0;
    display: flex;
    align-items: center; }
    .darwin-card__chart svg {
      width: 100%; }
    @media (max-width: 767.98px) {
      @keyframes dash {
        to {
          stroke-dashoffset: 0; } }
      .darwin-card__chart--animated .darwin-chart-path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        transition: 1.5s all ease-in-out; }
        .slick-current .darwin-card__chart--animated .darwin-chart-path {
          stroke-dashoffset: 0; } }
    @media (max-width: 1199.98px) {
      .darwin-card__chart {
        padding: 0 0.8rem; } }
  .darwin-card__stats {
    padding: 0.8rem 0.8rem 0; }
    .darwin-card__stats table td {
      border-top-color: #676D81; }
  @media (max-width: 991.98px) {
    .darwin-card .make-trading__quote {
      color: gray; } }

.slider--static-xs > .slider__slides {
  display: flex; }
  .slider--static-xs > .slider__slides .slider__item {
    margin: 1rem; }

@media (min-width: 350px) {
  .slider--static-s > .slider__slides {
    display: flex; }
    .slider--static-s > .slider__slides .slider__item {
      margin: 1rem; } }

@media (min-width: 450px) {
  .slider--static-smc > .slider__slides {
    display: flex; }
    .slider--static-smc > .slider__slides .slider__item {
      margin: 1rem; } }

@media (min-width: 576px) {
  .slider--static-sm > .slider__slides {
    display: flex; }
    .slider--static-sm > .slider__slides .slider__item {
      margin: 1rem; } }

@media (min-width: 768px) {
  .slider--static-md > .slider__slides {
    display: flex; }
    .slider--static-md > .slider__slides .slider__item {
      margin: 1rem; } }

@media (min-width: 992px) {
  .slider--static-lg > .slider__slides {
    display: flex; }
    .slider--static-lg > .slider__slides .slider__item {
      margin: 1rem; } }

@media (min-width: 1200px) {
  .slider--static-xl > .slider__slides {
    display: flex; }
    .slider--static-xl > .slider__slides .slider__item {
      margin: 1rem; } }

@media (min-width: 1440px) {
  .slider--static-xxl > .slider__slides {
    display: flex; }
    .slider--static-xxl > .slider__slides .slider__item {
      margin: 1rem; } }

.slider-trade-text-success {
  color: #AAFF00; }

.slider-trade-text-danger {
  color: #FF4141; }

.slider-trade-card {
  width: 220px !important;
  height: 220px !important;
  margin: auto;
  background-color: #373737;
  border-radius: 0.5rem;
  flex-direction: column;
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  padding: 1rem 2rem; }
  @media (max-width: 991.98px) {
    .slider-trade-card {
      width: 170px !important;
      height: 190px !important;
      padding: 0.7rem 1rem; } }
  @media (max-width: 767.98px) {
    .slider-trade-card {
      width: 140px !important;
      height: 225px !important;
      padding: 0.7rem 1rem; } }
  @media (max-width: 349.98px) {
    .slider-trade-card {
      width: 150px !important;
      height: 175px !important;
      padding: 0.5rem 1rem; } }
  .slider-trade-card-color {
    height: 24px !important;
    width: 24px !important;
    border-radius: 100%;
    margin-right: 0.4375rem; }

.slider-trade .slick-arrow {
  width: 18px;
  height: 32px;
  background-image: url(../images/icons/slider-arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: transparent; }
  .slider-trade .slick-arrow::before {
    display: none; }

.slider-trade .slick-next {
  transform: rotate(-180deg); }

.slider-trade .slick-next:focus,
.slider-trade .slick-next:hover,
.slider-trade .slick-prev:focus,
.slider-trade .slick-prev:hover {
  background-image: url(../images/icons/slider-arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: transparent; }

.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden; }

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: 0; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  top: 0;
  left: 0; }

.slick-track:after,
.slick-track:before {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-vertical .slick-slide {
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-next,
.slick-prev {
  position: absolute;
  padding: 0; }

.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-loading .slick-list {
  background: url(ajax-loader.gif) center center no-repeat #FFFFFF; }

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0; }

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0; }

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1; }

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25; }

.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #FFFFFF; }

.slick-prev {
  left: -25px; }

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto; }

.slick-next {
  right: -25px; }

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px; }

.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slider-trade, .slider-cards-horizontal, .slider-user-reviews, .slider-press-reviews {
  padding: 0 1rem; }
  @media (min-width: 576px) {
    .slider-trade .slick-slide, .slider-cards-horizontal .slick-slide, .slider-user-reviews .slick-slide, .slider-press-reviews .slick-slide {
      margin: 0 .5rem; } }
  @media (min-width: 992px) {
    .slider-trade, .slider-cards-horizontal, .slider-user-reviews, .slider-press-reviews {
      padding: 0 3rem; } }

.slider-cards-horizontal {
  max-width: 350px;
  margin: auto; }
  .slider-cards-horizontal .slick-slide {
    margin: 1rem; }
  @media (min-width: 576px) {
    .slider-cards-horizontal {
      max-width: 100%; } }
  @media (min-width: 992px) {
    .slider-cards-horizontal .slick-slide {
      transition: .25s transform ease-in-out;
      transform: scale(0.9);
      margin: 1rem 0; }
      .slider-cards-horizontal .slick-slide.slick-current {
        transform: scale(1); } }

.slider-user-reviews {
  max-width: 350px;
  margin: auto; }
  .slider-user-reviews .slick-slide {
    margin: 1rem;
    box-shadow: 0 0 9px 0 rgba(156, 156, 156, 0.5); }
  @media (min-width: 576px) {
    .slider-user-reviews {
      max-width: 100%; }
      .slider-user-reviews .slick-slide {
        margin: .5rem; } }
  @media (min-width: 992px) {
    .slider-user-reviews {
      padding: 0 3rem; }
      .slider-user-reviews .slick-slide {
        margin: 0.3125rem 1rem;
        box-shadow: none; }
        .slider-user-reviews .slick-slide.slick-current {
          box-shadow: 0 0 9px 0 rgba(156, 156, 156, 0.5); } }

.slider-press-reviews {
  padding: 0 1rem; }
  .slider-press-reviews .logo-press {
    margin: 1rem auto 0; }

.slider-logos .slick-track {
  align-items: center; }

.slider-logos .list-logos__logo {
  margin: .5rem 2rem;
  padding: 0; }
  .slider-logos .list-logos__logo > img {
    margin: auto; }

.footer-main {
  position: relative;
  padding: 2.5rem 0;
  background-color: #373737;
  color: #FFFFFF;
  font-size: .813rem;
  text-align: center; }
  .footer-main__container {
    padding: 0 120px; }
    @media (max-width: 991.98px) {
      .footer-main__container {
        padding: 0 36px; } }
  .footer-main__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.8125rem; }
  @media (max-width: 991.98px) {
    .footer-main__logo {
      margin-bottom: 27px; } }
  .footer-main__social .list-social-network {
    margin-bottom: 0;
    display: flex; }
  .footer-main__social__icon_container {
    width: 36px;
    height: 37.64px;
    background-color: #009bff;
    border-radius: 50%; }
    .footer-main__social__icon_container:hover {
      background-color: #007ccc; }
    .footer-main__social__icon_container a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none; }
  .footer-main__social__icon_img {
    width: 24px;
    height: 24px; }
  .footer-main__contact {
    font-size: 0.875rem;
    text-align: center; }
    @media (max-width: 991.98px) {
      .footer-main__contact address {
        margin-bottom: 23px; } }
    .footer-main__contact_links {
      position: relative; }
      .footer-main__contact_links a:first-of-type {
        margin-right: 6px; }
        .footer-main__contact_links a:first-of-type::after {
          content: '';
          position: absolute;
          width: 1px;
          height: 100%;
          background-color: #FFFFFF;
          margin-left: 6px; }
      .footer-main__contact_links a:last-of-type {
        margin-left: 6px; }
  .footer-main .download-app-links {
    text-align: center; }
    .footer-main .download-app-links a {
      display: inline-block;
      vertical-align: middle;
      margin: 0 34px; }
      .footer-main .download-app-links a:hover, .footer-main .download-app-links a:focus {
        text-decoration: none; }
      .footer-main .download-app-links a img {
        width: 150px;
        height: 51px; }
  .footer-main__text {
    color: #979797;
    padding-top: 24px;
    margin-bottom: 24px; }
    @media (max-width: 991.98px) {
      .footer-main__text {
        padding-top: 55px;
        margin-bottom: 55px; } }
  .footer-main__nav {
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
    text-align: left; }
    @media (max-width: 991.98px) {
      .footer-main__nav {
        flex-direction: column; } }
    @media (min-width: 768px) {
      .footer-main__nav {
        padding-bottom: 84px; } }
    .footer-main__nav_links, .footer-main__nav_links_mobile {
      display: flex;
      flex-direction: column; }
      .footer-main__nav_links h3, .footer-main__nav_links_mobile h3 {
        color: #686868;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 26px; }
        .footer-main__nav_links h3:not(:first-of-type), .footer-main__nav_links_mobile h3:not(:first-of-type) {
          padding-top: 8px; }
      .footer-main__nav_links_link {
        font-size: 16px;
        color: #FFFFFF;
        font-weight: 400;
        text-decoration: none; }
        .footer-main__nav_links_link:first-of-type {
          padding-bottom: 16px; }
        .footer-main__nav_links_link:not(.footer-main__nav_links_link:first-of-type) {
          padding: 16px 0; }
        .footer-main__nav_links_link:last-of-type {
          margin-bottom: 14px; }
        .footer-main__nav_links_link:hover, .footer-main__nav_links_link:focus {
          color: #026DCE;
          text-decoration: none; }
      .footer-main__nav_links_mobile h3 {
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 0; }
      .footer-main__nav_links_mobile_toggler {
        display: flex;
        justify-content: space-between;
        margin-bottom: 38px; }
      .footer-main__nav_links_mobile [aria-expanded='true'] {
        color: #026DCE; }
        .footer-main__nav_links_mobile [aria-expanded='true'] h3 {
          color: #026DCE; }
        .footer-main__nav_links_mobile [aria-expanded='true'] i {
          transform: rotate(180deg); }
      .footer-main__nav_links_mobile_collapsable {
        display: flex;
        flex-direction: column; }
    .footer-main__nav__image {
      max-width: 250px; }
      @media (max-width: 991.98px) {
        .footer-main__nav__image {
          margin-bottom: 50px; } }
  .footer-main__nav-secondary {
    margin-bottom: 1.5rem; }
    .footer-main__nav-secondary a {
      display: inline-block;
      font-size: 0.875rem;
      margin: .5rem 1rem; }
  .footer-main a {
    color: #FFFFFF;
    text-decoration: none; }
  .footer-main .footer-logo {
    margin-bottom: 2rem;
    margin-top: 2rem; }
  .footer-main .list-social-network .list-inline-item:not(:last-child) {
    margin-right: 14px; }
  .footer-main .list-social-network a {
    color: #009BFF;
    transition: all 0.2s ease-in-out; }
    .footer-main .list-social-network a:hover, .footer-main .list-social-network a:focus {
      color: #007ccc;
      text-decoration: none; }
  .footer-main .list-social-network [class*="icon-"] {
    font-size: 2.25rem; }
  @media (max-width: 991.98px) {
    .footer-main__info {
      display: block;
      margin-bottom: 2.25rem; }
    .footer-main__contact {
      font-size: 0.875rem;
      margin-bottom: 2.625rem;
      text-align: center; }
    .footer-main .download-app-links {
      text-align: center;
      display: flex;
      flex-direction: column; }
      .footer-main .download-app-links a {
        margin-left: 0;
        margin-right: 0; }
        .footer-main .download-app-links a:first-of-type {
          margin-bottom: 23px; }
        .footer-main .download-app-links a img {
          width: 150px;
          height: 51px; }
    .footer-main .list-social-network {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem; }
      .footer-main .list-social-network .list-inline-item:not(:last-child) {
        margin-right: 1rem; }
      .footer-main .list-social-network [class*="icon-"] {
        font-size: 2.5rem; } }
  @media (max-width: 767.98px) {
    .footer-main .list-social-network .list-inline-item:not(:last-child) {
      margin-right: .5rem; }
    .footer-main .list-social-network [class*="icon-"] {
      font-size: 2rem; } }

.sticky-bottom {
  z-index: 1030;
  pointer-events: none; }
  @media screen and (max-height: 600px) {
    .sticky-bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      top: 3.5rem; } }

.r-calculator {
  padding: 1.5rem;
  text-align: left; }
  .r-calculator__input-group {
    display: flex; }
    .r-calculator__input-group > .form-group {
      flex: 1;
      margin-right: .5rem; }
  .r-calculator .form-control[type="text"] {
    font-size: 0.875rem; }
  .r-calculator__results {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: minmax(90px, auto) minmax(90px, auto);
    grid-template-rows: minmax(90px, auto) minmax(90px, auto);
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-top: 1.5rem; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .r-calculator__results {
        margin-right: -10px;
        margin-bottom: -10px; }
        .r-calculator__results > * {
          margin-right: 10px;
          margin-bottom: 10px; } }
  .r-calculator__result {
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
    text-align: left; }
    .r-calculator__result:nth-child(1) {
      -ms-grid-column: 1;
      -ms-grid-column-span: 2-1;
      -ms-grid-row: 1;
      -ms-grid-row-span: 2-1;
      grid-column: 1/2;
      grid-row: 1/2; }
    .r-calculator__result:nth-child(2) {
      -ms-grid-column: 2;
      -ms-grid-column-span: 3-2;
      -ms-grid-row: 1;
      -ms-grid-row-span: 2-1;
      grid-column: 2/3;
      grid-row: 1/2; }
    .r-calculator__result:nth-child(3) {
      -ms-grid-column: 1;
      -ms-grid-column-span: 2-1;
      -ms-grid-row: 2;
      -ms-grid-row-span: 3-2;
      grid-column: 1/2;
      grid-row: 2/3; }
    .r-calculator__result:nth-child(4) {
      -ms-grid-column: 2;
      -ms-grid-column-span: 3-2;
      -ms-grid-row: 2;
      -ms-grid-row-span: 3-2;
      grid-column: 2/3;
      grid-row: 2/3; }
  .r-calculator__result-title {
    font-size: 0.875rem;
    color: #011B34;
    font-weight: 500; }
  .r-calculator__result-value {
    background-color: transparent;
    border: 0;
    outline: none;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 700;
    color: #026DCE; }
  @media (max-width: 1199.98px) {
    .r-calculator__result {
      padding: .5rem 1rem; }
    .r-calculator__result-value {
      font-size: 1rem; }
    .r-calculator__input-group {
      display: block; }
      .r-calculator__input-group > .form-group {
        margin-right: 0; } }
  @media (max-width: 767.98px) {
    .r-calculator {
      padding: 1.5rem 1rem; }
      .r-calculator__results {
        display: block; }
      .r-calculator__result {
        margin-top: 1rem;
        flex-direction: row;
        padding: 1rem;
        min-height: 70px;
        align-items: center; }
      .r-calculator__result-title {
        flex-basis: 70%;
        padding-right: 1rem; }
      .r-calculator__result-value {
        text-align: right;
        font-size: 1.25rem; } }
  @media (max-width: 349.98px) {
    .r-calculator__result-value {
      font-size: 0.875rem; } }

.slider-spreads__card {
  padding: 2rem 3rem; }

.slider-spreads__titles {
  display: none; }

@media (max-width: 767.98px) {
  .slider-spreads__card {
    padding: 2rem 1rem; } }

@media (max-width: 349.98px) {
  .slider-spreads__card {
    padding: 1rem; } }

.slider-spreads .slick-arrow:before {
  color: #FFFFFF; }

.slider-spreads .slick-arrow.slick-disabled {
  opacity: 0; }

.slider-spreads .slick-arrow.slick-prev {
  left: -3rem; }

.slider-spreads .slick-arrow.slick-next {
  right: -3rem; }

.slider-fixed-col {
  padding-left: 14.375rem;
  position: relative;
  margin-top: 6rem; }
  .slider-fixed-col__fixed-col {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 14.375rem;
    height: 28.125rem;
    margin: .5rem 0;
    box-shadow: 10px 0 7px 0 white;
    z-index: 1; }
    .slider-fixed-col__fixed-col .card-listitems {
      width: 100%; }
  .slider-fixed-col--lg .slider-fixed-col__fixed-col {
    height: 31.25rem; }
  .slider-fixed-col--lg .slider-fixed-col__item {
    height: 31.25rem; }
  .slider-fixed-col__slider {
    height: 100%; }
  .slider-fixed-col__item {
    height: 28.125rem;
    margin: .5rem .4rem .5rem 1rem;
    order: 1; }
  .slider-fixed-col .dropdown-menu {
    min-width: 10rem;
    margin-left: -4rem;
    position: absolute; }
  .slider-fixed-col .dropdown-item {
    white-space: normal; }
  .slider-fixed-col__prev, .slider-fixed-col__next {
    position: absolute;
    top: -3rem; }
  .slider-fixed-col__prev {
    right: 2.5rem; }
  .slider-fixed-col__next {
    right: 0; }
  .slider-fixed-col .slick-initialized .slick-slide {
    display: flex; }
  .slider-fixed-col__header-inner {
    display: flex;
    align-items: center; }
  .slider-fixed-col__logo {
    display: flex;
    margin-right: .5rem; }
  .slider-fixed-col__title {
    font-size: 1.25rem;
    font-weight: 500;
    color: #011B34;
    margin-bottom: 0; }
    @media (max-width: 1199.98px) {
      .slider-fixed-col__title {
        font-size: 1rem; } }
  @media (min-width: 992px) {
    .slider-fixed-col__slider {
      justify-content: space-between;
      display: flex; }
    .slider-fixed-col__item {
      flex: 1;
      width: 28.125rem; }
    .slider-fixed-col .slick-active:last-child .dropdown-menu {
      left: 0;
      right: auto; } }
  @media (max-width: 575.98px) {
    .slider-fixed-col {
      padding-left: 8.125rem; }
      .slider-fixed-col__header-inner {
        display: block; }
      .slider-fixed-col__logo {
        height: 3rem;
        margin-right: 0;
        margin-bottom: .5rem; }
        .slider-fixed-col__logo img {
          display: block;
          height: 2.5rem;
          margin: auto; }
        .slider-fixed-col__logo h3 {
          font-size: 0.75rem;
          text-align: center; }
      .slider-fixed-col__title {
        font-size: 0.75rem;
        text-align: center; }
      .slider-fixed-col .slider-fixed-col__fixed-col {
        width: 8.125rem; }
        .slider-fixed-col .slider-fixed-col__fixed-col .card-listitems__item {
          justify-content: flex-start;
          text-align: left; }
      .slider-fixed-col .card-listitems__logo {
        height: 3rem; }
      .slider-fixed-col .card-listitems__header {
        padding: 0 1rem; }
      .slider-fixed-col .card-listitems__list {
        padding: 0 1rem; }
      .slider-fixed-col .card-listitems__item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center; } }

.section-execution-conditions .tab-pane#stocksUsd .table-scrollable th:nth-child(2), .section-execution-conditions .tab-pane#stocksUsd .table-scrollable td:nth-child(2) {
  white-space: normal; }

.section-execution-conditions .table-scrollable tr:nth-last-child(-n+3) .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.section-execution-conditions .icon-calendar-clock[data-toggle='dropdown'] {
  cursor: context-menu;
  color: #011B34; }
  .section-execution-conditions .icon-calendar-clock[data-toggle='dropdown']:hover, .section-execution-conditions .icon-calendar-clock[data-toggle='dropdown']:focus {
    text-decoration: none;
    color: #011B34; }

@media (max-width: 991.98px) {
  .section-execution-conditions .nav-fill .nav-item {
    flex-basis: 33.33333333333%;
    max-width: 33.33333333333%; } }

@media (max-width: 767.98px) {
  .section-execution-conditions .nav-fill .nav-item {
    flex-basis: 50%;
    max-width: 50%; } }

@media (max-width: 449.98px) {
  .section-execution-conditions .nav-fill .nav-item {
    flex-basis: 100%;
    max-width: 100%; } }

.section-media__image {
  width: 100%;
  max-width: 20rem;
  display: block;
  margin: auto; }

@media (min-width: 768px) {
  .section-media__image {
    max-width: 31.25rem; }
  .section-media .section__content {
    padding-top: 1rem; } }

.form-contact {
  padding: 2rem; }
  .form-contact--box {
    box-shadow: 0 0 4px 0 rgba(156, 156, 156, 0.5); }
  .form-contact .form-control {
    border-radius: 2px;
    box-shadow: 0 0 4px 0 rgba(156, 156, 156, 0.5);
    padding: .5rem 1.5rem; }
  .form-contact .form-group {
    margin-bottom: 1.5rem; }
  @media (min-width: 992px) {
    .form-contact {
      padding: 2rem 3.5rem; } }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #E0E0E0; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #E0E0E0; }

.modal {
  padding-right: 0 !important; }
  .modal--alt .modal-header {
    border-color: transparent;
    padding-top: 1rem;
    padding-bottom: 0; }
  @media (min-width: 1440px) {
    .modal--maximize {
      max-width: 1224px; } }
  @media (min-width: 1200px) and (max-width: 1439.98px) {
    .modal--maximize {
      max-width: 992px; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .modal--maximize {
      max-width: 768px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .modal--maximize {
      max-width: 640px; } }
  .modal--maximize .modal-body {
    width: 100%; }

.modal-footer--no-border {
  border-top: 0; }

.video-modal-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 999;
  margin: 0;
  background-color: rgba(39, 39, 39, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 3rem; }

.video-modal-close-icon {
  cursor: pointer;
  outline: none;
  z-index: 9999; }

.video-modal-close-icon:hover {
  filter: opacity(0.7); }

.video-modal-video-container {
  display: flex;
  align-items: center;
  justify-content: center; }

.video-modal-video {
  width: 50vw; }

.video-modal-image {
  cursor: pointer; }
  @media (max-width: 991.98px) {
    .video-modal-image {
      display: none; } }

.video-modal-image:hover {
  filter: opacity(0.85); }

.customModal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;
  width: 690px;
  z-index: 1034;
  border-radius: 8px; }
  @media (max-width: 991.98px) {
    .customModal {
      width: 100%;
      top: 0;
      left: 0;
      transform: unset; } }
  .customModal.show {
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: fade-in;
    animation-duration: 400ms;
    animation-timing-function: ease-in; }
    @media (max-width: 991.98px) {
      .customModal.show {
        animation-name: fade-in-up;
        animation-duration: 2500ms;
        animation-timing-function: ease-in; } }
  .customModal.hide {
    opacity: 0; }
  .customModal-content {
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    max-height: 90vh; }
  .customModal-header {
    border-radius: 8px 8px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 46px 40px;
    background-color: #026DCE;
    color: #FFFFFF;
    font-size: 40px;
    line-height: normal;
    text-align: center;
    margin-bottom: 40px; }
    @media (max-width: 991.98px) {
      .customModal-header {
        padding: 32px 16px;
        font-size: 24px; } }
  .customModal-body {
    width: 100%;
    padding: 0px 40px;
    font-size: 20px;
    line-height: 30px;
    position: relative;
    max-height: calc(100vh - 138px);
    overflow-y: auto; }
    @media (max-width: 991.98px) {
      .customModal-body {
        padding: 0px 16px;
        font-size: 16px; } }
  .customModal-footer {
    padding: 40px 40px 0px 40px;
    padding-top: 40px;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 46px; }
    .customModal-footer__buttonGroup {
      gap: 40px; }
      @media (max-width: 991.98px) {
        .customModal-footer__buttonGroup {
          gap: 24px;
          align-items: center; } }

.backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1033;
  transition: opacity 0.3s ease-in-out; }
  .backdrop.show {
    display: block;
    opacity: 1; }
  .backdrop.hide {
    opacity: 0; }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in-up {
  from {
    top: -100%; }
  to {
    top: 0; } }

.section-error404 {
  color: #D8EFFD; }
  .section-error404__container {
    max-width: 1400px;
    padding: 0 2rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
  .section-error404__text {
    flex-basis: 60%;
    padding-right: 4rem; }
  .section-error404__number {
    font-size: 14.75rem;
    color: #D8EFFD;
    text-shadow: 0px 0px 20px #D8EFFD;
    line-height: 1; }
  @media (max-width: 991.98px) {
    .section-error404__number {
      font-size: 12.5rem; } }
  @media (max-width: 767.98px) {
    .section-error404__container {
      flex-direction: column;
      justify-content: center; }
    .section-error404__number {
      width: 100%;
      text-align: center;
      order: 1;
      font-size: 6.25rem; }
    .section-error404__text {
      text-align: center;
      order: 2;
      padding-right: 0; } }

.section-divider__content {
  display: flex;
  align-items: stretch;
  overflow: hidden;
  margin-left: -4px; }

.section-divider__item {
  flex: 1;
  border-left: 4px solid #FFFFFF; }
  .section-divider__item > img {
    width: 100%;
    height: auto; }

.darwins-backtest {
  display: -ms-grid;
  display: grid;
  grid-gap: 0.909rem;
  -ms-grid-columns: 1fr 290px;
  grid-template-columns: 1fr 290px; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .darwins-backtest {
      margin-right: -0.909rem;
      margin-bottom: -0.909rem; }
      .darwins-backtest > * {
        margin-right: 0.909rem;
        margin-bottom: 0.909rem; } }
  .darwins-backtest__aside {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-column: 2/3;
    grid-row: 1/3;
    box-shadow: 0 0 6px 0 rgba(103, 103, 103, 0.4);
    display: flex;
    flex-direction: column; }
  .darwins-backtest__chart {
    height: 400px;
    box-shadow: 0 0 6px 0 rgba(103, 103, 103, 0.4); }
  .darwins-backtest__status {
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem; }
  .darwins-backtest__total {
    padding: 1rem;
    box-shadow: 0 0 6px 0 rgba(103, 103, 103, 0.4);
    color: #373737;
    font-size: 0.875rem; }
    @media (max-width: 767.98px) {
      .darwins-backtest__total .list-divided {
        display: block; }
        .darwins-backtest__total .list-divided__item {
          border: 0; } }
  @media (max-width: 991.98px) {
    .darwins-backtest {
      -ms-grid-columns: 1fr 250px;
      grid-template-columns: 1fr 250px;
      grid-gap: 0.4rem; } }
  @media screen and (max-width: 991.98px) and (-ms-high-contrast: active), (max-width: 991.98px) and (-ms-high-contrast: none) {
    .darwins-backtest {
      margin-right: -0.4rem;
      margin-bottom: -0.4rem; }
      .darwins-backtest > * {
        margin-right: 0.4rem;
        margin-bottom: 0.4rem; } }
  @media (max-width: 767.98px) {
    .darwins-backtest {
      display: block;
      margin: 0; }
      .darwins-backtest__chart {
        margin-top: .909rem;
        height: 325px; }
      .darwins-backtest__total {
        margin-top: .909rem; } }

.select-darwins {
  display: flex;
  flex: 1;
  flex-direction: column; }
  .select-darwins__header {
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    border-bottom: 1px solid #E0E0E0; }
  .select-darwins__title {
    padding: .75rem 1rem;
    color: #011B34;
    flex-shrink: 0;
    font-weight: 500; }
  .select-darwins__toggler {
    display: none;
    border: 0;
    border-left: 1px solid #E0E0E0;
    padding: 0;
    width: 4rem;
    cursor: pointer;
    outline: none;
    transition: .25s all ease-in-out;
    flex-shrink: 0; }
    .select-darwins__toggler:hover, .select-darwins__toggler:focus {
      outline: none;
      background-color: #FBFBFB; }
  .select-darwins__list {
    color: #011B34;
    font-size: 0.875rem;
    flex: 1;
    position: relative; }
    .select-darwins__list thead th {
      font-size: 0.875rem;
      border-bottom: 0; }
    .select-darwins__list td {
      font-weight: 300; }
    .select-darwins__list:before {
      content: '';
      height: 4px;
      opacity: 1;
      transition: .25s all ease-in-out;
      position: absolute;
      top: 32px;
      left: 0;
      right: 0;
      z-index: 1;
      background: linear-gradient(to bottom, rgba(80, 80, 80, 0.31) 0%, rgba(162, 162, 162, 0) 100%); }
    .select-darwins__list:after {
      content: '';
      height: 4px;
      opacity: 0;
      transition: .25s all ease-in-out;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      z-index: 1;
      background: linear-gradient(to top, rgba(80, 80, 80, 0.31) 0%, rgba(162, 162, 162, 0) 100%); }
    .select-darwins__list.can-scroll-bottom:after {
      opacity: 1; }
  .select-darwins__scroll {
    position: absolute;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .select-darwins__scroll-head td, .select-darwins__scroll-head th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border: 0;
    background-color: white;
    z-index: 3; }
  .select-darwins__overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1; }
  .select-darwins__row {
    cursor: pointer;
    transition: .25s background-color ease-in-out; }
    .select-darwins__row:hover {
      background-color: #FBFBFB; }
    .select-darwins__row.is-selected {
      background-color: #FBFBFB; }
      .select-darwins__row.is-selected:hover, .select-darwins__row.is-selected:focus {
        background-color: #E0E0E0; }
      .select-darwins__row.is-selected .icon-checked-circle__circle {
        fill: #79AA00; }
      .select-darwins__row.is-selected .icon-checked-circle__check {
        fill: #FFFFFF; }
  .select-darwins__darwin-name {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .select-darwins__darwin-name .darwin-name {
      font-weight: 300; }
  @media (max-width: 991.98px) {
    .select-darwins__darwin-name {
      font-size: 0.875rem; }
      .select-darwins__darwin-name .darwin-name:before {
        width: 12px;
        height: 12px; } }
  @media (max-width: 767.98px) {
    .select-darwins__scroll {
      top: 0;
      background: white;
      box-shadow: 0 3px 6px 0 rgba(103, 103, 103, 0.4);
      height: 0;
      transition: .25s all ease-out; }
    .select-darwins__list thead td, .select-darwins__list thead th {
      padding: 1em .5em; }
    .select-darwins__list:after, .select-darwins__list:before {
      display: none; }
    .select-darwins__list:before {
      top: 44px; }
    .select-darwins__toggler {
      display: block;
      position: relative;
      overflow: hidden; }
      .select-darwins__toggler > i {
        display: inline-block;
        transition: .25s all ease-in-out; }
    .select-darwins.is-open .select-darwins__toggler > i.icon-chevron-down-thin {
      transform: scaleY(-1); }
    .select-darwins.is-open .select-darwins__overlay {
      display: block; }
    .select-darwins.is-open .select-darwins__list {
      position: relative;
      z-index: 2; }
    .select-darwins.is-open .select-darwins__scroll {
      height: calc(100vh - 300px); }
    .select-darwins.is-open .select-darwins__header {
      z-index: 2; }
    .select-darwins.is-open .select-darwins__list:before {
      display: block; } }

.footer-anonymous {
  position: relative;
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: #202020;
  color: #a6a6a6;
  text-align: center;
  transition: all 0.2s ease-in-out;
  pointer-events: auto;
  box-shadow: 1px -2px 2px 0 rgba(0, 0, 0, 0.5); }
  .footer-anonymous__toggler {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #202020;
    border-radius: 50%;
    padding-top: .31rem;
    box-shadow: 1px -2px 2px 0 rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 40px; }
    .footer-anonymous__toggler > img {
      transition: all 0.2s ease-in-out; }
    .footer-anonymous__toggler.collapsed > img {
      transform: rotateX(180deg); }
  .footer-anonymous .container-fluid, .footer-anonymous .container-sm, .footer-anonymous .container-md, .footer-anonymous .container-lg, .footer-anonymous .container-xl {
    padding-top: 2px;
    padding-bottom: 1px; }
  .footer-anonymous__legal {
    line-height: 1.2;
    position: relative;
    z-index: 1; }
  .footer-anonymous__details {
    padding-top: 1.375rem; }
  @media (max-width: 767.98px) {
    .footer-anonymous {
      z-index: 1030;
      line-height: 1;
      padding: 0; } }
  @media (max-height: 300px) {
    .footer-anonymous {
      display: none; } }

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0; }

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute; }

@media (max-width: 991.98px) {
  .section-mobile {
    width: 100%;
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 991.98px) {
  .section-mobile__dark {
    background-color: #202020;
    padding: 32px 0px; } }

.section-mobile__dark-ranking {
  background-color: #202020;
  padding-top: 15px; }

@media (max-width: 991.98px) {
  .section-mobile__separation {
    padding-top: 110px;
    padding-bottom: 16px; } }

@media (min-width: 576px) and (max-width: 1199.98px) {
  .section-mobile__separation {
    padding-top: 220px; } }

.section-separation {
  padding-top: 92px;
  padding-bottom: 92px; }
  @media (max-width: 991.98px) {
    .section-separation {
      padding-top: 40px;
      padding-bottom: 40px; } }
  .section-separation--black {
    background-color: #202020; }

@media (max-width: 991.98px) {
  .container-mobile {
    padding-left: 16px;
    padding-right: 16px;
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 991.98px) {
  .font-mobile__title {
    font-size: 24px; } }

@media (max-width: 991.98px) {
  .font-mobile__base {
    font-size: 16px; } }

.gap-container {
  gap: 70px; }
  .gap-container__classic {
    gap: 24px; }
  @media (max-width: 991.98px) {
    .gap-container__small {
      gap: 12px; } }
  .gap-container__m {
    gap: 16px; }
  .gap-container__xs {
    gap: 5px; }

.silver-info__container {
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 40px; }
  @media (max-width: 991.98px) {
    .silver-info__container {
      width: 100%;
      display: grid;
      gap: 20px;
      position: relative; } }

.silver-info__play-cont {
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 290px; }

.silver-info__logo {
  width: 350px; }
  @media (max-width: 1199.98px) {
    .silver-info__logo {
      width: 220px;
      margin-left: auto;
      margin-right: auto; } }

.silver-info__information {
  width: 90%; }
  @media (max-width: 991.98px) {
    .silver-info__information {
      width: 100%; } }

.silver-info__tablet {
  width: 700px; }
  @media (max-width: 991.98px) {
    .silver-info__tablet {
      width: 100%; } }

.silver-info__btn {
  display: none; }
  @media (max-width: 991.98px) {
    .silver-info__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 290px; } }

.silver-info__rating-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: #202020;
  color: #FFFFFF;
  padding: 32px;
  border-radius: 8px; }
  @media (max-width: 991.98px) {
    .silver-info__rating-container {
      display: none; } }
  .silver-info__rating-container__form-container {
    display: flex;
    flex-direction: column;
    gap: 16px; }
    .silver-info__rating-container__form-container__input {
      background-color: #272727;
      border: solid 1px transparent;
      color: #FFFFFF; }
    .silver-info__rating-container__form-container__value {
      display: flex;
      flex-direction: column; }
  .silver-info__rating-container__cross-container {
    display: none; }
    @media (max-width: 991.98px) {
      .silver-info__rating-container__cross-container {
        display: flex;
        width: 100%;
        position: relative; } }
  .silver-info__rating-container__cross-logo {
    display: none; }
    @media (max-width: 991.98px) {
      .silver-info__rating-container__cross-logo {
        display: flex;
        position: absolute;
        top: 10px;
        right: 10px; } }

@media (max-width: 991.98px) {
  .silver-info__rating-show {
    width: 100%;
    min-height: 100vh;
    position: fixed;
    overflow-y: scroll;
    z-index: 1032;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: #202020;
    color: #FFFFFF;
    padding: 24px;
    border-radius: 0px; } }

.darwinia-gold-resume {
  min-width: 350px;
  background-color: #4a4a4a;
  position: absolute;
  top: 0;
  right: 0; }

.darwinia-silver-resume {
  min-width: 350px;
  background-color: #4a4a4a;
  position: absolute;
  bottom: 0;
  left: 0; }

.background-mountain-level {
  width: 100%;
  height: 900px;
  background-image: url(../images/home/darwinia/mountains-level.png);
  background-size: cover;
  background-position: bottom; }

.mountains__title {
  line-height: 35px; }

.darwinia-description-container {
  position: relative;
  width: 40%;
  height: 500px; }

.darwinia-levels-title {
  width: 50%; }

.fact-container {
  background-color: #F1F1F1;
  gap: 32px;
  position: relative;
  z-index: 0;
  border-radius: 8px; }
  .fact-container::after {
    content: '';
    position: absolute;
    width: 230px;
    height: 300px;
    background-image: url(../images/home/darwinia/question.svg);
    background-size: contain;
    top: 32px;
    right: 32px; }
    @media (max-width: 991.98px) {
      .fact-container::after {
        display: none; } }
  @media (max-width: 991.98px) {
    .fact-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 32px 16px; } }
  .fact-container__info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-weight: 400;
    color: #000000;
    z-index: 10; }
  .fact-container__card {
    width: 130%;
    padding: 30px;
    background-color: #373737;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;
    border-radius: 8px; }
    @media (max-width: 991.98px) {
      .fact-container__card {
        width: 100%; } }
    .fact-container__card__acronym-bar {
      width: 100%;
      height: 2px;
      background-color: #F2A007; }
    .fact-container__card__acronym-container {
      background-color: #F2A007;
      padding: 10px; }
    .fact-container__card__acronym-triangle {
      border-color: #F2A007 transparent transparent transparent;
      width: 0;
      height: 0;
      border-width: 56px 8px 0 0;
      border-style: solid; }

.at-container {
  gap: 10px; }

.bar {
  width: 90%;
  height: 1px;
  background-color: #FFFFFF; }

.return-fact--negative {
  color: #FF4141; }

.return-fact--positive {
  color: #AAFF00; }

.ranking-container {
  width: 800px;
  height: 500px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 60px; }
  .ranking-container--gold {
    background-color: #E0E0E0;
    margin-top: 100px; }
  .ranking-container--silver {
    background-color: #202020; }
  @media (max-width: 991.98px) {
    .ranking-container {
      width: 100%;
      height: auto;
      flex-direction: column; } }

.top3-container {
  position: absolute;
  top: -80px;
  left: 100px; }
  @media (max-width: 991.98px) {
    .top3-container {
      position: unset;
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      padding: 12px; } }

.top2-card {
  width: 200px;
  height: 255px;
  background-image: url(../images/home/darwinia/top2.svg);
  background-size: cover;
  gap: 12px; }
  @media (max-width: 991.98px) {
    .top2-card {
      width: 90%;
      height: auto;
      background-image: url(../images/home/darwinia/top-2-mobile.png);
      padding: 8px;
      border-radius: 4px;
      background-position: right; } }

.top1-card {
  width: 200px;
  height: 270px;
  background-image: url(../images/home/darwinia/top1.svg);
  background-size: cover;
  gap: 12px; }
  @media (max-width: 991.98px) {
    .top1-card {
      width: 90%;
      height: auto;
      background-image: url(../images/home/darwinia/top-1-mobile.png);
      padding: 8px;
      border-radius: 4px;
      background-position: right; } }

.top3-card {
  width: 200px;
  height: 255px;
  background-image: url(../images/home/darwinia/top3.svg);
  background-size: cover;
  gap: 12px; }
  @media (max-width: 991.98px) {
    .top3-card {
      width: 90%;
      height: auto;
      background-image: url(../images/home/darwinia/top-3-mobile.png);
      padding: 8px;
      border-radius: 4px;
      background-position: right; } }
  .top3-card__username {
    display: flex;
    gap: 8px; }
  .top3-card__avatar {
    display: flex;
    width: 25px;
    border-radius: 50%; }
    @media (max-width: 991.98px) {
      .top3-card__avatar {
        display: none; } }

.top-3-gap-info {
  gap: 24px; }
  @media (max-width: 991.98px) {
    .top-3-gap-info {
      gap: 20px; } }

.top-3-gap-blocks {
  gap: 12px; }

.acronym-cont {
  display: flex;
  padding: 7px 15px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: red; }

.grid-ranking-container {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  gap: 32px; }
  .grid-ranking-container__column-1 {
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    flex-direction: column;
    gap: 12px; }
    @media (max-width: 991.98px) {
      .grid-ranking-container__column-1 {
        justify-content: start; } }
  .grid-ranking-container__column-2 {
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    flex-direction: column;
    gap: 12px; }
    @media (max-width: 991.98px) {
      .grid-ranking-container__column-2 {
        justify-content: start; } }
  @media (max-width: 991.98px) {
    .grid-ranking-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: start;
      align-items: start; } }

.ranking-container-element {
  gap: 12px; }

.requirements-container {
  display: grid;
  grid-template-columns: 2;
  gap: 24px; }
  .requirements-container__images {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-template-rows: repeat(3, 1fr);
    gap: 40px;
    justify-content: center; }
  .requirements-container__info {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-template-rows: repeat(3, 1fr);
    gap: 40px;
    align-items: center;
    justify-content: center; }
    @media (max-width: 991.98px) {
      .requirements-container__info {
        align-items: start; } }
  @media (max-width: 991.98px) {
    .requirements-container__title {
      width: 60%; } }
  @media (max-width: 991.98px) {
    .requirements-container {
      gap: 10px; } }

.btn__desktop {
  display: flex; }
  @media (max-width: 991.98px) {
    .btn__desktop {
      display: none; } }

.check-icon {
  width: 70px;
  position: relative;
  z-index: 5; }
  .check-icon--vertical-bar {
    position: relative; }
    .check-icon--vertical-bar::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 396px;
      bottom: 0;
      left: calc(50% - 6px);
      transform: translate(50%, 0);
      background-color: #009BFF; }
      @media (max-width: 991.98px) {
        .check-icon--vertical-bar::after {
          display: none; } }
  @media (max-width: 991.98px) {
    .check-icon {
      width: 30px; } }

.requirements-check-1 {
  grid-row-start: 1;
  grid-row-end: 2; }

.requirements-check-2 {
  grid-row-start: 2;
  grid-row-end: 3; }

.requirements-check-3 {
  grid-row-start: 3;
  grid-row-end: 4; }

.vertical-bar {
  position: relative; }
  .vertical-bar::after {
    content: '';
    position: absolute;
    display: flex;
    width: 2px;
    height: calc(100% + 110px);
    background-color: #009BFF;
    left: calc(50% - 6px);
    transform: translate(50%, 0);
    z-index: -1;
    top: -55px; }
    @media (max-width: 991.98px) {
      .vertical-bar::after {
        display: none; } }

.requirements-gap-container {
  gap: 8px;
  padding: 16px;
  display: flex;
  width: 100%; }
  .requirements-gap-container__dissapear {
    display: none; }
  .requirements-gap-container__appear {
    display: flex;
    flex: column; }
  .requirements-gap-container__table {
    gap: 8px;
    padding: 16px;
    display: flex;
    max-width: 100%;
    text-align: center; }
    @media (max-width: 991.98px) {
      .requirements-gap-container__table {
        padding: 16px;
        font-size: 12px; } }
  .requirements-gap-container__table-cont {
    width: 100%; }
  .requirements-gap-container__table-groups {
    gap: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    position: relative; }
    .requirements-gap-container__table-groups::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #272727;
      bottom: 0; }
  .requirements-gap-container__table-groups-first {
    gap: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column; }

.table-mobile__first-row {
  height: 100%;
  max-height: 70px;
  display: flex;
  justify-content: center;
  text-align: center; }
  @media (max-width: 991.98px) {
    .table-mobile__first-row {
      max-width: 125px;
      width: 100%;
      background-color: #262435;
      padding: 4px;
      text-align: start; } }

.table-mobile__first-column {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: center; }

.table-mobile__color-column {
  background-color: #262435;
  max-width: 120px;
  width: 100%;
  padding: 8px; }

.table-mobile__other-row {
  width: 25%; }
  @media (max-width: 991.98px) {
    .table-mobile__other-row {
      width: 15%;
      display: flex;
      justify-content: center;
      border-bottom: 2px solid transparent;
      padding: 0px; } }
  .table-mobile__other-row-first {
    width: 25%; }
    @media (max-width: 991.98px) {
      .table-mobile__other-row-first {
        text-align: center;
        display: flex;
        justify-content: center;
        border-bottom: 2px solid transparent;
        padding: 0px;
        background-color: #262435; } }

.table-mobile__line {
  position: relative; }
  .table-mobile__line::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 200px;
    background-color: #4D4747;
    right: -4px;
    top: 0; }

.requirements-table-border {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #202020; }

.border-line {
  border-bottom: solid 1px grey; }

.gold-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 40px; }
  @media (max-width: 991.98px) {
    .gold-container {
      width: 100%; } }

.gold-info-container {
  display: flex;
  flex-direction: column;
  gap: 24px; }

.background-mountain {
  width: 100%;
  background-image: url(../images/bg/bg-mountains.png);
  background-repeat: no-repeat;
  background-size: cover; }
  @media (max-width: 991.98px) {
    .background-mountain {
      background-position: right; } }

.conditions-container__title {
  width: 42%; }
  @media (max-width: 991.98px) {
    .conditions-container__title {
      width: 100%; } }

.conditions-container__list {
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  gap: 40px; }
  @media (max-width: 991.98px) {
    .conditions-container__list {
      padding-top: 0px;
      gap: 24px;
      justify-content: start;
      align-items: start; } }

.updown__container {
  position: relative; }
  @media (max-width: 991.98px) {
    .updown__container {
      max-width: 80%; } }

.updown__icon {
  display: none; }
  @media (max-width: 991.98px) {
    .updown__icon {
      display: flex;
      position: absolute;
      transform: rotate(180deg);
      right: -20px;
      top: 0px; } }

.updown__down {
  transform: rotate(0deg); }

.background-dark {
  width: 100%;
  background-image: url(../images/bg/bg-dark-details.png);
  background-repeat: no-repeat;
  background-size: cover; }
  @media (max-width: 991.98px) {
    .background-dark {
      width: 90%;
      background-position: center; } }

.commissions-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 70px; }
  @media (max-width: 991.98px) {
    .commissions-container {
      flex-direction: column; } }
  @media (max-width: 991.98px) {
    .commissions-container {
      display: grid;
      grid-template-rows: 3;
      justify-content: center;
      align-items: center; } }

.commissions__photo {
  width: 320px; }
  @media (max-width: 991.98px) {
    .commissions__photo {
      grid-row-start: 2;
      grid-row-end: 3;
      width: 200px;
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 991.98px) {
    .commissions__photo__upper {
      display: none; } }

@media (max-width: 991.98px) {
  .commissions__title {
    grid-row-start: 1;
    grid-row-end: 2; } }

.commissions-text-container {
  gap: 30px;
  width: 50%; }
  @media (max-width: 991.98px) {
    .commissions-text-container {
      width: 100%; } }

.migration-container {
  width: 100%;
  background: linear-gradient(198deg, #4D4747 0%, #000 100%);
  gap: 32px; }
  @media (max-width: 991.98px) {
    .migration-container {
      background: unset; } }
  @media (max-width: 991.98px) {
    .migration-container__background {
      width: 100%;
      background-image: url(../images/home/darwinia/migration-bg-mobile.png);
      background-size: cover; } }
  .migration-container__info {
    gap: 30px; }
    @media (max-width: 991.98px) {
      .migration-container__info {
        align-items: center;
        padding-bottom: 30px; } }
  .migration-container__texts {
    width: 70%; }
    @media (max-width: 991.98px) {
      .migration-container__texts {
        width: 100%; } }
  .migration-container__title {
    width: 400px; }
    @media (max-width: 991.98px) {
      .migration-container__title {
        width: auto; } }

.background-arrows {
  display: flex;
  width: 1000px;
  height: 500px;
  background-image: url(../images/home/darwinia/darwinia-arrows.svg);
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat; }
  @media (max-width: 991.98px) {
    .background-arrows {
      display: none; } }

.btn-width {
  max-width: 280px; }

.btn-position {
  position: absolute;
  left: calc(50%, 0);
  bottom: -18px; }
  @media (max-width: 991.98px) {
    .btn-position {
      left: 50%;
      transform: translate(-50%);
      min-width: 300px; } }

@media (max-width: 991.98px) {
  .mobile-mountains-background {
    display: flex;
    padding: 32px 16px 124px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    background-image: url(../images/home/darwinia/mountains-responsive.png);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat; } }

@media (max-width: 991.98px) {
  .mobile-competition-info {
    width: 90%;
    background-color: #E0E0E0;
    color: #4a4a4a;
    border-radius: 8px; } }

.text-blue {
  color: #009BFF; }

.text-black {
  color: #000000; }

.darwin {
  width: fit-content; }
  .darwin-size-sm {
    padding: 4px; }
  .darwin-size-md {
    padding: 7px 15px; }

.section-separation {
  padding-top: 92px;
  padding-bottom: 92px; }
  @media (max-width: 991.98px) {
    .section-separation {
      padding-top: 40px;
      padding-bottom: 40px; } }

@media (max-width: 991.98px) {
  .section-mobile {
    width: 100%;
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 991.98px) {
  .section-mobile__dark {
    background-color: #202020;
    padding: 32px 0px; } }

.section-mobile__dark-ranking {
  background-color: #202020;
  padding-top: 15px; }

@media (max-width: 991.98px) {
  .section-mobile__separation {
    padding-top: 110px;
    padding-bottom: 16px; } }

@media (min-width: 576px) and (max-width: 1199.98px) {
  .section-mobile__separation {
    padding-top: 220px; } }

.gap-container {
  gap: 70px; }
  .gap-container__l {
    gap: 32px; }
  .gap-container__classic {
    gap: 24px; }
  @media (max-width: 991.98px) {
    .gap-container__small {
      gap: 12px; } }
  .gap-container__m {
    gap: 16px; }
  .gap-container__xs {
    gap: 5px; }

.better__img {
  width: 50%; }
  @media (max-width: 991.98px) {
    .better__img {
      width: 100%; } }

.why-us__background {
  background-image: url(../images/bg/bg-why-us.png);
  width: 100%;
  height: 100%;
  background-position: center; }

.why-us__container {
  width: 60%; }
  @media (max-width: 991.98px) {
    .why-us__container {
      width: 100%; } }

.grow__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px; }
  @media (max-width: 991.98px) {
    .grow__container {
      grid-template-columns: 1fr; } }

.grow__card {
  max-width: 360px;
  border-radius: 16px;
  border: 1px;
  border-color: #E0E0E0;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 48px 32px 32px 32px; }

.grow__image {
  max-width: 60px; }

.grow__text {
  font-weight: 400;
  line-height: normal; }

.how-to__container {
  width: 25%; }
  @media (max-width: 991.98px) {
    .how-to__container {
      width: 100%; } }
  .how-to__container__bar {
    position: relative;
    width: 25%; }
    .how-to__container__bar::after {
      content: '';
      display: block;
      width: 55%;
      height: 3px;
      background-color: #026DCE;
      position: absolute;
      right: 0;
      top: 40px; }
      @media (max-width: 991.98px) {
        .how-to__container__bar::after {
          width: 3px;
          height: 65px;
          left: 20px;
          top: auto;
          bottom: 0; } }
    @media (max-width: 991.98px) {
      .how-to__container__bar {
        width: 100%;
        height: 130px; } }

.how-to__text {
  line-height: 33.6px; }

.how-to__icon {
  width: 80px;
  height: 80px;
  gap: 10px;
  border-radius: 50%;
  background: #FFF;
  flex-shrink: 0;
  box-shadow: 0px 9px 9px 0px rgba(0, 0, 0, 0.25); }
  @media (max-width: 991.98px) {
    .how-to__icon {
      width: 48px;
      height: 48px; } }

@media (max-width: 991.98px) {
  .how-to__image {
    max-width: 50%; } }

.card__container {
  min-width: 269px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #272727;
  transition: background-color 500ms ease-in-out;
  scroll-snap-align: start; }
  @media (min-width: 992px) {
    .card__container {
      max-width: 269px;
      min-width: unset; }
      .card__container:hover {
        cursor: default; } }

.card__darwin-container {
  display: flex; }

.card__darwin {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 24px;
  padding-right: 16px; }

.card__icon {
  width: 25px; }

.card__acronym_triangle {
  width: 0;
  height: 0;
  border-width: 56px 8px 0 0;
  border-style: solid; }

.card__bar {
  width: 80%;
  height: 1px;
  background-color: #4a4a4a;
  margin-left: auto;
  margin-right: auto; }

.card__button {
  color: #009BFF;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 24px; }

.cards__container__cards {
  display: flex;
  gap: 16px;
  flex-flow: row nowrap;
  overflow-x: scroll;
  max-width: 100%;
  margin: 2.5rem 0 1rem;
  padding-bottom: 1rem;
  scroll-snap-type: x mandatory; }
  @media (min-width: 992px) {
    .cards__container__cards {
      flex-wrap: wrap;
      overflow-x: auto;
      justify-content: center; } }

.map-global {
  height: 437px;
  width: 100%;
  position: relative; }
  @media (max-width: 991.98px) {
    .map-global {
      height: 82px; } }
  .map-global_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .map-global__address__section {
    width: 100%; }
  .map-global__address_container {
    display: flex;
    gap: 32px; }
    @media (max-width: 991.98px) {
      .map-global__address_container {
        flex-direction: column; } }
    .map-global__address_container address {
      flex: 1 1 0; }

.home-image-fullWidth {
  width: 100%;
  height: 100%; }

@media (max-width: 991.98px) {
  .home-displaynonemobile {
    display: none; } }

.home__content {
  padding-top: 2rem; }
  @media (max-width: 991.98px) {
    .home__content {
      padding: 2rem 0.5rem;
      padding-bottom: 0; } }
  @media (min-width: 768px) {
    .home__content .col-lg-6:last-of-type {
      padding-left: 30px; } }

@media (max-width: 991.98px) {
  .home-cta {
    text-align: center; } }

.home__image {
  width: 500px; }
  @media (max-width: 1199.98px) {
    .home__image {
      width: 450px; } }
  @media (max-width: 991.98px) {
    .home__image {
      width: 350px; } }
  @media (max-width: 767.98px) {
    .home__image {
      width: 300px; } }

.home--secondary {
  position: relative; }
  .home--secondary h1:first-child,
  .home--secondary .h1:first-child {
    margin-bottom: 2rem; }
    .home--secondary h1:first-child::before,
    .home--secondary .h1:first-child::before {
      content: '';
      display: block;
      height: 0;
      width: 0;
      margin-top: calc((1 - 1.4) * 0.5em); }
  @media (max-width: 991.98px) {
    .home--secondary {
      padding-top: 7rem; } }
  @media (max-width: 991.98px) {
    .has-navbar-fixed-open .home--secondary:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: 0.5;
      z-index: 1; } }

@media (max-width: 991.98px) {
  .home--nointro {
    padding-top: 3.5rem; } }

.section-universe {
  background: #000306; }
  .section-universe_bg {
    padding-bottom: 20px;
    width: 100%;
    background-image: url(../images/home/universe.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position-y: 80px; }
    @media (max-width: 991.98px) {
      .section-universe_bg {
        background-image: unset; } }
  .section-universe_title {
    margin-bottom: 184px;
    text-align: center;
    color: #ffffff; }
    @media (max-width: 991.98px) {
      .section-universe_title {
        background-image: url(../images/home/universe.png);
        background-size: cover;
        background-position-x: center;
        background-position-y: -10px;
        padding-top: 50px; } }
    @media (max-width: 991.98px) {
      .section-universe_title {
        margin-bottom: 32px; } }
  .section-universe__block {
    gap: 16px;
    display: flex;
    justify-content: space-between;
    color: #f1f1f1f1; }
    @media (max-width: 991.98px) {
      .section-universe__block {
        flex-direction: column;
        gap: 35px; } }
    .section-universe__block_item {
      width: 50%;
      display: flex;
      flex-direction: column; }
      @media (max-width: 991.98px) {
        .section-universe__block_item {
          width: 100%; } }
    .section-universe__block_divider {
      width: 1px;
      background-color: #009BFF; }

.section-broker-manager {
  background-color: #000306;
  background-image: url(../images/home/broker-manager.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  color: #ffffff;
  text-align: center; }

.section-broker {
  background-color: #000306;
  padding-top: 36px;
  padding-bottom: 64px; }

@media (min-width: 768px) {
  .section-track-record {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto 1fr;
    grid-template-areas: 'title image' 'text image' 'cta image'; } }

.section-track-record__h2 {
  grid-area: title;
  padding-top: 92px; }
  @media (max-width: 991.98px) {
    .section-track-record__h2 {
      padding-top: 0; } }

.section-track-record__img {
  grid-area: image;
  align-self: center;
  margin: 1rem auto 2rem; }
  @media (min-width: 768px) {
    .section-track-record__img {
      margin: auto; } }
  .section-track-record__img img {
    width: 100%; }

.section-track-record__text {
  grid-area: text; }

.section-track-record__cta {
  grid-area: cta; }

.section-home-darwinia {
  background-image: url(../images/home/darwinia.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 156px; }
  @media (max-width: 991.98px) {
    .section-home-darwinia {
      background-position: bottom;
      background-size: contain;
      max-height: 430px; } }

.section-zero {
  background: #FFF;
  border-radius: 8px 8px 0px 0px;
  padding: 92px 40px;
  position: relative;
  background-image: url(../images/home/D-Zero-bg-2.png);
  background-position: right;
  background-size: cover; }
  @media (max-width: 991.98px) {
    .section-zero {
      background-image: unset; } }
  .section-zero_text {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: #202020; }
    .section-zero_text img {
      width: fit-content;
      flex-shrink: 0; }
  .section-zero_img {
    position: relative; }
    .section-zero_img_img {
      width: 100%;
      height: 100%;
      right: 0;
      background-image: url(../images/home/zero.png);
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute; }
  .section-zero_info {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    background-color: #202020;
    border-radius: 18px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 32px 16px;
    color: #f1f1f1f1; }
    @media (max-width: 991.98px) {
      .section-zero_info {
        flex-direction: column; } }
    .section-zero_info_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 16px;
      position: relative; }
      @media (max-width: 991.98px) {
        .section-zero_info_item {
          flex-direction: row;
          align-items: flex-start;
          text-align: left;
          justify-content: flex-start; } }
    .section-zero_info_itemDivider {
      width: 1px;
      background-color: #f1f1f1f1; }

.investors__table__text {
  margin-bottom: 10px !important; }

.investors__table__border__top {
  border-top: 3px #4A4A4A solid; }

@media (max-width: 991.98px) {
  .investors-cta {
    text-align: center; } }

.investors__image {
  width: 500px; }
  @media (max-width: 991.98px) {
    .investors__image {
      width: 300px; } }

.investors--secondary {
  position: relative; }
  .investors--secondary h1:first-child,
  .investors--secondary .h1:first-child {
    margin-bottom: 2rem; }
    .investors--secondary h1:first-child::before,
    .investors--secondary .h1:first-child::before {
      content: '';
      display: block;
      height: 0;
      width: 0;
      margin-top: calc((1 - 1.4) * 0.5em); }
  @media (max-width: 991.98px) {
    .investors--secondary {
      padding-top: 7rem; } }
  @media (max-width: 991.98px) {
    .has-navbar-fixed-open .investors--secondary:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: 0.5;
      z-index: 1; } }

@media (max-width: 991.98px) {
  .investors--nointro {
    padding-top: 3.5rem; } }

.regulations__section__characteristics {
  background-color: #F1F1F1;
  padding: 32px;
  border-radius: 8px; }
  @media (max-width: 991.98px) {
    .regulations__section__characteristics {
      background-color: unset; } }
  .regulations__section__characteristics_row {
    gap: 104px; }
    .regulations__section__characteristics_row div {
      flex: 1 1 0px; }
      @media (max-width: 991.98px) {
        .regulations__section__characteristics_row div {
          width: 100%; } }
    @media (max-width: 991.98px) {
      .regulations__section__characteristics_row {
        flex-direction: column;
        gap: 24px; } }

.regulations__section__comparative__table {
  padding: 40px 100px 0px 100px;
  display: flex;
  flex-direction: column; }
  @media (max-width: 991.98px) {
    .regulations__section__comparative__table {
      overflow-x: scroll;
      padding: 40px 0; } }
  .regulations__section__comparative__table__row {
    display: flex;
    gap: 48px;
    justify-content: space-between;
    font-weight: normal; }
    .regulations__section__comparative__table__row:first-of-type {
      font-weight: 700; }
      .regulations__section__comparative__table__row:first-of-type div {
        border-top: none; }
  .regulations__section__comparative__table__item_component {
    padding: 16px 0px;
    font-size: 16px;
    flex: 1 1 0px;
    min-height: 56px;
    border-top: 1px solid #E0E0E0;
    display: block; }
    @media (max-width: 991.98px) {
      .regulations__section__comparative__table__item_component {
        min-width: 120px; } }
    .regulations__section__comparative__table__item_component span {
      display: block; }
      .regulations__section__comparative__table__item_component span:nth-child(2) {
        font-size: 14px; }
    .regulations__section__comparative__table__item_component img {
      width: 16px;
      height: 16px; }
    .regulations__section__comparative__table__item_component:not(.regulations__section__comparative__table__item_component:first-of-type) {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; }

.regulations__section__faq {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 146px; }
  @media (max-width: 991.98px) {
    .regulations__section__faq {
      padding: 0; } }
  .regulations__section__faq__item {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 13px 24px 16px 24px;
    background: linear-gradient(270deg, #FFF 0.02%, #F1F1F1 99.98%);
    border-radius: 4px;
    cursor: pointer; }
    @media (max-width: 767.98px) {
      .regulations__section__faq__item {
        border-radius: 0px; } }
    .regulations__section__faq__item_title {
      font-weight: 700;
      display: flex;
      justify-content: space-between; }

/*# sourceMappingURL=darwinex-public.css.map */